import Boton from "infortech_modules/Componentes/Boton";
import Formularios from "infortech_modules/Componentes/Formularios";
import Tabla from "infortech_modules/Componentes/Tabla";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { MdEditSquare } from "react-icons/md";
import { determinarEstadoCilindros } from "infortech_modules/Constantes/Constantes";
import { useState } from "react";
import { BiSave } from "react-icons/bi";



export default function OperacionCilindro({ tipo, seriales, imprimirSeriales, datosCilindros, actulizarCilindros, mostrar }) {
    datosCilindros = datosCilindros === undefined ? [] : datosCilindros;
    const [estado, setEstado] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false
    ]);
    const [tem, setTemp] = useState({})
    const cilindros = [];

    const cambiar = (e) => {
        cilindros[e.target.name.toString()] = e.target.value
    }

    const operacioEditar = (pos, valor) => {
        setTemp({
            ...tem,
            [pos]: valor
        });
        setEstado({
            ...estado,
            [pos]: true
        })

    }

    const operacionGuardar = (pos) => {
        imprimirSeriales[pos] = tem[pos];


        setEstado({
            ...estado,
            [pos]: false
        })

    }


    const valor = () => {
        function EsPar(numero) {
            return numero % 2 === 0;
        }

        let retornar = [];
        let temporal;
        let j = 0;
        for (let i = 0; i < 50; i++) {


            if (EsPar(i)) {
                temporal = { type: 'text', name: i.toString(), value: cilindros[i], placeholder: 'Serial Cilindro ' + (i + 1) }
            } else {
                retornar[j] = [temporal, { type: 'text', name: i.toString(), value: cilindros[i], placeholder: 'Serial Cilindro ' + (i + 1) }];
                j++
            }

        }
        return retornar;
    }

    const guardarSerial = () => {
        seriales(cilindros);
    }

    const cambiarSeriales = () => {
        seriales([]);
    }

    const verificarCilindro = (cilindro) => {
        return datosCilindros.some(c => c.ser === cilindro)
    }
    const actualizarValor = (e) => {
        setTemp({
            ...tem,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>

            {
                mostrar ?
                    <small>
                        
                        <div className="mb-3">
                        <div className="text-end mb-3">
                            <Boton tipo={'principal'} titulo={'Validar Seriales'} onClick={() => guardarSerial()} />
                        </div>
                            <Formularios datos={
                                valor()
                            } onChange={cambiar} />
                        </div>
                        <div className="text-end">
                            <Boton tipo={'principal'} titulo={'Validar Seriales'} onClick={() => guardarSerial()} />
                        </div>

                    </small> :
                    <>
                        <div className="h3 mb-3">
                            Seriales Seleccionados
                        </div>
                        <Tabla
                            encabezado={['Serial', 'Producto Cilindro', 'Capacidad', 'Estado', 'Editar']}
                            filas={imprimirSeriales.map((v, i) => {
                                return (
                                    <tr key={i}>
                                        <>
                                            {
                                                verificarCilindro(v) ? <>
                                                    {
                                                        datosCilindros.filter(d => d.ser === v).map((x, j) => {
                                                            let clase;
                                                            let mensaje;

                                                            if (x.ubic === 0) {
                                                                if (x.est === 1) {
                                                                    return CreaarFila([estado[i] ? "" : x.ser, x.nom, x.capacidad, "Disponible para Llenado", ""], "", [""], "text-white bg-success fw-bold")
                                                                } else {
                                                                    clase = "text-white  bg-danger fw-bold";
                                                                    mensaje = "Error: Estado actual del cilindro: " + determinarEstadoCilindros(x.est);
                                                                    return CreaarFila([estado[i] ? "" : x.ser, x.nom, x.capacidad, mensaje, <>
                                                                        {
                                                                            estado[i] ? <Boton tipo='icono' Icono={BiSave} titulo={'Guardar'} onClick={() => operacionGuardar(i)} /> :
                                                                                <Boton tipo='icono' claseIcon={'pointer text-white'} Icono={MdEditSquare} titulo={'Editar'} onClick={() => operacioEditar(i)} />
                                                                        }
                                                                    </>], "", [""], clase)
                                                                }

                                                            } else {
                                                                clase = "text-white  bg-danger fw-bold";
                                                                mensaje = "Error:  El cilinndro registra ubicado en " + x.ubicacion;
                                                                return CreaarFila([estado[i] ? "" : x.ser, x.nom, x.capacidad, mensaje, <>
                                                                    {
                                                                        estado[i] ? <Boton tipo='icono' Icono={BiSave} titulo={'Guardar'} onClick={() => operacionGuardar(i)} /> :
                                                                            <Boton tipo='icono' claseIcon={'pointer text-white'} Icono={MdEditSquare} titulo={'Editar'} onClick={() => operacioEditar(i)} />
                                                                    }
                                                                </>], "", [""], clase)
                                                            }

                                                        })
                                                    }
                                                </>
                                                    : CreaarFila([estado[i] ? <input type="text" name={i.toString()} value={tem[i]} className="form-control" onChange={actualizarValor} /> : v, "", "", "Error: Serial no Registrado", <>
                                                        {
                                                            estado[i] ? <Boton tipo='icono' claseIcon={'pointer text-white fw-bold'} Icono={BiSave} titulo={'Guardar'} onClick={() => operacionGuardar(i)} /> :
                                                                <Boton tipo='icono' claseIcon={'pointer text-white'} Icono={MdEditSquare} titulo={'Editar'} onClick={() => operacioEditar(i, imprimirSeriales[i])} />
                                                        }
                                                    </>], "", [""], "text-white  bg-danger fw-bold")
                                            }
                                        </>
                                    </tr>)
                            })
                            }
                        />
                        <div className="text-end">
                            <Boton titulo={'Cambiar Seriales'} tipo={'sinfondo'} onClick={() => cambiarSeriales()} />
                        </div>
                    </>
            }
        </>
    )
};
