import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import { cambiarFormulario, duplicate, tiempo, buscarValor, datosConsultarHistorial } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { compararDosFechas } from "infortech_modules/Constantes/Fecha";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import { useState } from "react"
import { IoEyeSharp } from "react-icons/io5";
import Calibracion from "./Calibracion";
import CalibracionEnviar from "./CalibracionEnviar";
import MantenimientoEnviar from "./MantenimientoEnviar";
import Mantenimientos from "./Mantenimientos";


export default function VerEquipos({ id, marca, modelo, descripcion, cant, disp }) {
    disp = disp === undefined ? false : disp;
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [per, setPer] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [man, setMan] = useState(0);
    const [cal, setCal] = useState(0);
    const [agregar, setAgregar] = useState({ idE: id, ser: "", pman: "1", fuman: "0000-00-00 00:00:00", pcal: "1", fucal: "0000-00-00 00:00:00", ubic: 0, prop: "1", nomP: "", est: 1, })

    const open = () => {
        setModal(true);
        principal();
    }

    const closed = () => {
        setModal(false);
        setError(false);
        tiempo(500, setCargar, true)
    }

    const open1 = (id) => {
        setMan(id);
        setModal1(true);
    }

    const open2 = (id) => {
        setCal(id);
        setModal2(true);
    }

    const closed1 = () => {
        setMan(0);
        setModal1(false);
    }

    const closed2 = () => {
        setCal(0);
        setModal2(false);
    }



    const principal = (est) => {
        est && setCargar(true);
        est && setAgregar({ idE: id, ser: "", pman: "1", fuman: "0000-00-00 00:00:00", pcal: "1", fucal: "0000-00-00 00:00:00", ubic: 0, prop: "1", nomP: "", est: 1, })

        Get({ url: '/get/general', key: 'DetEquipos', data0: id, data1: disp }).then((r) => {
            setData(r[0]);
            setPer(r[1]);
            setClientes(r[2]);
            setProveedores(r[2].filter(c => c.tipoC === 2));
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(500, setCargar, false)
        })
    }

    const cambiar = (e) => {
        setAgregar({
            ...agregar,
            [e.target.name]: e.target.value
        })
        setAgregar((v) => {
            if (v.prop === "1") {
                v.nomP = "";
            }
            if (v.pman === '1') {
                v.fuman = "0000-00-00 00:00:00"
            }
            if (v.pcal === '1') {
                v.fucal = "0000-00-00 00:00:00"
            }
            return v;
        })
    }

    const definirVacio = () => {
        if (agregar.prop === "1") {
            return "ok"
        } else {
            if (agregar.prop === "2") {
                if (buscarValor(proveedores, agregar.nomP)) {
                    return "ok";
                } else {
                    return "";
                }
            } else {
                if (agregar.prop === "3") {
                    if (buscarValor(clientes, agregar.nomP)) {
                        return "ok"
                    } else {
                        return ""
                    }
                }
            }
        }
    }
    
    return (
        <>

            <div className='infoText'>
                <div className={disp ? "pointer text-primary fw-bold" : "pointer text-success fw-bold"} style={{ display: 'flex' }} onClick={() => open()}>
                    {cant} &nbsp;
                    <IoEyeSharp className={disp ? "fs-5 text-primary" : "fs-5 text-success"} />
                </div>

                <span className="textText-Top">{disp ? "Ver Equipos Disponibles" : "Ver Inventarios"}</span>

            </div>
            <datalist id="clientes">
                {
                    clientes.map((v, i) => {
                        return (
                            <option key={i} value={`${v.id} - ${v.doc} - ${v.nom} ${v.ape}`} />
                        )
                    })
                }


            </datalist>

            <datalist id="proveedor">
                {
                    proveedores.map((v, i) => {
                        return (
                            <option key={i} value={`${v.id} - ${v.doc} - ${v.nom} ${v.ape}`} />
                        )
                    })
                }


            </datalist>


            <Modales
                titulo={descripcion + ' - ' + marca + ' - ' + modelo}
                show={modal}
                onHide={closed}
                body={
                    <Contenedor autorizado={Permisos().equipos} altura={'45vh'}
                        titulo={''}
                        cargar={cargar} mensajeCarga={'Obteniendo información del equipo...'}
                        error={error} mensajeError={mensaje}
                        botonAdd={<>
                            {
                                Autorizado([Permisos().prodcutosAdd]) &&
                                <ModalAdd
                                    tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nuevo Serial'} modalTitulo={'Crear Nuevo Serial'}
                                    contenidoSuperiorModal={<>
                                        <Formularios datos={[
                                            [CrearInput({ type: 'text', name: 'ser', value: agregar.ser, placeholder: 'Serial' })],
                                            [CrearInput({
                                                type: 'select', name: 'pman', value: agregar.pman, placeholder: 'Período de Mantenimiento', options: per.map((v, i) => {
                                                    return { value: v.id, titulo: v.periodo }
                                                })
                                            })],
                                        ]} onChange={cambiar}
                                        />
                                        {
                                            agregar.pman !== "1" &&
                                            <Formularios
                                                datos={[
                                                    [CrearInput({ type: 'datetime-local', name: 'fuman', value: agregar.fuman, placeholder: 'Fecha de Último Mantenimiento' })]
                                                ]} onChange={cambiar}
                                            />
                                        }
                                        <Formularios datos={[
                                            [CrearInput({
                                                type: 'select', name: 'pcal', value: agregar.pcal, placeholder: 'Período de Calibración', options: per.map((v, i) => {
                                                    return { value: v.id, titulo: v.periodo }
                                                })
                                            })],
                                        ]} onChange={cambiar} />
                                        {
                                            agregar.pcal !== "1" &&
                                            <Formularios
                                                datos={[
                                                    [CrearInput({ type: 'datetime-local', name: 'fucal', value: agregar.fucal, placeholder: 'Fecha de Última Calibración' })]
                                                ]} onChange={cambiar}
                                            />
                                        }
                                        <Formularios
                                            datos={[
                                                [CrearInput({ type: 'select', name: 'prop', value: agregar.prop, placeholder: 'Propiedad del Equipo', options: [{ value: "1", titulo: 'Propio' }, { value: "2", titulo: "Alquilado" }, { value: "3", titulo: "Tercero" }] })]
                                            ]} onChange={cambiar}
                                        />
                                        {
                                            agregar.prop !== "1" &&
                                            <Formularios datos={[
                                                [CrearInput({ type: 'text', name: 'nomP', value: agregar.nomP, list: agregar.prop === "3" ? "clientes" : "proveedor", placeholder: agregar.prop === "2" ? "Seleccione el Proveedor" : "Seleccione el Tercero" })]
                                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                                            />
                                        }

                                    </>
                                    }

                                    comprobarTextoVacio={[agregar.ser, definirVacio()]}
                                    mensajeTextoVacio={['Escriba el serial del equipo', agregar.prop === "2" ? "Seleccione un Proveedor de la lista" : "Seleccione un Cliente de la Lista", 'Debe escribir la descripción del equipo']}
                                    mensajeLoadingPromesa={'Agregando...'} mensajePromesa={'Agregado'} agregar={agregar}
                                    duplicados={duplicate(true, [agregar.ser], ['ser'], ['Este serial ya esta registrado'], [` and idE = '${id}'`])}
                                    tabla={'equipos_serial'} caso={'equiposAdd'} url={'/post/general'} principal={principal} infoLog={JSON.stringify(agregar)} key1={'EquiposAdd'}
                                />
                            }
                        </>}
                        tablaEncabezado={['Serial', 'Propiedad', 'Próximo Mantenimiento', 'Próxima Calibración', 'Ubicación', 'Estado', 'Acciones']} tablaNombre={'tes'}
                        tablaFilas={data.map((v, i) => {
                            let estado = "";
                            switch (v.est) {
                                case 1:
                                    estado = "Disponible"
                                    break;
                                case 2:
                                    estado = "En Ruta"
                                    break;
                                case 3:
                                    estado = "En Cliente"
                                    break;
                                case 4:
                                    estado = "En Mantenimiento";
                                    break;
                                case 5:
                                    estado = "En Calibración";
                                    break;
                                default:
                                    break;
                            }
                            return (
                                <tr key={i}>
                                    {
                                        CreaarFila([v.ser, v.propietario, v.pxman, v.pxcal, v.ubicacion, estado, <div style={{ display: 'flex' }}>
                                            <ModalDelete
                                                modalTitulo={'Eliminar Serial: ' + v.ser}
                                                mensajeEliminar={<>Va ha prooceder a eliminar el serial <strong>{v.ser}</strong> del equipo <strong>{descripcion}</strong> - <strong>{marca + ' ' + modelo}</strong>, <div>¿Esta seguro de continuar?</div> </>}
                                                consultarHistorial={datosConsultarHistorial(false, ['programacion_mantenimiento'], ['idES'], ['Error: "El equipo tiene mantenimientos programados"'])}
                                                caso={'equiposDel'} url={'/del'} principal={principal} valorEliminar={v.id} condicionEliminar={`id = ${v.id}`} infoLog={JSON.stringify({ ser: v.ser })}
                                                mensajeLoadingPromesa={'Elimninando Serial...'} mensajePromesa={'Serial Elimiando'} tabla={'equipos_serial'}
                                            />
                                            &nbsp; &nbsp;
                                            <MantenimientoEnviar serial={v.ser} equipo={descripcion} modelo={modelo} marca={marca} id={v.id} estado={v.est}
                                            />

                                            {
                                                v.est === 4 && v.idM !== 0 &&
                                                <>
                                                    &nbsp; &nbsp;
                                                    <Boton tipo={'icono'} Icono={IoEyeSharp} onClick={() => open1(v.idM)} titulo={'Ver Mantenimiento'}
                                                        claseIcon={'fs-4 pointer text-primary'} posicionTexto={'left'} />
                                                </>

                                            }
                                            {
                                                v.pcal !== 1 &&
                                                <>
                                                    &nbsp; &nbsp;
                                                    <CalibracionEnviar
                                                        serial={v.ser} equipo={descripcion} modelo={modelo} marca={marca} id={v.id} estado={v.est}
                                                    />
                                                </>
                                            }
                                            {
                                                v.est === 5 && v.idC !== 0 &&
                                                <>
                                                    &nbsp; &nbsp;
                                                    <Boton tipo={'icono'} Icono={IoEyeSharp} onClick={() => open2(v.idC)} titulo={'Ver Calibración'}
                                                        claseIcon={'fs-4 pointer text-primary'} posicionTexto={'left'} />
                                                </>
                                            }
                                        </div>

                                        ]
                                            , "scope", [""], compararDosFechas(v.pxman, v.pxcal, v.pman, v.pcal) ? "text-white fw-bold bg-danger" : "")
                                    }
                                </tr>
                            )
                        })}
                    />
                }
            />
            <Modales
                show={modal1}
                onHide={closed1}
                body={<Mantenimientos mantenimiento={man} recargar={principal} />}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed1()} />}
            />

            <Modales
                show={modal2}
                onHide={closed2}
                body={<Calibracion calibrar={cal} recargar={principal} />}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed2()} />}
            />
        </>
    )
};
