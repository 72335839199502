
import Alertas from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Dias, { ciudadeSelect, ciudadesRuta, convertirDias, diasSemana, escribirCiudades, escribirDias } from "infortech_modules/Constantes/Dias";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Carga from "infortech_modules/Elementos/Carga";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useState, useEffect } from "react";
export default function RutasD({ iniciar }) {

    useEffect(() => {
        principal();
    }, [])


    const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({
        ruta: "", dias: "", ciudades: "", obs: "", est: 1,
    })
    const [editar, setEditar] = useState({
        ruta: "", dias: "", ciudades: "", obs: "", est: 1,
    })
    const [city, setCity] = useState([]);
    const [d, sD] = useState({
        l: "", m: "", mm: "", j: "", v: "", s: "", d: ""
    });
    const [ed, sED] = useState({
        l: "", m: "", mm: "", j: "", v: "", s: "", d: ""
    });
    const [c, sC] = useState("0")
    const [ciudades, setCiudades] = useState([]);
    const [ciudadesE, setCiudadesE] = useState([]);
    const [cargaC, setCargaC] = useState(false);



    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'RutasD' }).then((r) => {
            setData(r[0]);
            setCity(r[1]);
            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(500, setCargar, false)
        })

    }


    const cambiarCheck = (e) => {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        sD({
            ...d,
            [e.target.name]: v
        })

        sD((v) => {
            setAgregar({
                ...agregar,
                dias: convertirDias(v)
            })
            return v;
        })

    }

    const cambiarCheckEdit = (e) => {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        sED({
            ...ed,
            [e.target.name]: v
        })

        sED((v) => {
            setEditar({
                ...editar,
                dias: convertirDias(v)
            })
            
            return v;
        })

    }

    const cambiar = (e) => {
        sC(e.target.value);
    }

    const agregarPush = (dato) => {

        if (dato === "0") {
            Alertas('a', 'Por favor seleccione una ciudad para agregar a la rutas')
        } else {
            if (ciudades.some(cs => cs === dato)) {
                Alertas('a', 'Esta ciudad ya ha sido agregada')
            } else {
                setCargaC(true);
                const cs = ciudades;
                cs.push(dato);
                setAgregar({
                    ...agregar,
                    ciudades: cs.toString()
                })
                setCiudades(cs);
            }

        }
        tiempo(500, setCargaC, false)


    }

    const eliminarPush = (dato) => {
        setCargaC(true);
        const cs = ciudades;
        cs.splice(dato, 1);
        setAgregar({
            ...agregar,
            ciudades: cs.toString()
        })
        setCiudades(cs);
        tiempo(500, setCargaC, false);
    }

    const inicializarDatos = (datos) => {
        setEditar(datos);
        setCiudadesE(datos.ciudades.split(","));
        const t = datos.dias.split(",");        
        
        sED ((v)=> {
            v.l = t[0] === "" ? false: true;
            v.m = t[1] === "" ? false: true;
            v.mm = t[2] === "" ? false: true;
            v.j = t[3] === "" ? false: true;
            v.v = t[4] === "" ? false: true;
            v.s = t[5] === "" ? false: true;
            v.d = t[6] === "" ? false: true;
            return v;
        })

    }


    const agregarEditarPush = (dato) => {

        if (dato === "0") {
            Alertas('a', 'Por favor seleccione una ciudad para agregar a la rutas')
        } else {
            if (ciudadesE.some(cs => cs === dato)) {
                Alertas('a', 'Esta ciudad ya ha sido agregada')
            } else {
                setCargaC(true);
                const cs = ciudadesE;
                cs.push(dato);
                setEditar({
                    ...editar,
                    ciudades: cs.toString()
                })
                setCiudadesE(cs);
            }

        }
        tiempo(500, setCargaC, false)


    }

    const eliminarEditarPush = (dato) => {
        setCargaC(true);
        const cs = ciudadesE;
        cs.splice(dato, 1);
        setEditar({
            ...editar,
            ciudades: cs.toString()
        })
        setCiudadesE(cs);
        tiempo(500, setCargaC, false);
    }


    return (
        <>
            <Contenedor autorizado={Permisos().rutas}
                titulo={'Rutas'} rutas={['Inicio', 'Rutas']}
                cargar={cargar} mensajeCarga={'Cargando información...'} classNameMensaje={'fcom fs-5 text-dark'} altura={'85vh'}
                error={error} mensajeError={mensaje} claseNameTextError={'text-danger fw-bold fcom fs-5'}
                tablaEncabezado={['Ruta', 'Días de Salida', 'Ciudades', 'Observaciones', 'Acciones']} tablaNombre={'tr'}
                botonAdd={Autorizado([Permisos().rutasAdd]) &&
                    <ModalAdd
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nueva Ruta'}
                        modalTitulo={'Nueva Ruta'}
                        contenidoSuperiorModal={<>
                            <div className="row">
                                <Formularios onChange={(e) => cambiarFormulario(e, agregar, setAgregar)} datos={[[
                                    CrearInput({ type: 'text', name: 'ruta', value: agregar.ruta, placeholder: 'Nombre de la Ruta', clase: 'col-xl-4 col-lg-4 col-sm-12 col-12' }),
                                    CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: ' Observaciones', clase: 'col-xl-8 col-lg-8 col-sm-12 col-12' })
                                ]]} />
                                <div className={'col-12 col-sm-12 col-lg-5 col-xl-5 mb-3'}>
                                    <div>Días de la Semana</div>
                                    {diasSemana(cambiarCheck, ['l', 'm', 'mm', 'j', 'v', 's', 'd'], [d.l, d.m, d.mm, d.j, d.v, d.s, d.d])}

                                </div>
                                <div className={'col-12 col-sm-12 col-lg-7 col-xl-7'}>
                                    <div>Ciudades</div>
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-9 col-sm-8 col-12">
                                            {
                                                <Formularios onChange={cambiar} datos={
                                                    [[CrearInput({
                                                        type: 'select', name: 'c', value: c, placeholder: 'Ciudad', list: { value: "0", titulo: "Seleccione una Ciudad" }, options: city.map((x, j) => {
                                                            return (
                                                                { value: x.id, titulo: x.otro === "" ? x.municipio + ' (' + x.departamento + ') ' : x.otro + ' - ' + x.municipio + ' (' + x.departamento + ') ' }
                                                            )
                                                        })
                                                    })]]
                                                } />
                                            }
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-sm-4 col-12">
                                            <Boton tipo={'principal'} titulo={'Agregar Ciudad'} onClick={() => { agregarPush(c) }} />
                                        </div>
                                        <div className="row">

                                            {
                                                cargaC ? <Carga mensaje={'Agregando Ciudad...'} classNameMensaje={'text-dark fs-6 p-3'} /> :
                                                    ciudadeSelect(ciudades.toString(), city, eliminarPush)
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>}
                        agregar={agregar} tabla={'rutas'} url={'/post'} caso={'rutasAdd'} duplicados={duplicate(true, [agregar.ruta], ['ruta'], ['Este nombre de ruta ya existe'])}
                        infoLog={JSON.stringify(agregar)} principal={principal} mensajeLoadingPromesa={'Agregando Ruta...'} mensajePromesa={'Ruta Agregada'}
                        comprobarTextoVacio={[agregar.ruta, ciudades.length === 0 ? "" : agregar.ciudades, d.length === 0 ? "" : agregar.dias]}
                        mensajeTextoVacio={['Digite un nombre para la ruta', 'Debe seleccionar al menos una ciudad', 'Debe seleccionar al menos un día para la ruta']}
                    />
                }
                tablaFilas={data.map((v, i) => {
                    datos[i] = { ruta: v.ruta, dias: v.dias, ciudades: v.ciudades, obs: v.obs }
                    return (
                        <tr key={i}>
                            {
                                CreaarFila([v.ruta, Dias(v.dias), ciudadesRuta(v.ciudades, city), ''])
                            }
                            <td className="text-center">
                                <ModalEdit
                                    tituloBotonPrincipal={'Editar esta Ruta'}
                                    modalTitulo={'Editar la Ruta: ' + v.ruta}
                                    inicializarDatos={() => inicializarDatos(datos[i])}
                                    contenidoSuperiorModal={
                                        <>
                                            <div className="row">
                                                <Formularios onChange={(e) => cambiarFormulario(e, editar, setEditar)} datos={[[
                                                    CrearInput({ type: 'text', name: 'ruta', value: editar.ruta, placeholder: 'Nombre de la Ruta', clase: 'col-xl-4 col-lg-4 col-sm-12 col-12' }),
                                                    CrearInput({ type: 'text', name: 'obs', value: editar.obs, placeholder: ' Observaciones', clase: 'col-xl-8 col-lg-8 col-sm-12 col-12' })
                                                ]]} />
                                                <div className={'col-12 col-sm-12 col-lg-5 col-xl-5 mb-3'}>
                                                    <div>Días de la Semana</div>
                                                    {diasSemana(cambiarCheckEdit, ['l', 'm', 'mm', 'j', 'v', 's', 'd'], [ed.l, ed.m, ed.mm, ed.j, ed.v, ed.s, ed.d])}

                                                </div>
                                                <div className={'col-12 col-sm-12 col-lg-7 col-xl-7'}>
                                                    <div>Ciudades</div>
                                                    <div className="row">
                                                        <div className="col-xl-9 col-lg-9 col-sm-8 col-12">
                                                            {
                                                                <Formularios onChange={cambiar} datos={
                                                                    [[CrearInput({
                                                                        type: 'select', name: 'c', value: c, placeholder: 'Ciudad', list: { value: "0", titulo: "Seleccione una Ciudad" }, options: city.map((x, j) => {
                                                                            return (
                                                                                { value: x.id, titulo: x.otro === "" ? x.municipio + ' (' + x.departamento + ') ' : x.otro + ' - ' + x.municipio + ' (' + x.departamento + ') ' }
                                                                            )
                                                                        })
                                                                    })]]
                                                                } />
                                                            }
                                                        </div>
                                                        <div className="col-xl-3 col-lg-3 col-sm-4 col-12">
                                                            <Boton tipo={'principal'} titulo={'Agregar Ciudad'} onClick={() => { agregarEditarPush(c) }} />
                                                        </div>
                                                        <div className="row">

                                                            {
                                                                cargaC ? <Carga mensaje={'Agregando Ciudad...'} classNameMensaje={'text-dark fs-6 p-3'} /> :
                                                                    ciudadeSelect(ciudadesE.toString(), city, eliminarEditarPush)
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    }
                                    
                                    editar={editar} tabla={'rutas'} url={'/put'} caso={'rutasEdit'} duplicados={duplicate(true, [editar.ruta], ['ruta'], ['Este nombre de ruta ya existe'], [`and id != '${v.id}'`])}
                                    infoLog={JSON.stringify(editar)} idLog={v.id} principal={principal} mensajeLoadingPromesa={'Editando datos...'} mensajePromesa={'Datos Actualizados'}
                                    comprobarTextoVacio={[editar.ruta, ciudadesE.length === 0 ? "" : editar.ciudades, ed.length === 0 ? "" : editar.dias]}
                                    mensajeTextoVacio={['Digite un nombre para la ruta', 'Debe seleccionar al menos una ciudad', 'Debe seleccionar al menos un día para la ruta']}
                                    id={`id = ${v.id}`}
                                />
                                &nbsp; &nbsp;
                                <ModalDelete
                                tituloBotonPrincipal={'Eliminar Ruta'}
                                modalTitulo={'Eliminar Ruta: '+v.ruta}
                                mensajeEliminar={<>¿Desea eliminar la Ruta <strong>{v.id}</strong> que sale los días <strong>{escribirDias(v.dias)}</strong> a las ciudades <strong>{escribirCiudades(v.ciudades, city)}</strong></>}
                                condicionEliminar={`id = ${v.id}`} consultarHistorial={datosConsultarHistorial(false,['direccion_cliente'], ['ruta'], ['Esta ruta esta asignada a direcciones del cliente'])}
                                mensajeLoadingPromesa={'Eliminado Ruta...'} mensajePromesa={'Ruta Eliminada'} principal={principal}
                                tabla={'rutas'} infoLog={JSON.stringify(datos[i])} caso={'rutasDel'} url={'/del'} valorEliminar={v.id}
                            
                                />
                            </td>
                        </tr>
                    )

                })}

            />
        </>
    )
};
