import Header from "0Plantilla/Header"
import Footer from "0Plantilla/Footer"
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar"
import "infortech_modules/Recursos/Estilos/ProSideBar.scss";
import { useProSidebar } from "react-pro-sidebar";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsDatabaseFillCheck, BsFillUsbDriveFill, BsImage, BsPSquareFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { FaRegAddressCard, FaUsers, FaImage, FaHandHoldingMedical, FaHospitalUser, FaUserCircle } from "react-icons/fa";
import { ImExit } from "react-icons/im"
import { Link } from "react-router-dom";
import Main from "./Main";
import Logo from "../infortech_modules/Recursos/Imagenes/bg_oxioriente_2020.jpg"
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import { TbCylinder } from "react-icons/tb";
import { GoTasklist } from "react-icons/go";
import { MdReadMore } from "react-icons/md";



export default function Layout({ iniciar }) {

    const menuClasses = {
        root: 'ps-menu-root',
        menuItemRoot: 'ps-menuitem-root',
        subMenuRoot: 'ps-submenu-root',
        button: 'ps-menu-button',
        prefix: 'ps-menu-prefix',
        suffix: 'ps-menu-suffix',
        label: 'ps-menu-label',
        icon: 'ps-menu-icon',
        subMenuContent: 'ps-submenu-content',
        SubMenuExpandIcon: 'ps-submenu-expand-icon',
        disabled: 'ps-disabled',
        active: 'ps-active',
        open: 'ps-open',
    };

    const themes = {
        light: {
            sidebar: {
                backgroundColor: 'rgba(255,255,255,0.3)',
                color: '#607489',

            },
            menu: {
                menuContent: 'rgba(255,255,255,0.1)',
                hover: {
                    backgroundColor: 'rgba(109,40,217,0.4)',
                    color: 'white',

                },
                disabled: {
                    color: '#9fb6cf',
                },
            },
        },

    };



    const menuItemStyles = {
        root: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: "Comfortaa",
            color: 'white'

        },

        size: 19,

        icon: {
            color: themes.light.menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes.light.menu.disabled.color,
            },

        },

        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                themes.light.menu.menuContent
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes.light.menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: themes.light.menu.hover.backgroundColor,
                color: themes.light.menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };


    const { toggleSidebar, broken } = useProSidebar();

    return (
        <>
            {
                broken ? (
                    <div className="min-width-full fixed z2">
                        <div className="bg-p broken">
                            <div className="col-11"></div>
                            <div className="col-1"><FiMenu size={28} onClick={() => toggleSidebar()} /></div>
                        </div>
                    </div>
                ) : (
                   <></>
                )
            }
            <div className="ly-layout">
                <Sidebar
                    breakPoint="lg"
                    backgroundColor={(themes.light.sidebar.backgroundColor)}
                    image={Logo}


                >
                    <Header broken={broken} />
                    <Menu menuItemStyles={menuItemStyles}>
                        <MenuItem className="hover" component={<Link to="/dashboard" />} icon={<RxDashboard size={menuItemStyles.size} />} >Dashboard</MenuItem>
                        <SubMenu label="Operaciones" icon={<GoTasklist size={menuItemStyles.size}  />}>
                            {Autorizado([Permisos().clientesComercial]) && usuario().area === 1 && <MenuItem className="hover" component={<Link to="/operaciones/alistar" />} icon={<MdReadMore size={menuItemStyles.size} />}>Preparar Pedidos</MenuItem>}
                            <MenuItem className="hover" component={<Link to="/clientes/comercial" />} icon={<FaUserCircle size={menuItemStyles.size} />}>Área Comercial</MenuItem>
                        </SubMenu>
                        
                        <SubMenu label="Clientes" icon={<FaUsers size={menuItemStyles.size} />}>
                            {Autorizado([Permisos().clientesComercial]) && usuario().area === 1 && <MenuItem className="hover" component={<Link to="/clientes/medicinal" />} icon={<FaHospitalUser size={menuItemStyles.size} />}>Área Medicinal </MenuItem>}
                            <MenuItem className="hover" component={<Link to="/clientes/comercial" />} icon={<FaUserCircle size={menuItemStyles.size} />}>Área Comercial</MenuItem>
                        </SubMenu>
                        <SubMenu label="Pedidos" icon={<BsPSquareFill size={menuItemStyles.size} />}>
                            {Autorizado([Permisos().clientesComercial]) && usuario().area === 1 && <MenuItem className="hover" component={<Link to="/pedidos/medicinal" />} icon={<FaHandHoldingMedical size={menuItemStyles.size} />}>Área Medicinal </MenuItem>}
                            <MenuItem className="hover" component={<Link to="/pedidos/comercial" />} icon={<BsImage size={menuItemStyles.size} />}>Área Comercial</MenuItem>
                        </SubMenu>
                        
                        <MenuItem className="hover" component={<Link to="/rutas" />} icon={<FaUsers size={menuItemStyles.size} />}>Rutas</MenuItem>
                        <SubMenu label="Cilindros" icon={<BsFillUsbDriveFill size={menuItemStyles.size} />}>
                            <MenuItem className="hover" component={<Link to="/cilindros/cilindros" />} icon={<TbCylinder size={menuItemStyles.size} />}>Cilindros</MenuItem>
                            <MenuItem className="hover" component={<Link to="/cilindros/llenado" />} icon={<BsDatabaseFillCheck size={menuItemStyles.size} />}>Llenado</MenuItem>
                            <MenuItem className="hover" component={<Link to="/tarjetas/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Vaciar Cilindros</MenuItem>
                            <MenuItem className="hover" component={<Link to="/tarjetas/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Movimientos Lote</MenuItem>
                        </SubMenu>
                        <SubMenu label="Equipo" icon={<FaRegAddressCard size={menuItemStyles.size} />}>
                            <MenuItem className="hover" component={<Link to="/equipos/equipos" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Equipos</MenuItem>
                            <MenuItem className="hover" component={<Link to="equipos/listado/mantenimiento/" />} icon={<BsImage size={menuItemStyles.size} />}>Mantenimientos</MenuItem>
                            <MenuItem className="hover" component={<Link to="equipos/listado/calibracion/" />} icon={<BsImage size={menuItemStyles.size} />}>Calibraciones</MenuItem>
                            <MenuItem className="hover" component={<Link to="/tarjetas/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Transferencia de Equipos</MenuItem>
                        </SubMenu>
                        <MenuItem className="hover" component={<Link to="/usuarios" />} icon={<FaUsers size={menuItemStyles.size} />}>Bodegas</MenuItem>

                        
                        <SubMenu label="IPS" icon={<FaRegAddressCard size={menuItemStyles.size} />}>
                            <MenuItem className="hover" component={<Link to="/ips/eps" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>EPS</MenuItem>
                            <MenuItem className="hover" component={<Link to="/ips/pedidos" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Pedidos</MenuItem>
                            <MenuItem className="hover" component={<Link to="/ips/pacientes" />} icon={<BsImage size={menuItemStyles.size} />}>Pacientes</MenuItem>
                            <MenuItem className="hover" component={<Link to="/ips/planes" />} icon={<FaImage size={menuItemStyles.size} />}>Planes</MenuItem>
                            <MenuItem className="hover" component={<Link to="/tarjetas/portada" />} icon={<FaImage size={menuItemStyles.size} />}>Renovaciones</MenuItem>
                            <MenuItem className="hover" component={<Link to="/ips/residuales" />} icon={<FaImage size={menuItemStyles.size} />}>Residuales</MenuItem>
                        </SubMenu>
                        <SubMenu label="Configuraciones" icon={<FaRegAddressCard size={menuItemStyles.size} />}>
                            <MenuItem className="hover" component={<Link to="/configuraciones/ciudades" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Ciudades</MenuItem>
                            <MenuItem className="hover" component={<Link to="/configuraciones/actividades" />} icon={<BsImage size={menuItemStyles.size} />}>Actividades</MenuItem>
                            <MenuItem className="hover" component={<Link to="/configuraciones/zonas" />} icon={<FaImage size={menuItemStyles.size} />}>Zonas</MenuItem>
                        </SubMenu>
                        <SubMenu label="Familias" icon={<FaRegAddressCard size={menuItemStyles.size} />}>
                            <MenuItem className="hover" component={<Link to="/familias/familias" />} icon={<AiFillInfoCircle size={menuItemStyles.size} />}>Familias</MenuItem>                            
                            <MenuItem className="hover" component={<Link to="/familias/productos" />} icon={<FaImage size={menuItemStyles.size} />}>Productos</MenuItem>
                        </SubMenu>



                        <MenuItem className="hover" component={<Link to="/salir" />} icon={<ImExit size={menuItemStyles.size} />}>Cerrar Sesión </MenuItem>
                    </Menu>
                    <Footer />
                </Sidebar>                
                    <Main iniciar={iniciar} />
              
            </div>
        </>
    )
};
