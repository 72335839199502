import Alertas from "infortech_modules/Componentes/Alertas";

export default function EsNumero(array, array1) {
    let numeros = /^[0-9]+$/;
    let estado = "correcto";
    
    if (Array.isArray(array)) {
        if (Array.isArray(array1)) {
            for (let i = 0; i < array.length; i++) {
                if (!array[i].match(numeros)) {
                    Alertas("a", array1[i]);
                    estado = "error";
                    i = array.length;
                }
            }

            if (estado === "correcto") {
                return true
            } else {
                return false
            }
        } else {
            Alertas("e", "Error 4: Datos de Texto inválidos")
            return false;
        }

    } else {
        Alertas("e", "Error 3: Formato de número inválidos")
        return false;
    }
}

export function NumerosCeros(number, width) {
    if(number !== undefined){
      const numberOutput = Math.abs(number); /* Valor absoluto del número */
      const length = number.toString().length; /* Largo del número */
      const zero = "0"; /* String de cero */
    
      if (width <= length) {
        if (number < 0) {
          return ("-" + numberOutput.toString());
        } else {
          return numberOutput.toString();
        }
      } else {
        if (number < 0) {
          return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
        } else {
          return ((zero.repeat(width - length)) + numberOutput.toString());
        }
      }
    }
    else{
      return 0;
    }
   
  }
  
  export function FormatearNumero(valor) {
    const con = Number(valor);
    return new Intl.NumberFormat().format(con);
  }