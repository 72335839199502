import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Modales from "infortech_modules/Componentes/Modales";
import Tabla from "infortech_modules/Componentes/Tabla";
import { determinarEstadoPedidos, tiempo } from "infortech_modules/Constantes/Constantes";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { NumerosCeros } from "infortech_modules/Constantes/EsNumero";
import { FormatearFecha } from "infortech_modules/Constantes/Fecha";
import Permisos from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";

export default function VerPedidos({ idPedido, area }) {
    const [carga, setCarga] = useState(true);
    const [error, setError] = useState(false);
    const [mensje, setMensaje] = useState("");
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [detalle, setDetalle] = useState([]);

    const principal = (est) => {
        est && setCarga(true);
        Get({ url: '/get/general', key: "VerPedidos", data0: area, data1: idPedido }).then((r) => {
            setData(r[0][0]);
            setDetalle(r[1]);
            tiempo(200, setCarga, false)
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(200, setCarga, false)
        })
    }
    const open = () => {
        setModal(true)
        principal();

    }

    const closed = () => {
        setError(false);
        setCarga(true);
        setModal(false);
    }

    const definirTipo = (tipo, familia, nom, cap, norma) => {
        let retornar;
        switch (tipo) {
            case "Producto":
                retornar = familia + ' ' + nom + ' ' + cap + ' (' + norma + ')'
                break;
            case "Equipo":
                retornar = familia + ' (' + nom + ')';
                break;
            default:
                break;
        }
        return retornar;
    }


    return (
        <>
            <div className="infoText">
                <IoEyeSharp
                    size={25}
                    color="blue" className="pointer"
                    onClick={() => open()}
                />
                <span className="textText-Top ">Ver Pedido</span>
            </div>
            <Modales
                titulo={"Detalles del Pedido No. " + NumerosCeros(idPedido, 7)}
                show={modal}
                onHide={closed}
                body={<Contenedor autorizado={Permisos().pedidos} altura={'55vh'}
                    mensajeCarga={"Cargando información del pediddo..."} cargar={carga} error={error} mensajeError={mensje}
                    tablaEncabezado={["Ítem", "Tipo", "Descripción", "Cant"]} tablaNombre={"tvp"}
                    tablaFilas={detalle.map((v, i) => {
                        return <tr key={i}>
                            {CreaarFila([i + 1, v.tipo, definirTipo(v.tipo, v.familia, v.nom, v.cap, v.norma), v.cant], "scope", [""], "")}
                        </tr>
                    })}
                    header={<>
                        <div className="mb-4">
                            <Tabla encabezado={['Fecha de Pedido', 'Fecha Programada', 'Estado']}
                                filas={[CreaarFila([FormatearFecha(new Date(data.fped), 3), FormatearFecha(new Date(data.fdes), 2), determinarEstadoPedidos(data.est)], "scope")]}
                            />
                            <Tabla encabezado={['Cliente', 'Identificación', 'Dirección de Despacho', 'Ciudad']}
                                filas={[CreaarFila([data.cliente, data.doc, data.dir, data.otro + ' ' + data.municipio + ' (' + data.departamento + ')'])]}
                            />

                        </div>
                        <hr />
                        <div className="mb-4 text-center h4">Detalles del Pedido</div>
                    </>}
                />}

                footer={<Boton tipo={'secondary'} onClick={() => closed()} titulo={'Cerrar'} />}
            />
        </>
    )
};
