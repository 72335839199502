import jQuery from "jquery";
import $ from "jquery";
import { CrearEncabezado } from "infortech_modules/Constantes/CrearTabla";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";


export default function Tabla({ encabezado, filas, nombre, claseEncabezado, ordenar, ordenNo, tipoOrden }) {
    ordenar = ordenar === undefined ? false:ordenar;
    if(ordenar){
        jQuery(function () {
            $(`#tabla${nombre}`).DataTable({
                order: [ordenNo, tipoOrden],
                retrieve: true,
            });
            
        })
    } else{
        jQuery(function () {
            $(`#tabla${nombre}`).DataTable();
            
        })
    }

    function DibujarTabla() {
        if (nombre !== undefined) {
            return (
                <div className="table-responsive">
                    <small>
                        <table className="table table-striped table-hover" id={`tabla${nombre}`}>
                            <thead>
                                <tr>
                                    {CrearEncabezado(encabezado, "col", [""], claseEncabezado)}

                                </tr>
                            </thead>
                            <tbody>
                                {filas}
                            </tbody>
                        </table>
                    </small>
                </div>
            )
        } else {
            return (
                <div className="table-responsive">
                    <small>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    {CrearEncabezado(encabezado, "col", [""], claseEncabezado)}
                                </tr>
                            </thead>
                            <tbody>
                                {filas}
                            </tbody>
                        </table>
                    </small>
                </div>
            )
        }
    }

    function Proyectar() {        
        if(encabezado !== undefined){
            return DibujarTabla()
        }else{
            return ""
        }     
    }

    return Proyectar();

    
};
