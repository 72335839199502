import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Modales from "infortech_modules/Componentes/Modales";
import { buscarTipoCliente, cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Post from "infortech_modules/Crud/Post";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useEffect, useState } from "react";
import Direcciones from "./Direcciones";


export default function ClientesMedicinal({ iniciar }) {
    const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({
        tp: "1", td: "1", doc: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "1", sexo: "", fnac: "0000-00-00", mail: "", zona: "1", actividad: "1", est: 1,
    })
    const [editar, setEditar] = useState({
        tp: "", doc: "", td: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "", mail: "", zona: "", actividad: ""
    })
    const [td, setTd] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [actividad, setActividad] = useState([]);
    const [zona, setZona] = useState([]);
    const [rutas, setRutas] = useState([]);
    const [disabledInput, setDisabledInput] = useState(true);
    const [btnDis, setBtnDis] = useState(false);
    const [btnSnp, setBtnSnp] = useState(false);
    const [cambio, setCambio] = useState(true);
    const [modalConfirmacion, setModalConfirmacion] = useState(false);
    const [mensajeModal, setMensajeModal] = useState('');
    const [btnModal, setBotonModal] = useState(false);

    function open(dato) {
        setMensajeModal(dato);
        setTimeout(() => {
            setModalConfirmacion(true);
        }, 250);
    }

    const closed = () => {
        setModalConfirmacion(false)
        setMensajeModal('');
    };


    useEffect(() => {
        principal()
        // eslint-disable-next-line 
    }, [])

    const principal = (est) => {
        est && <>
            {setCargar(true)};
            {setBtnDis(false)};
            {setAgregar({         tp: "1", td: "1", doc: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "1", sexo: "", fnac: "0000-00-00", mail: "", zona: "1", actividad: "1", est: 1,
 })}
            {setDisabledInput(true)}

        </>


        Get({ url: '/get/general', key: 'clientes', data0: '2', iniciar: iniciar }).then((r) => {
            setData(r[0])
            setTd(r[1]);
            setMunicipios(r[2]);
            setActividad(r[3]);
            setZona(r[4]);
            setRutas(r[5])
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setCargar, false, setError, true)
        })
    }

    const buscarCliente = () => {
        setBtnDis(true);
        setBtnSnp(true);
        if (EsNumero([agregar.doc], ['El documento del cliente debe ser un valor númerico'])) {
            const promesa = new Promise((res, rej) => {
                Get({ url: '/get', key: '2', data1: agregar.doc, data0: 0 }).then((r) => {
                    if (r.length === 0) {
                        tiempo(500, res, 'Número de Documento disponible para registro, puede continuar', setDisabledInput, false, setBtnSnp, false, setCambio, false, setBotonModal, true)
                    } else {
                        if (buscarTipoCliente(r, 2)) {
                            tiempo(500, rej, 'Error: Existe un cliente registrado con este número de documento', setBtnDis, false, setBtnSnp, false);
                        } else if (buscarTipoCliente(r, 1) && buscarTipoCliente(r, 0)) {
                            tiempo(500, rej, 'Un cliente con este número de documento esta registrada en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito tanto en el área comercial como paciente.</div><div className="fw-bold fst-italic">¿Desea grabarlo en el área medicinal?</div></div>)
                        } else if (buscarTipoCliente(r, 1)) {
                            tiempo(500, rej, 'Un cliente con este número de documento esta registrada en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito como paciente.</div><div className="fw-bold fst-italic">¿Desea grabarlo en el área medicinal?</div></div>)
                        } else if (buscarTipoCliente(r, 0)) {
                            tiempo(500, rej, 'Un cliente con este número de documento esta registrada en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito en el área comercial.</div><div className="fw-bold fst-italic">¿Desea grabarlo en el área medicinal?</div></div>)
                        }
                    }
                }).catch((f) => {
                    tiempo(500, setBtnDis, false, rej, f)
                })
            })
            AlertaPromesa('Consultando número de documento...', promesa, 'a');
        } else {
            tiempo(1200, setBtnDis, false, setBtnSnp, false);
        }
    }

    const grabar = () => {
        const promesa = new Promise((res, rej) => {
            Post({ url: '/post', data0: { idCliente: agregar.doc, ipa: 2, est: 1, asesor:usuario().id }, caso: 'ClientesComercialAdd', table: 'tipoCliente', infoLog: JSON.stringify({ idCliente: agregar.doc, ipa: 2, est: 1 }), duplicate: duplicate(false) })
                .then((r) => {
                    tiempo(500, res, 'Se agrego el cliente al área medicinal', principal, true, setModalConfirmacion, false);
                })
                .catch((f) => {
                    tiempo(500, rej, f)
                })

        })
        AlertaPromesa('Gurdando en el área medicinal...', promesa, 'e');
    }

    const formEdit = [
        [
            CrearInput({ type: 'select', name: 'td', value: editar.td, disabled: true, options: td.map((v, i) => { return { value: v.id, titulo: v.nom } }), placeholder: 'Tipo de Documento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'text', name: 'doc', value: editar.doc, disabled: true, placeholder: 'No. de Documento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        editar.tp === 1 ?
            [CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: "Razón Social", clase: 'col-12' })] :
            [
                CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: "Nombres", clase: 'col-6' }),
                CrearInput({ type: 'text', name: 'ape', value: editar.ape, placeholder: "Apellidos", clase: 'col-6' })
            ],
        [CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: 'Teléfono' }), CrearInput({ type: 'text', name: 'cel', value: editar.cel, placeholder: 'Celular' })],
        [CrearInput({ type: 'text', name: 'mail', value: editar.mail, placeholder: 'Correo Eletrónico' })],
        [
            CrearInput({ type: 'select', name: 'actividad', value: editar.actividad, options: actividad.map((v, i) => { return { value: v.id, titulo: v.actividad } }), placeholder: 'Actividad Cliente', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'select', name: 'zona', value: editar.zona, options: zona.map((v, i) => { return { value: v.id, titulo: v.zona } }), placeholder: 'Zona', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({
                type: 'select', name: 'ciudad', value: editar.ciudad, options: municipios.map((v, i) => {
                    return {
                        value: v.id, titulo: v.otro === "" ? v.ciudad + " (" + v.dep + ")" : v.otro + " - " + v.ciudad + " (" + v.dep + ")"
                    }
                }), placeholder: 'Ciudad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12'
            }),
        ]

    ]

    const inicializarDatos = (d) => {
        setEditar(d);
    }


    const row = data.map((v, i) => {
        datos[i] = { tp: v.tp, doc: v.doc, td: v.td, nom: v.nom, ape: v.ape, tel: v.tel, cel: v.cel, dir: v.dir, ciudad: v.ciudad, mail: v.mail, zona: v.idZona, actividad: v.idActividad }
        return (
            <tr key={i}>
                {
                    CreaarFila([v.nom + ' ' + v.ape, v.sigla, v.doc, v.tel, v.municipio], "", [""], "text-left")
                }
                <td className="text-center">
                    {
                        Autorizado([Permisos().clientesComercialEdit]) &&
                        <ModalEdit
                            modalTitulo={'Editar Cliente: ' + v.nom + ' ' + v.ape}
                            formulario={formEdit} inicializarDatos={() => inicializarDatos(datos[i])} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                            comprobarTextoVacio={[editar.nom, editar.tp === 1 ? "ok" : editar.ape, editar.tel, editar.mail, editar.dir]}
                            mensajeTextoVacio={[editar.tp === 1 ? "Digite la Razón Social" : "Digite los Nombres", "Digite los Apellidos", "Digite un número de teléfono", "Digite un Correo Electrónico", "Escriba la dirección del Cliente"]}
                            numeroComprobar={[editar.tel.toString(), editar.cel === "" ? "1" : editar.cel.toString()]}
                            mensajeNumeroComprobar={['El teléfono debe ser un valor múmerico', 'El celular debe ser un valor númerico']}
                            mensajeLoadingPromesa={'Editando...'} mensajePromesa={'Registro Editado...'} infoLog={`${JSON.stringify(datos[i])} -> ${JSON.stringify(editar)}`}
                            duplicados={duplicate(false)}
                            url={'/put'} caso={'clientesMedicinalEdit'} id={`id = ${v.id}`} idLog={v.id} tabla={'clientes'} editar={editar} principal={principal}

                        />

                    }
                    &nbsp;
                    {
                        Autorizado([Permisos().clientesComercialDel]) &&
                        <ModalDelete
                        modalTitulo={'Eliminar Cliente: '+v.nom + ' '+v.ape}
                        mensajeEliminar={<>¿Desea eliminar {v.tp === 1? 'a la empresa':'Sr (a)'} <strong>{v.nom + ' '+v.ape}</strong> identificado con <strong>{v.sigla+' '+v.doc}</strong>?</>}
                        mensajeLoadingPromesa={'Eliminado Cliente'} mensajePromesa={'Cliente Eliminado'}
                        consultarHistorial={datosConsultarHistorial(true)}
                        url={'/del/general'} key1={'clientesDel'} data0={v.doc} data1={0} tabla={'clientes'} condicionEliminar={`id = ${v.id}`} valorEliminar={v.id}
                        principal={principal} caso={'clientesMedicinalDel'}                      

                        />

                    }
                    &nbsp;
                    {
                        Autorizado([Permisos().clientesMedicinalDir]) &&
                        <Direcciones
                        id={v.id}
                        cliente={v.nom + ' '+v.ape }     
                        ruta={rutas}      
                        />
                    }

                </td>
            </tr>
        )
    })


    const formAdd = [
        [CrearInput({ type: 'select', name: 'tp', value: agregar.tp, disabled: disabledInput, options: [{ value: '1', titulo: 'Persona Jurídica' }, { value: '2', titulo: 'Persona Natural' }], placeholder: 'Tipo de Persona', clase: 'col-6' }),
        CrearInput({ type: 'select', name: 'td', value: agregar.td, disabled: disabledInput, options: td.map((v, i) => { return { value: v.id, titulo: v.nom } }), placeholder: 'Tipo de Documento', clase: 'col-6' }),
        ],

        agregar.tp === "1" ?
            [CrearInput({ type: 'text', name: 'nom', value: agregar.nom, disabled: disabledInput, placeholder: "Razón Social", clase: 'col-12' })] :
            [
                CrearInput({ type: 'text', name: 'nom', value: agregar.nom, disabled: disabledInput, placeholder: "Nombres", clase: 'col-6' }),
                CrearInput({ type: 'text', name: 'ape', value: agregar.ape, disabled: disabledInput, placeholder: "Apellidos", clase: 'col-6' })
            ],
        [CrearInput({ type: 'text', name: 'tel', value: agregar.tel, disabled: disabledInput, placeholder: 'Teléfono' }), CrearInput({ type: 'text', name: 'cel', value: agregar.cel, disabled: disabledInput, placeholder: 'Celular' })],
        [CrearInput({ type: 'text', name: 'mail', value: agregar.mail, disabled: disabledInput, placeholder: 'Correo Eletrónico' })],
        [
            CrearInput({ type: 'select', name: 'actividad', value: agregar.actividad, disabled: disabledInput, options: actividad.map((v, i) => { return { value: v.id, titulo: v.actividad } }), placeholder: 'Actividad Cliente', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'select', name: 'zona', value: agregar.zona, disabled: disabledInput, options: zona.map((v, i) => { return { value: v.id, titulo: v.zona } }), placeholder: 'Zona', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'text', name: 'dir', value: agregar.dir, disabled: disabledInput, placeholder: 'Dirección', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({
                type: 'select', name: 'ciudad', value: agregar.ciudad, disabled: disabledInput, options: municipios.map((v, i) => {
                    return {
                        value: v.id, titulo: v.otro === "" ? v.ciudad + " (" + v.dep + ")" : v.otro + " - " + v.ciudad + " (" + v.dep + ")"
                    }
                }), placeholder: 'Ciudad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12'
            }),
        ]
        
    ]

    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar);
    }

    const cambiarDoc = () => {
        setCambio(true);
        setDisabledInput(true);
        setBtnDis(false);
        setBotonModal(false);
    }


    return (
        <>
            <Contenedor
                titulo={'Clientes Área Medicinal'}
                rutas={['Clientes', 'Medicinal']} autorizado={Permisos().clientesComercial}
                cargar={cargar} mensajeCarga={'Obteniendo información de clientes...'} altura='85vh' classNameMensaje={'text-dark fs-5 fw-bold fcom'} anchoImagen={100}
                error={error} mensajeError={mensaje} claseNameTextError={'fs-5 fcom text-danger fw-bold'}
                tablaFilas={row} tablaEncabezado={['Cliente', 'TD', 'No. Documento', 'Teléfono', 'Ciudad', 'Acción']} tablaNombre={'tu'}
                botonAdd={
                    Autorizado([Permisos().clientesComercialAdd]) &&
                    <ModalAdd
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nuevo Cliente'}
                        formulario={formAdd} onChange={cambiar}
                        modalTitulo={'Nuevo Cliente - Área Medicinal'}
                        tipoBotonEnviar={'principal'}
                        tituloBotonEnviar={'Guardar'}
                        disabledBotonEnviar={btnModal}
                        contenidoSuperiorModal={
                            <>
                                <div className="row mb-3">
                                    <div className="col-xl-6 col-lg-6 col-sm-8 col-9">
                                        <div className="form-floating">
                                            <input type={'text'} name='doc' value={agregar.doc} placeholder={'No de Documento'} className='form-control' onChange={cambiar} disabled={!disabledInput} />
                                            <label>No de Documento</label>
                                        </div>

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-4 col-3">

                                        {
                                            cambio ?
                                                <Boton tipo={'primary'} titulo={'Buscar'} disabled={btnDis} SpinnerBoton={btnSnp} onClick={() => buscarCliente()} /> :
                                                <Boton tipo={'primary'} titulo={'Cambiar'} SpinnerBoton={btnSnp} onClick={() => cambiarDoc()} />
                                        }

                                    </div>
                                </div>

                            </>
                        }

                        tabla={'clientes'} caso={'ClientesMedicinalAdd'} key1={"ClientesComercialAdd"}
                        duplicados={duplicate(true, [agregar.doc], ['doc'], ['Error: Documento ya esta registrado'])} datos1={'2'} datos2={agregar.doc}
                        comprobarTextoVacio={[agregar.nom, agregar.tp === '1' ? "ok" : agregar.ape, agregar.tel, agregar.mail, agregar.dir]}
                        mensajeTextoVacio={[agregar.tp === "1" ? "Digite la Razón Social" : "Digite los Nombres", "Digite los Apellidos", "Digite un número de teléfono", "Digite un Correo Electrónico", "Escriba la dirección del Cliente"]}
                        numeroComprobar={[agregar.tel, agregar.cel === "" ? "1" : agregar.cel]}
                        mensajeNumeroComprobar={['El teléfono debe ser un valor múmerico', 'El celular debe ser un valor númerico']}
                        mensajeLoadingPromesa={'Creando Cliente...'} mensajePromesa={'Registro Correcto'} infoLog={JSON.stringify(agregar)}
                        url={'/post/general'} agregar={agregar} principal={principal}
                    />
                }



            />
            <Modales
                titulo={'Confirmar'}
                show={modalConfirmacion}
                onHide={closed}
                size={'lg'}
                body={
                    <>
                        {mensajeModal}
                    </>
                }
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cancelar'} onClick={() => closed()} />
                    <Boton tipo={'principal'} titulo={'Si, Grabar'} onClick={() => grabar()} />
                </>}
            />
        </>
    )
};
