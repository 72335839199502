export default function CrearInput({type, name, value, placeholder, clase, options, list, disabled, readonly, claseForm, spinner, required, busqueda}) {
    return {
        type:type, 
        name:name,
        value:value,
        placeholder:placeholder, 
        clase:clase,
        options:options,
        list:list,
        disabled:disabled, 
        readonly:readonly,
        claseForm:claseForm,
        spinner:spinner,
        required:required,
        busqueda:busqueda
    }
};
