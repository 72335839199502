import "infortech_modules/Recursos/Estilos/inicio.scss";
import Logo from "infortech_modules/Recursos/Imagenes/Logo.png";
import DateTime from "infortech_modules/Constantes/Fecha";
import { useState } from "react";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import Formularios from "infortech_modules/Componentes/Formularios";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { Link } from "react-router-dom";
import Lectura from "infortech_modules/Constantes/Lectura";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import axios from "axios";
import api from "infortech_modules/Constantes/Constantes";
export default function Iniciar({iniciar}) {

    const [loguin, setLoguin] = useState({
        user: "", pass: "", fecha: DateTime()
    })
    const [disabled, setDisabled] = useState(false);

    const cambiar = (e) => {
        setLoguin({
            ...loguin,
            [e.target.name]: e.target.value
        })
    }
    
    const entrar = () => {
        let tipo;
        const te = (t, f, e) => {
            setTimeout(() => {
                f(e)
            }, t);

        }
        setDisabled(true);
        if(EsVacio([loguin.user, loguin.pass],['Digite su nombre de usuario o correo electónico', "Digite su contraseña"])){
            const promesa = new Promise((res, rej) => {
                axios.post(api + '/loguin', {
                    plataforma: "web", loguin: loguin
                }).then((r) => {    
                                    console.log(r);
                    tipo = "a";
                    if (r.data.res) {
                        localStorage.setItem('sicma', r.data.msje);                       

                        setTimeout(() => {
                            setDisabled(false);
                            res('Usuario Autorizado')
                        }, 400);
                        setTimeout(() => {
                            iniciar();
                        }, 300);
                    } else {                        
                        setTimeout(() => {
                            setDisabled(false)
                            rej(Lectura(r.data.msje, iniciar))
                        }, 250);
                    }
                }).catch((f) => {
                    tipo = "e";
                    console.log(f);
                    setTimeout(() => {                        
                        rej(Lectura(f,iniciar))
                        setDisabled(false)
                    }, 250);
                })
            })
            AlertaPromesa("Iniciando sesión", promesa, tipo);
        }else{
            te(1000, setDisabled, false)
        }
    }

    const form = [
        [CrearInput({ type: 'email', name: 'user', value: loguin.user, placeholder: 'Digite su usuario' })],
        [CrearInput({ type: 'password', name: 'pass', value: loguin.pass, placeholder: 'Digite su contraseña' })],
    ]

    const bot = [
        [CrearInput({ type: 'boton', spinner: false, placeholder: 'Iniciar Sesión', clase: 'd-grid gap-2', claeForm: 'btn btn-tema', value: { tipo: 'principal', titulo: 'Iniciar Sesión', onClick: () => entrar(), disabled: disabled } })]
    ]

    return (
        <>
            <div>
                <div className="inicio-body">
                    <div className="inicio-contenedor">
                        <div className="inicio-subcontenedor">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-6 inicio-logo">
                                    <img src={Logo} className="col-9" alt="Logo de SICMA - OXIORIENTE" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-6 text-center inicio-texto">
                                    <div className="p-4">
                                        <h4 className="h4 fw-bold fs-5 mb-3">SICMA V2.0</h4>
                                        <div className="fs-11 mb-4">Ingrese sus datos para iniciar sesión</div>
                                        <div className="scaled">
                                            {<Formularios datos={form} onChange={cambiar} />}                                        
                                        </div>
                                        <div className="fs-11 mb-3">
                                            <Link to='recuperar' className="no-underline font-black text-xs">¿Has olvidado tu contraseña?</Link>
                                        </div>
                                        <div>{<Formularios datos={bot} />}</div>
                                        <div><Link to='/Registrar' className="no-underline font-black text-xs">Registrarse</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
