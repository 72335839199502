import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Tabla from "infortech_modules/Componentes/Tabla";
import { buscarValor, cambiarFormulario, datosConsultarHistorial, duplicate, extraerCliente, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Permisos, { Autorizado, usuario } from "infortech_modules/Constantes/Usuarios";
import Post from "infortech_modules/Crud/Post";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useState } from "react";

export default function PResiduales({ productos, temporal, open }) {
    const datos = [];
    productos = productos === undefined ? [] : productos;
    temporal = temporal === undefined ? [] : temporal;
    const [agregar, setAgregar] = useState({ vid: "", des: "", usr: usuario().id, tipo: "PResiduales" });
    const [editar, setEditar] = useState({ vid: "", des: "" });
    const [disabled, setDisabled] = useState(false);
    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar)
    }

    const guardar = () => {
        setDisabled(true);
        if (EsVacio([buscarValor(productos, agregar.vid) ? "ok" : "", agregar.des, agregar.des > 0 ? "ok" : ""], ['Seleccione un Producto de la Lista', 'Digite la Cantidad', "La  cantidad debe ser mayor a 0"])) {
            if (EsNumero([agregar.des], ['La cantidad debe ser un valor númerico'])) {

                const promesa = new Promise((res, rej) => {
                    Post({ url: '/post', table: 'temporal', caso: 'planesAdd', data0: { vid: extraerCliente(agregar.vid), des: agregar.des, usr: agregar.usr, tipo: agregar.tipo }, duplicate: duplicate(true, [extraerCliente(agregar.vid)], ['vid'], ['Este Producto ya ha sido agregado'], [`and usr = ${usuario().id} and tipo = 'PResiduales'`]) }).then((r) => {
                        open(true);
                        setAgregar({ vid: "", des: "", usr: usuario().id, tipo: "PResiduales" })
                        tiempo(250, res, 'Información Guardada')
                    }).catch((f) => {
                        tiempo(250, rej, f)
                    })
                })
                AlertaPromesa('Guardando Información', promesa, 'a')
            } else {
                tiempo(2000, setDisabled, false)
            }
        } else {
            tiempo(2000, setDisabled, false)
        }
    }
    const inicializar = (d) => {
        setEditar(d)
    }

    return (
        <>
            <datalist id="residuales">
                {
                    productos.map((v, i) => {
                        return <option key={i} value={v.id + ' - ' + v.nom} />
                    })
                }
            </datalist>
            <div className="border-2 p-3 mb-3 rounded-lg bg-gray-100">
                <h5>Agregar Descartables</h5>
                <div className="row mb-3">
                    <div className="col-lg-8 col-xl-8 col-12 mb-3">
                        <div className="form-floating">
                            <input className="form-control" type={'text'} name={'vid'} value={agregar.vid} list={'residuales'} onChange={cambiar} />
                            <label>Seleccione Descartable</label>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 col-6">
                        <div className="form-floating">
                            <input className="form-control" type={'text'} name={'des'} value={agregar.des} onChange={cambiar} />
                            <label>Cantidad</label>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xl-2 col-6">
                        <Boton tipo={'primary'} disabled={disabled} titulo={'Agregar Descartable'} onClick={() => guardar()} />
                    </div>
                </div>
                <div className="row mb-3">
                    <h5>Descartables y cantidades incluidas en el Plan</h5>
                    <Tabla
                        encabezado={['No. ', 'Descartable', 'Cantidad', 'Acciones']}
                        nombre={'ttrs'} filas={temporal.map((v, i) => {
                            datos[i] = ({ vid: v.vid + ' - ' + v.nom })
                            return (
                                <tr key={i}>
                                    {CreaarFila([i + 1, v.nom , v.des, <>
                                        {
                                            Autorizado([Permisos().planesEdit]) &&
                                            <ModalEdit modalTitulo={'Editar'} modalSize={'lg'}
                                                inicializarDatos={() => inicializar(datos[i])}
                                                formulario={[
                                                    [CrearInput({ type: 'text', name: 'vid', value: editar.vid, placeholder: 'Producto', list: 'residuales', clase: 'col-lg-6 col-xl-6 col-sm-12 col-12' }),
                                                    CrearInput({ type: 'text', name: 'des', value: editar.des, placeholder: 'Cantidad', clase: 'col-lg-6 col-xl-6 col-sm-12 col-12' })
                                                    ]
                                                ]} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                                                comprobarTextoVacio={[buscarValor(productos, editar.vid) ? "ok" : "", editar.des, editar.des.toString() > 0 ? "ok" : ""]}
                                                mensajeTextoVacio={["Seleccione un Producto de la Lista", "Escriba la Cantidad que incluye el plan", "La Cantidad debe ser mayor a 0"]}
                                                numeroComprobar={[editar.des]} mensajeNumeroComprobar={['La cantidad debe ser un valor múmerico']}
                                                duplicados={duplicate(true, [extraerCliente(editar.vid)], ['vid'], ['Este producto ya ha sido agregado'], [`and usr = ${usuario().id} and tipo = 'PResiduales' and id != ${v.id} `])}
                                                url={'/put'} tabla={'temporal'} caso={'planesEdit'}
                                                mensajeLoadingPromesa={'Actualizando...'} mensajePromesa={'Actualizado'} editar={{ vid: extraerCliente(editar.vid), des: editar.des }} id={`id = ${v.id}`} principal={open}

                                            />
                                        }
                                        {
                                            Autorizado([Permisos().planesDel]) &&
                                            <>
                                                &nbsp; &nbsp;
                                                <ModalDelete
                                                    modalTitulo={'Eliminar Producto Incluido en el Plan'}
                                                    mensajeEliminar={<>¿Seguro de eliminar el producto <strong>{v.familia + ' - ' + v.nom}</strong> de este plan?</>}
                                                    consultarHistorial={datosConsultarHistorial(true)}
                                                    url={'/del'} condicionEliminar={`id = ${v.id}`} tabla={'temporal'} caso={'planesDel'}
                                                    mensajeLoadingPromesa={'Eliminando'} mensajePromesa={'Eliminado'} principal={open}
                                                />
                                            </>
                                        }
                                    </>])}
                                </tr>
                            )
                        })}
                    />
                </div>
            </div>
        </>
    )
};
