import Contenedor from "infortech_modules/Componentes/Contenedor";
import { cambiarFormulario, duplicate, tiempo, datosConsultarHistorial } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado} from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useState, useEffect } from "react";

export default function Ciudades({ iniciar }) {
    const edit = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [agregar, setAgregar] = useState({
        dep:"85", ciudad:"1085", otro:""
    })
    const [editar, setEditar] = useState({
        id: "", dep:"", ciudad:"", otro:"",
    })


    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'ciudades' }).then((r) => {
            setData(r[0]);
            setDepartamentos(r[1]);
            setMunicipios(r[2]);
            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setError, true, setCargar, false);
        })
    }

    const cambiar = (e) => {
        setAgregar({
            ...agregar,
            [e.target.name]:e.target.value
        });
        setAgregar((v) => {
            if(agregar.dep !== v.dep) {
                v.ciudad = municipios.filter(m => m.departamento_id === parseInt(v.dep))[0].id_municipio;
            }
            return v;
        })
    } 

    const inicializar = (d) => {        
        setEditar(d);
    } 

    const form = [
        [CrearInput({type:'select', name:'dep', value:agregar.dep, placeholder:'Departamemnto', options:departamentos.map((v,i) => {return ({value:v.id_departamento, titulo:v.departamento})})})],
        [CrearInput({type:'select', name:'ciudad', value:agregar.ciudad, placeholder:'Ciudad', options:municipios.filter(m => m.departamento_id === parseInt(agregar.dep)).map((v,i) => {return ({value:v.id_municipio, titulo:v.municipio})})})],
        [CrearInput({type:'text', name:'otro', value:agregar.otro, placeholder:'Otros (Vereda, Corregimiento, etc...)'})]
    ],
    formEdit = [
        [CrearInput({type:'select', name:'dep', value:editar.dep, disabled:true, options: departamentos.map((v,i) => {return({value:v.id_departamento, titulo:v.departamento})}) , placeholder:'Departamemnto'})],
        [CrearInput({type:'select', name:'ciudad', value:editar.ciudad, disabled:true, placeholder:'Ciudad', options:municipios.filter(m => m.departamento_id === parseInt(editar.dep)).map((v,i) => {return ({value:v.id_municipio, titulo:v.municipio})})})],
        [CrearInput({type:'text', name:'otro', value:editar.otro, placeholder:'Otros (Vereda, Corregimiento, etc...)'})]
    ]


    return (
        <>
            <Contenedor autorizado={Permisos().ciudades} rutas={['Inicio', 'Configuraciones', 'Ciudades']} titulo={'Ciudades'}
                cargar={cargar} mensajeCarga={'Obteniendo información de las ciudades...'} classNameMensaje={'text-dark text-center p-3 fs-5 fcom'}
                error={error} mensajeError={mensaje} altura={'85vh'} claseNameTextError={'text-danger text-center p-3 fs-4 fcom fw-bold'}
                tablaEncabezado={['Departamento', 'Ciudad', 'Otros (vereda, corregimiento,  etc...)', 'Acciones']} tablaNombre={'tc'} claseTablaEncabezado={'text-center'}
                tablaFilas={data.map((v, i) => {
                    edit[i] = {id:v.id, dep:v.departamento, ciudad:v.municipio, otro:v.otro}
                    return (
                        <tr key={i}>
                            {CreaarFila([v.dep, v.ciudad, v.otro], "scope", [""], "text-center")}
                            <td className="text-center">
                                <ModalEdit
                                tituloBotonPrincipal={'Editar Ciudad'}
                                inicializarDatos={() => inicializar(edit[i])}
                                formulario={formEdit} onChange={(e) => cambiarFormulario(e, editar, setEditar) }
                                tabla={'ciudades'} caso={'ciudadesEdit'} editar={{otro:editar.otro}} id={`id =  ${v.id}`}
                                mensajeLoadingPromesa={'Actualizando...'} mensajePromesa={'Datos Actualizados'}
                                principal={principal} url={'/put'} idLog={v.id} 
                                duplicados={duplicate(true,[editar.otro],['otro'],['Error: Datos ya registrrados'], [`and dep = '${editar.dep}' and ciudad ='${editar.ciudad}' and id != '${v.id}' `])}
                                infoLog={`${v.otro} -> ${editar.otro}`}
                                />
                                &nbsp; &nbsp;
                                <ModalDelete
                                tituloBotonPrincipal={'Elimiar Ciudad'}
                                modalTitulo={v.otro === ''?'Eliminar: '+v.ciudad +' ('+v.dep+') ':'Eliminar: '+v.otro+' - '+v.ciudad+' ('+v.dep+')'}
                                mensajeEliminar={<>¿Está seguro de eliminar la ciudad <strong>{v.otro === ''?v.ciudad +' ('+v.dep+') ':v.otro+' - '+v.ciudad+' ('+v.dep+')'}</strong></>}
                                tituloBotonEnviar={'Si, Eliminar'} mensajeLoadingPromesa={'Eliminando Ciudad...'} mensajePromesa={'Ciudad Eliminada'}
                                url={'/del'} caso={'ciudadesDelete'} tabla={'ciudades'}
                                consultarHistorial={datosConsultarHistorial(false, ['direccion_cliente', 'clientes'],['ciu', 'ciudad'],['Esta ciudad esta registrada en direcciones de despacho de los clientes', 'Esta ciudad tiene registro en los datos de los clientes'])}
                                valorEliminar={v.id} condicionEliminar={`id = ${v.id}`} principal={principal} infoLog={JSON.stringify({ dep:v.departamento, ciudad:v.municipio, otro:v.otro})}


                                />

                            </td>
                        </tr>
                    )
                })}
                botonAdd={Autorizado([Permisos().ciudadesAdd]) &&
                    <ModalAdd
                    tituloBotonPrincipal={'Agregar Ciudad'} tipoBotonPrincipal={'principal'}
                    modalTitulo={'Agregar Ciudad'}
                    tipoBotonEnviar={'principal'} 
                    formulario={form} onChange={cambiar}
                    duplicados={duplicate(true,[agregar.dep],['dep'],['Estos datos ya estan registrados'],[`and ciudad = '${agregar.ciudad}' and otro = '${agregar.otro}'`])}
                    tabla={'ciudades'} agregar={agregar} comprobarTextoVacio={['ok']} mensajeTextoVacio={['ok']} caso={'ciudadesAdd'}
                    mensajeLoadingPromesa={'Agregando Ciudad...'} mensajePromesa={'Ciudad Agregada Correctamnte'}
                    url={'/post'} principal={principal} infoLog={JSON.stringify(agregar)}


                    />
                }
            />
        </>
    )
};
