import axios from "axios";
import { Lectura } from "../Constantes/Lectura";
import { api } from "infortech_modules/Constantes/Constantes";
import { usuario } from "infortech_modules/Constantes/Usuarios";
import { header } from "infortech_modules/Constantes/Constantes";


export default function Post({ table, url, duplicate, caso, reload, data0, data1, data2, data3, data4, data5, data6, value, key, iniciar, infoLog }) {
    reload = reload === undefined ? false:reload;
    duplicate = duplicate === undefined ? {verificar:false}:duplicate;

    return new Promise((res, rej) => {
        axios.post(api + url,
            { table: table, permisos: usuario().per, caso: caso, duplicate: duplicate, data0: data0, data1: data1, data2: data2, data3: data3, data4: data4, data5: data5, data6: data6, value: value, key: key, usuario:usuario().id, infoLog:infoLog },
            { headers: header(), timeout: 5500 }
        ).then((r) => {            
            if (r.data.res) {
                if (reload) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                }
                res(r.data.msje)

            } else {
                if(typeof r.data.msje === 'object'){
                    rej(Lectura(""+r.data.msje, iniciar));
                }else{
                    rej(Lectura(r.data.msje, iniciar));
                }
                
            }

        }).catch((f) => {
            console.log(f);
            if(typeof f === 'object'){
                rej(Lectura(""+f, iniciar))

            }else{
                rej(Lectura(f, iniciar))

            }
        })
    })
}

