import Contenedor from "infortech_modules/Componentes/Contenedor";
import Tabla from "infortech_modules/Componentes/Tabla";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import { NumerosCeros } from "infortech_modules/Constantes/EsNumero";
import { FormatearFecha } from "infortech_modules/Constantes/Fecha";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "infortech_modules/Recursos/Estilos/mantenimiento.scss";
import RegistrarCalibracion from "./RegistrarCalibracion";

export default function Calibracion({ iniciar, calibrar, recargar }) {
    const { id } = useParams();
    const no = id === undefined ? calibrar : id;
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [infoEquipo, setInfoEquipo] = useState({ nom: "", mar: "", modl: "", id: "", ser: "", periodo: "", tiempo: "", uman: "", pcal: "", est: "", res: "" });
    const [det, setDet] = useState([]);
    const [asunto, setAsunto] = useState([]);
    const [estados, setEstados] = useState([]);
    const [transferir, setTransferir] = useState([]);
    const [asignar, setAsigar] = useState([]);
    const [res, setRes] = useState([]);


    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])

    const principal = (est, d) => {
        est && setCargar(true)


        Get({ url: '/get/general', key: 'DetCalibracion', id: no }).then((r) => {
            if (r[0].length === 0) {
                setError(true);
                setMensaje('Proceso de Calibración No Existe');
            } else {
                setInfoEquipo(r[0][0]);               
                setDet(r[2]);
                setAsunto(r[3]);
                setEstados(r[4]);
                setTransferir(r[5]);
                setAsigar(r[6]);
                setRes(r[7]);
            }

            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(500, setCargar, false)
        })

    }

    function DeterminarEstado(params) {
        let actualizar;
        switch (params) {
            case 1:
                actualizar = "Creado";
                break;
            case 2:
                actualizar = "Asignado";
                break;
            case 3:
                actualizar = "En Revisión";
                break;
            case 4:
                actualizar = "En Espera";
                break;
            case 5:
                actualizar = "Finalizado";
                break;
            case 6:
                actualizar = "En Transferencia";
                break;
            case 7:
                actualizar = "Aceptada";
                break;

            default:
                break;
        }
        return actualizar;
    }

    


    return (
        <>
            <Contenedor autorizado={Permisos().detMantenimientoEquipos} rutas={['Inicio', 'Equipos', 'Calibración', id]}
                altura={'85vh'} cargar={cargar} mensajeCarga={'Cargando Detalles del Proceso de Calibración...'}
                error={error} mensajeError={mensaje}
                titulo={"Proceso de Calibración No. " + NumerosCeros(no, 6)}
                header={<>
                    <Tabla
                        encabezado={['Equipo', 'Marca', 'Modelo', 'Serial', 'Responsable']}
                        filas={<tr>
                            <td>{infoEquipo.nom}</td>
                            <td>{infoEquipo.mar}</td>
                            <td>{infoEquipo.modl}</td>
                            <td>{infoEquipo.ser}</td>
                            <td>{res.length > 0 ? res[0].usr : infoEquipo.res}</td>
                        </tr>}
                    />
                    <Tabla
                        encabezado={['Periodo de Calibración', 'Ultima Fecha de Calibración', 'Fecha de Próximo Mantenimeinto', 'Estado']}
                        filas={<tr>
                            <td>{infoEquipo.periodo}</td>
                            <td>{FormatearFecha(new Date(infoEquipo.uman), 2)}</td>
                            <td>{FormatearFecha(new Date(infoEquipo.pcal), 2)}</td>
                            <td>{DeterminarEstado(infoEquipo.est)}
                            </td>
                        </tr>}
                    />
                  

                    {
                        infoEquipo.est !== 5 &&
                        <RegistrarCalibracion tipo={'detalle'} id={no} idEquipo={infoEquipo.id} asunto={asunto} estados={estados} asignar={asignar} transferir={transferir} principal={principal} recargar={recargar} />

                    }


                    <div className="row">
                        <div className="m-titulo fcom">Actividades</div>
                    </div>
                    {
                        det.map((v, i) => {
                            function Dibujar() {
                                return <div className="row m-detalle" key={i}>
                                    <div className="col-xl-4 col-lg-4 col-sm-6 col-6 m-asunto">
                                        <div className="res">{v.usrActividad}</div>
                                        <div>{FormatearFecha(new Date(v.fecha), 3)}</div>
                                        <div>{v.privado === 1 && "Nota Privada"}</div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-sm-6 col-6 m-contenido">
                                        {
                                            v.tipo === 2 ? <>
                                                <div className="mb-1">Asigno el mantenimiento {`=> `} {v.actividad}</div>
                                                <div className="border border-dark p-1">{v.obs}</div>
                                            </> : v.tipo === 3 ? <>
                                                <div className="mb-1">Cambio Estado {`=> `}  {v.actividad}</div>
                                                <div className="border border-dark p-1">{v.obs}</div>
                                            </> : v.tipo === 4 ? <>
                                                <div className="mb-1">Transfirio el mantenimiento {`=> `} {v.actividad}</div>
                                                <div className="border border-dark p-1">{v.obs}</div>
                                            </> : <div>
                                                <div>{v.actividad}</div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            return (
                                v.privado === 0 ? Dibujar() : v.usr === usuario().id && Dibujar()
                            )
                        })
                    }


                </>}
            />
        </>
    )
}