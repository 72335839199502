export const DateTime = () => {
  const date = new Date();
  let options = { year: "numeric" };
  const year = date.toLocaleString("es-CO", options);
  options = { month: "2-digit" };
  const mes = date.toLocaleString("es-CO", options);
  options = { day: "2-digit" };
  const day = date.toLocaleString("es-CO", options);
  options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const hora = date.toLocaleString("es-ES", options);
  return year + "-" + mes + "-" + day + " " + hora;
}

export const compararFechas = (fecha) => {
  const actual = new Date();
  fecha = new Date(fecha + "T00:00");
  console.log(fecha, actual);

  if (fecha > actual) {
    return false;
  } else if (fecha < actual) {
    return true;
  }
}

export const compararFechasMayor = (fecha) => {
  const actual = new Date();
  fecha = new Date(fecha);


  if (fecha > actual) {
    return false;
  } else if (fecha < actual) {
    return true;
  }
}

export const compararDosFechasMayor = (fecha1, fecha2, igual) => {
  fecha1 = new Date(fecha1);
  fecha2 = new Date(fecha2);
  igual = igual === undefined ? false : igual;
  if (igual) {
    if (fecha1 >= fecha2) {
      return true;
    } else {
      return false;
    }
  } else {
    if (fecha1 > fecha2) {
      return true;
    } else {
      return false;
    }
  }

}

export const compararDosFechas = (fecha, fecha1, m, c) => {
  m = m === undefined ? 1 : m;
  c = c === undefined ? 1 : c;

  if (parseInt(m) !== 1 || parseInt(c) !== 1) {
    const actual = new Date();
    fecha = new Date(fecha + "T00:00");
    fecha1 = new Date(fecha1 + "T00:00");

    if (fecha > actual || fecha1 > actual) {
      return false;
    } else if (fecha < actual || fecha1 < actual) {
      return true;
    }
  } else {
    return false;
  }

}

export function fecha(fec) {
  let mes;
  //let mes1;
  const val = fec.split("-");
  const val1 = val[2].split("T");


  switch (val[1]) {
    case '01':
      mes = "Enero";
      //mes1 = "Ene";
      break;
    case '02':
      mes = "Febrero";
      //mes1 = "Feb";
      break;
    case '03':
      mes = "Marzo";
      //mes1 = "Mar";
      break;
    case '04':
      mes = "Abril";
      //mes1 = "Abr";
      break;
    case '05':
      mes = "Mayo";
      //mes1 = "May";
      break;
    case '06':
      mes = "Junio";
      //mes1 = "Jun";
      break;

    case '07':
      mes = "Julio";
      //mes1 = "Jul";
      break;
    case '08':
      mes = "Agosto";
      //mes1 = "Ago";
      break;
    case '09':
      mes = "Septiembre";
      //mes1 = "Sep";
      break;
    case '10':
      mes = "Octubre";
      //mes1 = "Oct";
      break;
    case '11':
      mes = "Noviembre";
      //mes1 = "Nov";
      break;
    case '12':
      mes = "Diciembre";
      //mes1 = "Dic";
      break;

    default:
      break;
  }
  return mes + " " + val1[0] + " de " + val[0];
}


const retornarFecha = (d, m, a, h, mn, caso, sc, ms) => {
  let retornar;
  let mes;
  let mes1;
  let mm;
  let mes2;
  let dd;
  if (mn < 10) {
    mm = "0" + mn;
  } else {
    mm = mn;
  }

  if (m < 10) {
    mes2 = "0" + m;
  } else {
    mes2 = m;
  }

  if (d < 10) {
    dd = "0" + d
  } else {
    dd = d
  }

  switch (m) {
    case 1:
      mes = "Enero";
      mes1 = "Ene";
      break;
    case 2:
      mes = "Febrero";
      mes1 = "Feb";
      break;
    case 3:
      mes = "Marzo";
      mes1 = "Mar";
      break;
    case 4:
      mes = "Abril";
      mes1 = "Abr";
      break;
    case 5:
      mes = "Mayo";
      mes1 = "May";
      break;
    case 6:
      mes = "Junio";
      mes1 = "Jun";
      break;

    case 7:
      mes = "Julio";
      mes1 = "Jul";
      break;
    case 8:
      mes = "Agosto";
      mes1 = "Ago";
      break;
    case 9:
      mes = "Septiembre";
      mes1 = "Sep";
      break;
    case 10:
      mes = "Octubre";
      mes1 = "Oct";
      break;
    case 11:
      mes = "Noviembre";
      mes1 = "Nov";
      break;
    case 12:
      mes = "Diciembre";
      mes1 = "Dic";
      break;

    default:
      break;
  }

  switch (caso) {
    case 1:
      retornar = d + " de " + mes1;
      break;
    case 2:
      retornar = d + " de " + mes + " " + a;
      break;
    case 3:
      retornar = d + " de " + mes1 + " del " + a + " a las " + h + ":" + mm;
      break;
    case 4:
      retornar = a + "-" + mes2 + "-" + dd + " " + h + ":" + mm + ":" + sc;
      break;
    case 5:
      retornar = a + mes2 + d + h + mm + sc + ms
      break;

    default:
      break;
  }

  return retornar;
}

export function FormatearFecha(fecha, caso) {
  const con = fecha.getTime();

  const fec = new Date(con);
  const d = fec.getUTCDate();
  const m = fec.getMonth() + 1;
  const a = fec.getFullYear();
  const hh = fec.getHours();
  const mm = fec.getMinutes();
  const sc = fec.getSeconds();
  const ms = fec.getMilliseconds();

  const val = retornarFecha(d, m, a, hh, mm, caso, sc < 10 ? `${'0' + sc}` : sc === 0 ? '00' : sc > 9 && sc, ms);
  return val;
}

export const pasarFechaFormulario = (fecha) => {
  const date = new Date(fecha);
  let options = { year: "numeric" };
  const year = date.toLocaleString("es-CO", options);
  options = { month: "2-digit" };
  const mes = date.toLocaleString("es-CO", options);
  options = { day: "2-digit" };
  const day = date.toLocaleString("es-CO", options);
  options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  return year + "-" + mes + "-" + day;
}

export const esFechaValida = (fecha) => {
  if(fecha !== ""){
    fecha = new Date(fecha);
    const v = new Date.parse(fecha);
    return isNaN(v);
  }else{
    return false;
  }
  
}

export const esSoloFechaValida = (fecha) => {
  if (fecha !== "") {  
    console.log(new Date.parse(fecha));  
  } else {
    return false;
  }
}

export const TransfomarFecha = (fecha) => {
  const date = new Date(fecha);
  let options = { year: "numeric" };
  const year = date.toLocaleString("es-CO", options);
  options = { month: "2-digit" };
  const mes = date.toLocaleString("es-CO", options);
  options = { day: "2-digit" };
  const day = date.toLocaleString("es-CO", options);
  options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  //const hora = date.toLocaleString("es-ES", options);
  return year + "-" + mes + "-" + day + " ";
}



export default DateTime;