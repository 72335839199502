import { useState, useEffect } from "react";
import {  tiempo } from "infortech_modules/Constantes/Constantes";
import Get from "infortech_modules/Crud/Get";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos from "infortech_modules/Constantes/Usuarios";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Modales from "infortech_modules/Componentes/Modales";
import Boton from "infortech_modules/Componentes/Boton";
import { IoEyeSharp } from "react-icons/io5";
import Calibracion from "./Calibracion";
import { FormatearFecha } from "infortech_modules/Constantes/Fecha";


export default function ListadoCalibracion({ iniciar }) {
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [man, setMan] = useState(0);

    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get', key: '9', iniciar: iniciar }).then((r) => {
            setData(r);
            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setError, true, setCargar, false);
        })
    }


    function DeterminarEstado(params) {
        let actualizar;
        switch (params) {
            case 1:
                actualizar = "Creado";
                break;
            case 2:
                actualizar = "Asignado";
                break;
            case 3:
                actualizar = "En Revisión";
                break;
            case 4:
                actualizar = "En Espera";
                break;
            case 5:
                actualizar = "Finalizado";
                break;
            case 6:
                actualizar = "En Transferencia";
                break;
            case 7:
                actualizar = "Aceptada";
                break;

            default:
                break;
        }
        return actualizar;
    }

    const open = (value) => {
        setMan(value);
        setModal(true);
    }
    const closed = () => {
        setMan(0);
        setModal(false)
    }

    return (
        <>
            <Contenedor rutas={['Inicio', 'Equipos', 'Calibracion', 'Listado']} autorizado={Permisos().actividades}
                cargar={cargar} mensajeCarga={'Por favor espere...'} classNameMensaje={'text-dark fs-4 fw-bold fcom'} altura={'85vh'}
                error={error} mensajeError={mensaje} claseNameTextError={'text-danger fs-4 fw-bold fcom'}
                titulo={'Procesos de Calibración'}

                tablaEncabezado={['No. ', 'Serial', 'Fecha','Equipo (Marca - Modelo)', 'Estado', 'Ver']} claseTablaEncabezado={'text-center'} tablaNombre={'tac'}
                tablaFilas={data.map((v, i) => {


                    return (
                        <tr key={i}>
                            {
                                CreaarFila([v.id, v.ser, FormatearFecha(new Date(v.fecha),2),v.nom + ' (' + v.mar + ' - ' + v.modl + ')', DeterminarEstado(v.est)], "scope", [""], "text-center")
                            }
                            <td className="text-center">
                                <Boton tipo={'icono'} Icono={IoEyeSharp} onClick={() => open(v.id)} titulo={'Ver Detalles'}
                                    claseIcon={'fs-4 pointer text-primary'} posicionTexto={'left'} />
                            </td>
                        </tr>
                    )
                })}

            />
            <Modales
                show={modal}
                onHide={closed}
                body={<Calibracion calibrar={man} recargar={principal} />}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />}
            />
        </>
    )
};
