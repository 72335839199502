import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Modales from "infortech_modules/Componentes/Modales";
import { buscarTipoCliente, buscarValor, calcularEdad, cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useEffect, useState } from "react";
import Direcciones from "2Access/0Clientes/Direcciones";
import Formularios from "infortech_modules/Componentes/Formularios";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Put from "infortech_modules/Crud/Put";
import DateTime, { pasarFechaFormulario } from "infortech_modules/Constantes/Fecha";
import OrdenVignete from "./Ordenes/OrdenVigente";
import OrdenNueva from "./Ordenes/OrdenNueva";


export default function Pacientes({ iniciar }) {
    const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({
        tp: "2", td: "2", doc: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "1", sexo: "Masculino", fnac: "0000-00-00", mail: "", zona: "1", actividad: "1", est: 1,
    })
    const [editar, setEditar] = useState({
        tp: "", doc: "", td: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "", mail: "", zona: "", fnac: "", sexo: ""
    })
    const [td, setTd] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [zona, setZona] = useState([]);
    const [rutas, setRutas] = useState([]);
    const [disabledInput, setDisabledInput] = useState(true);
    const [btnDis, setBtnDis] = useState(false);
    const [btnSnp, setBtnSnp] = useState(false);
    const [cambio, setCambio] = useState(true);
    const [modalConfirmacion, setModalConfirmacion] = useState(false);
    const [mensajeModal, setMensajeModal] = useState('');
    const [btnModal, setBotonModal] = useState(false);
    const [update, setUpdate] = useState({ fnac: "0000-00-00", sexo: "Masculino" })
    const [cliente, setCliente] = useState({});
    const [epsPaciente, setEpsPaciente] = useState({ idEps: "", idPac: "", fact: DateTime(), est: 1 });
    const [eps, setEps] = useState([]);
    const [updateEps, setUpdateEps] = useState({ idEps: "", idPac: "", fact: DateTime(), est: 1 });


    function open(dato) {

        setMensajeModal(dato);
        setTimeout(() => {
            setModalConfirmacion(true);
        }, 250);
    }

    const closed = () => {
        setModalConfirmacion(false)
        setMensajeModal('');
    };


    useEffect(() => {
        principal()
        // eslint-disable-next-line 
    }, [])

    const principal = (est) => {
        est && <>
            {setCargar(true)};
            {setBtnDis(false)};
            {setAgregar({
                tp: "1", td: "1", doc: "", nom: "", ape: "", tel: "", cel: "", dir: "", ciudad: "1", sexo: "1", fnac: "0000-00-00", mail: "", zona: "1", actividad: "1", est: 1,
            })}
            {setDisabledInput(true)}

        </>


        Get({ url: '/get/general', key: 'clientes', data0: '1', data2: 'Paciente', iniciar: iniciar }).then((r) => {
            setData(r[0])
            setTd(r[1]);
            setMunicipios(r[2]);
            setZona(r[4]);
            setRutas(r[5])
            setEps(r[6]);
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setCargar, false, setError, true)
        })
    }

    const buscarCliente = () => {
        setBtnDis(true);
        setBtnSnp(true);
        if (EsNumero([agregar.doc], ['El documento del cliente debe ser un valor númerico'])) {
            const promesa = new Promise((res, rej) => {
                Get({ url: '/get/general', key: 'BuscarCliente', data1: agregar.doc, data0: 1 }).then((r) => {
                    if (r.length === 0) {
                        tiempo(500, res, 'Número de Documento disponible para registro, puede continuar', setDisabledInput, false, setBtnSnp, false, setCambio, false, setBotonModal, true)
                    } else {
                        setCliente(r[0]);
                        setUpdateEps({
                            ...updateEps,
                            idPac: r[0].id
                        })
                        if (buscarTipoCliente(r, 1)) {
                            tiempo(500, rej, 'Error: Ya existe un paciente registrado con este número de documento', setBtnDis, false, setBtnSnp, false);
                        } else if (buscarTipoCliente(r, 2) && buscarTipoCliente(r, 0)) {
                            tiempo(500, rej, 'Este documento registra como cliente en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito tanto en el área comercial como medicinal.</div><div className="fw-bold fst-italic">¿Desea registrarlo como Paciente?</div></div>)
                        } else if (buscarTipoCliente(r, 2)) {
                            tiempo(500, rej, 'Este documento registra como cliente en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito en el àrea medicinal.</div><div className="fw-bold fst-italic">¿Desea registrarlo como paciente?</div></div>)
                        } else if (buscarTipoCliente(r, 0)) {
                            tiempo(500, rej, 'Este documento registra como cliente en otra área', setBtnSnp, false, setBtnDis, false, open, <div className="text-center p-3"><div className="mb-3">El número de documento {agregar.doc} registra <strong>{r[0].nom} {r[0].ape}</strong> inscrito en el área comercial.</div><div className="fw-bold fst-italic">¿Desea registrarlo como paciente?</div></div>)
                        }
                    }
                }).catch((f) => {
                    tiempo(500, setBtnDis, false, rej, f, setBtnDis, false)
                })
            })
            AlertaPromesa('Consultando número de documento...', promesa, 'a');
        } else {
            tiempo(1200, setBtnDis, false, setBtnSnp, false);
        }
    }

    const grabar = () => {
        if (EsVacio([update.fnac === "0000-00-00" ? "" : "ok", isNaN(Date.parse(update.fnac)) ? "" : "ok", buscarValor(eps, updateEps.idEps) ? "ok" : ""], ['Seleccione la fecha de nacimiento del Paciente', 'Digite una fecha valida', 'Seleccione una EPS de la Lista'])) {
            const promesa = new Promise((res, rej) => {
                Put({ url: '/put/general', key: 'PacientesUpdate', data0: update, caso: 'pacientesAdd', data1: cliente.id, data2: cliente.doc, infoLog: JSON.stringify({ idCliente: agregar.doc, ipa: 1, est: 1 }), duplicate: duplicate(false), data3: updateEps })
                    .then((r) => {
                        tiempo(500, res, 'Este cliente fue agregado como paciente', principal, true, setModalConfirmacion, false);
                    })
                    .catch((f) => {
                        tiempo(500, rej, f)
                    })

            })
            AlertaPromesa('Exportando cliente como Paciente...', promesa, 'e');
        }

    }

    const formEdit = [
        [
            CrearInput({ type: 'select', name: 'td', value: editar.td, disabled: true, options: td.map((v, i) => { return { value: v.id, titulo: v.nom } }), placeholder: 'Tipo de Documento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'text', name: 'doc', value: editar.doc, disabled: true, placeholder: 'No. de Documento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        editar.tp === 1 ?
            [CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: "Razón Social", clase: 'col-12' })] :
            [
                CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: "Nombres", clase: 'col-6' }),
                CrearInput({ type: 'text', name: 'ape', value: editar.ape, placeholder: "Apellidos", clase: 'col-6' })
            ],
        [CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: 'Teléfono' }), CrearInput({ type: 'text', name: 'cel', value: editar.cel, placeholder: 'Celular' })],
        [CrearInput({ type: 'text', name: 'mail', value: editar.mail, placeholder: 'Correo Eletrónico' })],
        [
            CrearInput({ type: 'date', name: 'fnac', value: editar.fnac, placeholder: 'Fecha de Nacimiento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'text', name: 'edad', value: calcularEdad(editar.fnac), disabled: true, placeholder: 'Edad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'select', name: 'sexo', value: editar.sexo, options: [{ value: 'Masculino', titulo: 'Masculino' }, { value: 'Femenino', titulo: 'Femenino' }], placeholder: 'Sexo', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'select', name: 'zona', value: editar.zona, options: zona.map((v, i) => { return { value: v.id, titulo: v.zona } }), placeholder: 'Zona', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({
                type: 'select', name: 'ciudad', value: editar.ciudad, options: municipios.map((v, i) => {
                    return {
                        value: v.id, titulo: v.otro === "" ? v.ciudad + " (" + v.dep + ")" : v.otro + " - " + v.ciudad + " (" + v.dep + ")"
                    }
                }), placeholder: 'Ciudad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12'
            }),
        ]

    ]

    const inicializarDatos = (d) => {
        setEditar(d);
    }


    const row = data.map((v, i) => {
        datos[i] = { tp: v.tp, doc: v.doc, td: v.td, nom: v.nom, ape: v.ape, tel: v.tel, cel: v.cel, dir: v.dir, ciudad: v.ciudad, mail: v.mail, zona: v.idZona, sexo: v.sexo, fnac: pasarFechaFormulario(v.fnac) }
        return (
            <tr key={i}>
                {
                    CreaarFila([v.nom + ' ' + v.ape, v.sigla, v.doc, v.tel, v.municipio], "", [""], "text-left")
                }
                <td className="text-center">
                    {
                        Autorizado([Permisos().clientesComercialEdit]) &&
                        <ModalEdit
                            modalTitulo={'Editar Paciente: ' + v.nom + ' ' + v.ape}
                            formulario={formEdit} inicializarDatos={() => inicializarDatos(datos[i])} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                            comprobarTextoVacio={[editar.nom, editar.tp === 1 ? "ok" : editar.ape, editar.tel, editar.mail, editar.dir, isNaN(Date.parse(editar.fnac)) ? "" : "ok"]}
                            mensajeTextoVacio={[editar.tp === 1 ? "Digite la Razón Social" : "Digite los Nombres", "Digite los Apellidos", "Digite un número de teléfono", "Digite un Correo Electrónico", "Escriba la dirección del Cliente", "La fecha de nacimiento no es válida"]}
                            numeroComprobar={[editar.tel.toString(), editar.cel === "" ? "1" : editar.cel.toString()]}
                            mensajeNumeroComprobar={['El teléfono debe ser un valor múmerico', 'El celular debe ser un valor númerico']}
                            mensajeLoadingPromesa={'Editando...'} mensajePromesa={'Registro Editado...'} infoLog={`${JSON.stringify(datos[i])} -> ${JSON.stringify(editar)}`}
                            duplicados={duplicate(false)}
                            url={'/put'} caso={'clientesMedicinalEdit'} id={`id = ${v.id}`} idLog={v.id} tabla={'clientes'} editar={editar} principal={principal}

                        />

                    }
                    &nbsp; &nbsp;
                    {
                        Autorizado([Permisos().clientesComercialDel]) &&
                        <ModalDelete
                            modalTitulo={'Eliminar Paciente: ' + v.nom + ' ' + v.ape}
                            mensajeEliminar={<>¿Desea eliminar {v.tp === 1 ? 'a la empresa' : 'Sr (a)'} <strong>{v.nom + ' ' + v.ape}</strong> identificado con <strong>{v.sigla + ' ' + v.doc}</strong>?</>}
                            mensajeLoadingPromesa={'Eliminando Paciente...'} mensajePromesa={'Paciente Eliminado'}
                            consultarHistorial={datosConsultarHistorial(true)}
                            url={'/del/general'} key1={'clientesDel'} data0={v.doc} data1={1} tabla={'clientes'} condicionEliminar={`id = ${v.id}`} valorEliminar={v.id}
                            principal={principal} caso={'pacientesDel'}

                        />

                    }
                    &nbsp; &nbsp;
                    {
                        Autorizado([Permisos().clientesMedicinalDir]) &&
                        <Direcciones
                            id={v.id}
                            cliente={v.nom + ' ' + v.ape}
                            ruta={rutas}
                        />
                    }
                    &nbsp; &nbsp;
                    {
                        <OrdenVignete
                        estado={v.ordenv !== ""}
                        id={v.id}
                        titulo={v.nom +" "+v.ape}
                        
                        />
                    }
                    {
                        v.ordenv === "" &&
                        <>
                        &nbsp; &nbsp;
                        <OrdenNueva
                        paciente={v.nom + " "+v.ape}
                        id={v.id} 
                        principal={principal}
                        />
                        </>
                    }

                </td>
            </tr>
        )
    })


    const formAdd = [
        [CrearInput({ type: 'select', name: 'tp', value: agregar.tp, disabled: true, options: [{ value: '1', titulo: 'Persona Jurídica' }, { value: '2', titulo: 'Persona Natural' }], placeholder: 'Tipo de Persona', clase: 'col-6' }),
        CrearInput({ type: 'select', name: 'td', value: agregar.td, disabled: true, options: td.map((v, i) => { return { value: v.id, titulo: v.nom } }), placeholder: 'Tipo de Documento', clase: 'col-6' }),
        ],

        agregar.tp === "1" ?
            [CrearInput({ type: 'text', name: 'nom', value: agregar.nom, disabled: disabledInput, placeholder: "Razón Social", clase: 'col-12' })] :
            [
                CrearInput({ type: 'text', name: 'nom', value: agregar.nom, disabled: disabledInput, placeholder: "Nombres", clase: 'col-6' }),
                CrearInput({ type: 'text', name: 'ape', value: agregar.ape, disabled: disabledInput, placeholder: "Apellidos", clase: 'col-6' })
            ],
        [CrearInput({ type: 'text', name: 'tel', value: agregar.tel, disabled: disabledInput, placeholder: 'Teléfono' }), CrearInput({ type: 'text', name: 'cel', value: agregar.cel, disabled: disabledInput, placeholder: 'Celular' })],
        [CrearInput({ type: 'text', name: 'mail', value: agregar.mail, disabled: disabledInput, placeholder: 'Correo Eletrónico' })],
        [
            CrearInput({ type: 'date', name: 'fnac', value: agregar.fnac, disabled: disabledInput, placeholder: 'Fecha de Nacimiento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'text', name: 'edad', value: calcularEdad(agregar.fnac), disabled: true, placeholder: 'Edad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'select', name: 'sexo', value: agregar.sexo, disabled: disabledInput, options: [{ value: 'Masculino', titulo: 'Masculino' }, { value: 'Femenino', titulo: 'Femenino' }], placeholder: 'Sexo', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({ type: 'select', name: 'zona', value: agregar.zona, disabled: disabledInput, options: zona.map((v, i) => { return { value: v.id, titulo: v.zona } }), placeholder: 'Zona', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
        ],
        [
            CrearInput({ type: 'text', name: 'dir', value: agregar.dir, disabled: disabledInput, placeholder: 'Dirección', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12' }),
            CrearInput({
                type: 'select', name: 'ciudad', value: agregar.ciudad, disabled: disabledInput, options: municipios.map((v, i) => {
                    return {
                        value: v.id, titulo: v.otro === "" ? v.ciudad + " (" + v.dep + ")" : v.otro + " - " + v.ciudad + " (" + v.dep + ")"
                    }
                }), placeholder: 'Ciudad', clase: 'col-xl-6 col-lg-6 col-sm-6 col-12'
            }),
        ]
    ]

    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar);
    }

    const cambiarDoc = () => {
        setCambio(true);
        setDisabledInput(true);
        setBtnDis(false);
        setBotonModal(false);
    }


    return (
        <>
            <datalist id="eps">
                {
                    eps.map((v, i) => {
                        return <option key={i} value={v.id + ' - ' + v.eps} />
                    })
                }
            </datalist>
            <Contenedor
                titulo={'Pacientes'}
                rutas={['Clientes', 'Medicinal']} autorizado={Permisos().clientesComercial}
                cargar={cargar} mensajeCarga={'Obteniendo información de clientes...'} altura='85vh' classNameMensaje={'text-dark fs-5 fw-bold fcom'} anchoImagen={100}
                error={error} mensajeError={mensaje} claseNameTextError={'fs-5 fcom text-danger fw-bold'}
                tablaFilas={row} tablaEncabezado={['Cliente', 'TD', 'No. Documento', 'Teléfono', 'Ciudad', 'Acción']} tablaNombre={'tu'}
                botonAdd={
                    Autorizado([Permisos().clientesComercialAdd]) &&
                    <ModalAdd
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nuevo Paciente'}
                        formulario={formAdd} onChange={cambiar}
                        modalTitulo={'Nuevo Paciente'}
                        tipoBotonEnviar={'principal'}
                        tituloBotonEnviar={'Guardar'}
                        disabledBotonEnviar={btnModal}
                        contenidoSuperiorModal={
                            <>
                                <div className="row mb-3">
                                    <div className="col-xl-6 col-lg-6 col-sm-8 col-9">
                                        <div className="form-floating">
                                            <input type={'text'} name='doc' value={agregar.doc} placeholder={'No de Documento'} className='form-control' onChange={cambiar} disabled={!disabledInput} />
                                            <label>No de Documento</label>
                                        </div>

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-4 col-3">

                                        {
                                            cambio ?
                                                <Boton tipo={'primary'} titulo={'Buscar'} disabled={btnDis} SpinnerBoton={btnSnp} onClick={() => buscarCliente()} /> :
                                                <Boton tipo={'primary'} titulo={'Cambiar'} SpinnerBoton={btnSnp} onClick={() => cambiarDoc()} />
                                        }

                                    </div>
                                </div>

                            </>
                        }
                        contenidoInferiorModal={<>
                            <Formularios datos={[
                                [CrearInput({ type: 'text', name: 'idEps', value: epsPaciente.idEps, list: 'eps', placeholder: 'Seleccione EPS', disabled: disabledInput })]
                            ]} onChange={(e) => cambiarFormulario(e, epsPaciente, setEpsPaciente)} />
                        </>}

                        tabla={'clientes'} caso={'ClientesMedicinalAdd'} key1={"ClientesComercialAdd"}
                        duplicados={duplicate(true, [agregar.doc], ['doc'], ['Error: Documento ya esta registrado'])} datos1={'1'} datos2={agregar.doc}
                        comprobarTextoVacio={[agregar.nom, agregar.tp === '1' ? "ok" : agregar.ape, agregar.tel, agregar.mail, agregar.dir, isNaN(Date.parse(agregar.fnac)) ? "" : "ok", agregar.fnac === "0000-00-00" ? "" : "ok"]}
                        mensajeTextoVacio={[agregar.tp === "1" ? "Digite la Razón Social" : "Digite los Nombres", "Digite los Apellidos", "Digite un número de teléfono", "Digite un Correo Electrónico", "Escriba la dirección del Cliente", "Digite una Fecha Válida", "Seleccione la fecha de nacimiento"]}
                        numeroComprobar={[agregar.tel, agregar.cel === "" ? "1" : agregar.cel]}
                        mensajeNumeroComprobar={['El teléfono debe ser un valor múmerico', 'El celular debe ser un valor númerico']}
                        mensajeLoadingPromesa={'Creando Cliente...'} mensajePromesa={'Registro Correcto'} infoLog={JSON.stringify(agregar)}
                        url={'/post/general'} agregar={agregar} principal={principal}
                    />
                }



            />
            <Modales
                titulo={'Confirmar'}
                show={modalConfirmacion}
                onHide={closed}
                size={'lg'}
                body={
                    <>
                        {mensajeModal}
                        <Formularios datos={[
                            [CrearInput({ type: 'date', name: 'fnac', value: update.fnac, placeholder: 'Fecha de Nacimiento', clase: 'col-xl-6 col-lg-6 col-sm-6 col-4' }),
                            CrearInput({ type: 'text', name: 'edad', value: calcularEdad(update.fnac), disabled: true, placeholder: 'Edad', clase: 'col-xl-3 col-lg-3 col-sm-3 col-4' }),
                            CrearInput({ type: 'select', name: 'sexo', value: update.sexo, options: [{ value: 'Masculino', titulo: 'Masculino' }, { value: 'Femenino', titulo: 'Femenino' }], placeholder: 'Sexo', clase: 'col-xl-3 col-lg-3 col-sm-3 col-4' }),
                            ],
                        ]} onChange={(e) => cambiarFormulario(e, update, setUpdate)} />
                        <Formularios datos={[
                            [CrearInput({ type: 'text', name: 'idEps', value: updateEps.idEps, list: 'eps', placeholder: 'Seleccione EPS' })]
                        ]} onChange={(e) => cambiarFormulario(e, updateEps, setUpdateEps)} />
                    </>
                }
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cancelar'} onClick={() => closed()} />
                    <Boton tipo={'principal'} titulo={'Si, Grabar'} onClick={() => grabar()} />
                </>}
            />
        </>
    )
}