import axios from "axios";
import { Lectura } from "infortech_modules/Constantes/Lectura";
import { api, header } from "infortech_modules/Constantes/Constantes";
import { usuario } from "infortech_modules/Constantes/Usuarios";

export default function Delete({ table, url, where, caso, reload, value, history, key, iniciar, infoLog, data0, data1 }) {
    history = history === undefined ? {verificar:true}:history;

    return new Promise((res, rej) => {
        axios.delete(api + url,
            {
                headers: header(),
                data: {
                    key: key,
                    caso: caso,
                    permisos: usuario().per,
                    table: table,
                    where: where,
                    value: value,
                    history: history,
                    infoLog:infoLog,
                    usuario:usuario().id,
                    data0:data0,
                    data1:data1,
                },
                timeout: 6500

            })
            .then((r) => {
                if (r.data.res) {
                    res(true);
                    if (reload) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                } else {
                    if(typeof r.data.msje === 'object'){
                        rej(Lectura(""+r.data.msje, iniciar));
                    }else{
                        rej(Lectura(r.data.msje, iniciar));
                    }
                }
            })
            .catch((f) => {
                if(typeof f === 'object'){
                    rej(Lectura(""+f, iniciar));
                }else{
                    rej(Lectura(f, iniciar));
                }

            })
    })

}