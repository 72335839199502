import { useState, useEffect } from "react";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import Get from "infortech_modules/Crud/Get";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import ModalDelete from "infortech_modules/Modales/ModalDelete";


export default function Actividades({iniciar}) {
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({actividad:''})
    const [editar, setEditar] = useState({id:'', actividad:''})

    useEffect(() => {
        principal();
        // eslint-disable-next-line
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get', key: '3', iniciar:iniciar }).then((r) => {
            setData(r);            
            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setError, true, setCargar, false);
        })
    }

    const formAdd = [
        [CrearInput({type:'text', name:'actividad', value:agregar.actividad, placeholder:'Actividad'})]
    ]

    const formEditar = [
        [CrearInput({type:'text', name:'actividad', value:editar.actividad, placeholder:'Actividad'})]
    ]

    return (
        <>
        <Contenedor rutas={['Inicio', 'Configuraciones', 'Actividades']} autorizado={Permisos().actividades}
        cargar={cargar} mensajeCarga={'Por favor espere...'} classNameMensaje={'text-dark fs-4 fw-bold fcom'} altura={'85vh'}
        error={error} mensajeError={mensaje} claseNameTextError={'text-danger fs-4 fw-bold fcom'}
        titulo={'Actividades del Cliente'}
        botonAdd = {Autorizado([Permisos().actividadesAdd]) && 
        <ModalAdd
        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nueva Actividad'} 
        modalTitulo={'Registrar Nueva Actividad'} tituloBotonEnviar={'Registrar'} tipoBotonEnviar={'principal'}
        formulario={formAdd} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
        url={'/post'} tabla={'actividades'} caso={'actividadesAdd'} agregar={agregar} duplicados={duplicate(true, [agregar.actividad], ['actividad'], ['Esta actividad ya esta registrada'])}
        mensajeLoadingPromesa={'Registrando...'} mensajePromesa={'Actividad Registrada'} comprobarTextoVacio={[agregar.actividad]} 
        mensajeTextoVacio={['Digite una actividad']} infoLog={agregar.actividad} principal={principal}
         />
        } 
        tablaEncabezado={['Item','Actividad', 'Acción']} claseTablaEncabezado={'text-center'} tablaNombre={'tac'}
        tablaFilas={data.map((v,i) => {
            return (
                <tr key={i}>
                    {
                        CreaarFila([i+1, v.actividad], "scope", [""], "text-center")
                    }
                    <td className="text-center">
                        <ModalEdit
                        formulario={formEditar} onChange={(e) => cambiarFormulario(e, editar, setEditar) }
                        modalTitulo={'Editar'} inicializarDatos={() => setEditar({id:v.id, actividad:v.actividad})}
                        duplicados={duplicate(true,[editar.actividad],['actividad'],['Esta actividad ya esta registrada'], [`and id != '${v.id}'`])}
                        comprobarTextoVacio={[editar.actividad]} mensajeTextoVacio={['"Digite una Actividad']}
                        mensajeLoadingPromesa={'Actualizando Datos...'} mensajePromesa={'Actividad Editada'}
                        idLog={v.id} infoLog={`${v.actividad} -> ${editar.actividad} `}
                        url={'/put'} caso={'actividadesEdit'} id={`id = ${v.id}`} tabla={'actividades'} editar={editar}
                        principal={principal}
                        />
                        &nbsp; &nbsp;
                        <ModalDelete
                        modalTitulo={'Eliminar Registro'} modalSize={'lg'}
                        mensajeEliminar={<>¿Seguro desea eliminar la actividad <strong>{v.actividad}</strong>? </>}
                        consultarHistorial={datosConsultarHistorial(false,['clientes'],['actividad'],['Error: Hay clientes asociadas con esta actividad'])}
                        valorEliminar={v.id} infoLog={v.actividad} caso={'actividadesDel'}
                        url={'/del'} tabla={'actividades'} condicionEliminar={`id = ${v.id}`} principal={principal}            
                        mensajeLoadingPromesa={'Elimiando...'} mensajePromesa={'Eliminado'}

                        />
                    </td>
                </tr>
            )
        })}
        
        />
        </>
    )
};
