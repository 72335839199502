import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import DateTime from "infortech_modules/Constantes/Fecha";
import { usuario } from "infortech_modules/Constantes/Usuarios";
import Put from "infortech_modules/Crud/Put";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";

export default function PhFinalizar({ id, idCil, principal }) {
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [actualizar, setActualizar] = useState({ certificado: "", est: "2", motivo: "", frec: "", usrfin: usuario().id })

    const guardar = () => {
        const promesa = new Promise((res,rej) => {
            Put({url:'/put/general', key:'PhFinalizar', caso:'PhFinalizar', data0:actualizar, data1:idCil, id:id})
            .then((r) => {
                tiempo(250, res,"Datos Guardados", principal, true)
            })
            .catch((f) => {
                tiempo(250, rej, f)
            })
        })
        AlertaPromesa('Guardando Datos ...', promesa, 'a');
    }

    const open = () => {
        setModal(true)
    }

    const open1 = () => {
        if (EsVacio([
            actualizar.est === "3"?"ok":actualizar.certificado,
            actualizar.frec,
            actualizar.est === "2"?"ok":actualizar.motivo
        ],
            ['Escriba el No de Certificado de la Prueba PH',
            actualizar.est === "2"? "Digite la fecha de aprobación de la Prueba PH":"Digite la fecha del certificado de la PH no aprobada",
            actualizar.est === "3"? "Especifique la razón por la cual cancela la prueba PH":"Motivo por el cual no aprobó la prueba PH"
        
        ])) {
            setModal1(true)

        }
    }


    const closed = () => {
        setModal(false)
    }

    const closed1 = () => {
        setModal1(false)
    }

    const opciones = [
        { value: "2", titulo: "Aprobada" }, { value: "3", titulo: "Cancelada" }, { value: "4", titulo: 'No Aprobada' }
    ]

    const cambiar = (e) => {
        setActualizar({
            ...actualizar,
            [e.target.name]: e.target.value
        })

        setActualizar((v) => {
            if (v.est === "2") {
                v.motivo = "";                
            }
            if (v.est === "3") {
                v.frec = DateTime();
                v.certificado = "";
            }
            return v;

        })
    }



    return (
        <>
            <Boton tipo={'icono'} Icono={BiTimeFive} titulo={'Finalizar PH'} posicionTexto={'left'} onClick={() => open()} />
            <Modales
                titulo={'Terminar Pruebas de presión hidrostática (PH)'}
                show={modal}
                onHide={closed}
                body={<>
                    {<Formularios datos={[
                        [CrearInput({ type: 'select', name: 'est', value: actualizar.est, placeholder: 'Estado', options: opciones })]
                    ]} onChange={cambiar} />}
                    {
                        actualizar.est === "2" &&
                        <>
                            <Formularios datos={[
                                [CrearInput({ type: 'text', name: 'certificado', value: actualizar.certificado, placeholder: 'No. de Certificado de la PH' })],
                                [CrearInput({ type: 'datetime-local', name: 'frec', value: actualizar.frec, placeholder: 'Fecha de la PH' })],
                            ]} onChange={cambiar} />
                        </>
                    }
                    {
                        actualizar.est === "3" &&
                        <Formularios datos={[
                            [CrearInput({ type: 'text', name: 'motivo', value: actualizar.motivo, placeholder: 'Motivo por la cual cancela la PH' })],
                        ]} onChange={cambiar} />
                    }
                    {
                        actualizar.est === "4" &&
                        <>
                            <Formularios datos={[
                                [CrearInput({ type: 'text', name: 'certificado', value: actualizar.certificado, placeholder: 'No. de Certificado de la PH' })],
                                [CrearInput({ type: 'text', name: 'motivo', value: actualizar.motivo, placeholder: '¿Por qué no aprobo la PH?' })],
                                [CrearInput({ type: 'datetime-local', name: 'frec', value: actualizar.frec, placeholder: 'Fecha del certificado' })],
                            ]} onChange={cambiar} />
                        </>
                    }

                </>}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />
                    <Boton tipo={'principal'} titulo={'Finalizar'} onClick={() => open1()} />
                </>}
            />
            <Modales
                titulo={'Confirmar'}
                show={modal1}
                onHide={closed1}
                body={<>
                    <div className="text-center p-3 fw-bold">{actualizar.est === "2"?"¿Está seguro de finalizar el proceso de la prueba de presión hidrostática (PH)?":actualizar.est === "3"?
                    "¿Está seguro de cancelar la prueba de presión hidrostática (PH)?":"¿Está seguro de finalizar el proeceso de la prueba de presión hidrostática (PH) en estado no aprobado?"}</div>
                    <div className="text-center p-3">
                        {
                            actualizar.est === "2"?"Una vez finalizado el proceso con la prueba de presión hidrostática (PH) aprobada, el cilindro regresara a disponible con estado vacío'":actualizar.est === "3"?
                            "Una vez cancelado, el cilindro regresara a disponible con estado PH Vencida y no podrá utilizar el cilindro hasta tanto no se aprueba la prueba de presión hidrostática (PH)":"Una vez registre que la prueba PH no fue aprobada, el cilindro regresara a disponible con estado PH Vencida y no podrá utilizar el cilindro hasta que se valide la prueba de presión hidrostática (PH)"
                        }
                    </div>
                </>}

                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed1()} />
                    <Boton tipo={'primary'} titulo={'Si, Finalizar'} onClick={() => guardar()} />
                </>}
            />

        </>
    )
};
