import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Modales from "infortech_modules/Componentes/Modales";
import { cambiarFormulario, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import EsNumero from "infortech_modules/Constantes/EsNumero";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Put from "infortech_modules/Crud/Put";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useState } from "react";
import { BsFillPersonCheckFill, BsFillPersonPlusFill } from "react-icons/bs";

export default function OrdenResponsables({ idPaciente, seleccionar }) {
    const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [agregar, setAgregar] = useState({ doc: "", nombres: "", dir: "", ciudad: "0", tel: "", parentesco: "", refPersonal: "", telRef: "" })
    const [ciudad, setCiudad] = useState([]);
    const [disabledInput, setDisabledInput] = useState(true);
    const [cambiarBoton, setCambiarBoton] = useState(true);
    //const [disabledBoton, setDisabledBoton] = useState(false);
    const [disabledDoc, setDisabledDoc] = useState(false);
    const [editar, setEditar] = useState({ doc: "", nombres: "", dir: "", ciudad: "0", tel: "", refPersonal: "", telRef: "" })
    const [idContacto, setIdContacto] = useState();
    const [enviarFuncion, setEnviarFuncion] = useState(false);
    //const [parentesco, setParentesco] = useState('')


    const open = (est) => {
        est && setCargar(true);
        setModal(true)
        Get({ url: '/get/general', key: 'PacienteContacto', data0: idPaciente })
            .then((r) => {
                setData(r[0]);
                setCiudad(r[1]);
                activar();
                tiempo(250, setCargar, false);
            }).catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false);
            })
    }

    const closed = () => {
        setModal(false);
        tiempo(250, setCargar, true, setError, false, setMensaje(''))
    }

    const buscar = () => {
        //setDisabledBoton(true);
        if (EsVacio([agregar.doc], ['Escriba el Número de Documento'])) {
            if (EsNumero([agregar.doc], ['El documento debe ser un valor númerico'])) {
                const promesa = new Promise((res, rej) => {
                    Get({ url: '/get/general', key: 'PacienteContactoBuscar', data0: idPaciente, data1: agregar.doc }).then((r) => {
                        if (r.length > 0) {
                            const d = r[0];
                            setAgregar({
                                ...agregar,
                                ciudad: d.ciudad,
                                dir: d.dir,
                                doc: d.doc,
                                nombres: d.nombres,
                                refPersonal: d.refPersonal,
                                tel: d.tel,
                                telRef: d.telRef
                            })
                            //setParentesco(d.parentesco)
                            setEnviarFuncion(true);
                            setIdContacto(d.id);
                            tiempo(250,  setCambiarBoton, false, res, 'Se encontra una persona con este número de documento, puede continuar con el registro...', setDisabledInput, false, setDisabledDoc, true)

                        } else {
                            setAgregar({ ...agregar, nombres: "", dir: "", ciudad: "0", tel: "", parentesco: "", refPersonal: "", telRef: "" });
                            setEnviarFuncion(false);
                            tiempo(250,  setCambiarBoton, false, res, 'Puede continuar con el registro', setDisabledInput, false, setDisabledDoc, true)
                        }
                    }).catch((f) => {
                        setEnviarFuncion(false);
                        tiempo(250, rej, f)
                    })
                })
                AlertaPromesa('Buscando Documento', promesa, 'a')

            } else {
                
            }
        } else {
            
        }
    }
    const activar = () => {
        setCambiarBoton(true);
        setDisabledInput(true)
        setDisabledDoc(false);
    }

    const inicializar = (d) => {
        setEditar(d);
    }

    const acutalizar = () => {
        if (EsVacio([agregar.doc, agregar.nombres, agregar.dir, agregar.ciudad === "0" ? "" : "ok", agregar.parentesco, agregar.tel, agregar.refPersonal, agregar.telRef],
            ['Escriba el No. de Documento', 'Escriba los Nombress y Apellidos', 'Digite la Dirección', 'Seleccione una Ciudad', 'Escriba el Parentesco', 'Digite el número de telefono del responsable', 'Escribal el nombre de la referencia perosnal', 'Digite el número de teléfono de la Referencia Personal'])) {
            if (EsNumero([agregar.doc, agregar.tel, agregar.telRef], ['El documento debe ser un valor númerico', 'El teléfono es un campo númerico', 'El teléfono de la referencia debe ser un valor númerico'])) {
                const promesa = new Promise((res, rej) => {
                    Put({ url: '/put/general', key: 'PacienteContactoUpdate', data0: agregar, id: idContacto, data1: idPaciente, caso: 'ordenesAdd' })
                        .then((r) => {
                            activar();
                            open(true);
                            tiempo(250, res, 'Guardado')
                        }).catch((f) => {
                            tiempo(250, rej, f)
                        })
                })
                AlertaPromesa('Guardando...', promesa, 'a')
            }

        }
    }

    const enviarDatos = (idC, nomC) => {
        seleccionar(idC, nomC);
        closed();
    }

    return (
        <>
            <Boton tipo={'icono'} Icono={BsFillPersonPlusFill} titulo={'Agregar Persona Responsalbe'} posicionTexto={'left'} claseIcon={'fs-1 pointer text-primary'}
                sizeIcon={35} onClick={() => open()}
            />
            <Modales
                show={modal}
                onHide={closed}
                body={<Contenedor autorizado={Permisos().ordenesAd} cargar={cargar} mensajeCarga={'Obteniendo Datos'} error={error} mensajeError={mensaje} altura={'65vh'}
                    titulo={''} botonAdd={Autorizado([Permisos().ordenesAd]) &&
                        <ModalAdd
                            modalTitulo={'Responsables'} disabledBotonEnviar={!disabledInput} tipoBotonPrincipal={'primary'} tituloBotonPrincipal={'Nuevo'} tipoBotonEnviar={'principal'}
                            contenidoSuperiorModal={
                                <div className="row">
                                    <div className="col-7 mb-3">
                                        <div className="form-floating">
                                            <input type={'text'} className={'form-control'} name={'doc'} value={agregar.doc} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                                                placeholder={'No de Documento'} disabled={disabledDoc}
                                            />
                                            <label>No de Documento</label>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <Boton tipo={'sinfondo'} titulo={cambiarBoton ? 'Buscar' : 'Cambiar'} onClick={cambiarBoton ? () => buscar() : () => activar()} />
                                    </div>
                                </div>
                            }
                            formulario={[
                                [
                                    CrearInput({ type: 'text', name: 'nombres', value: agregar.nombres, placeholder: 'Nombres y Apellidos', disabled: disabledInput })
                                ],
                                [CrearInput({ type: 'text', name: 'dir', value: agregar.dir, placeholder: 'Dirección', clase: 'col-5', disabled: disabledInput }),
                                CrearInput({
                                    type: 'select', name: 'ciudad', value: agregar.ciudad, placeholder: 'Ciudad', disabled: disabledInput, clase: 'col-7', list: { value: "0", titulo: "Seleccione la Ciudad" }, options: ciudad.map((v, i) => {
                                        return { value: v.id, titulo: <>{v.otro} {v.ciudad} ({v.dep})</> }
                                    })
                                }),
                                ],
                                [CrearInput({ type: 'text', name: 'parentesco', value: agregar.parentesco, placeholder: 'Parentesco', clase: 'col-7', disabled: disabledInput }), CrearInput({ type: 'text', name: 'tel', value: agregar.tel, placeholder: 'No de Teléfono', clase: 'col-5', disabled: disabledInput })],
                                [CrearInput({ type: 'text', name: 'refPersonal', value: agregar.refPersonal, placeholder: 'Referencia Personal', clase: 'col-8', disabled: disabledInput }), CrearInput({ type: 'text', name: 'telRef', value: agregar.telRef, placeholder: 'Teléfono', clase: 'col-4', disabled: disabledInput })]
                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                            comprobarTextoVacio={[agregar.doc, agregar.nombres, agregar.dir, agregar.ciudad === "0" ? "" : "ok", agregar.parentesco, agregar.tel, agregar.refPersonal, agregar.telRef]}
                            mensajeTextoVacio={['Escriba el No. de Documento', 'Escriba los Nombress y Apellidos', 'Digite la Dirección', 'Seleccione una Ciudad', 'Escriba el Parentesco', 'Digite el número de telefono del responsable', 'Escribal el nombre de la referencia perosnal', 'Digite el número de teléfono de la Referencia Personal']}
                            numeroComprobar={[agregar.doc, agregar.tel, agregar.telRef]}
                            mensajeNumeroComprobar={['El documento debe ser un valor númerico', 'El teléfono es un campo númerico', 'El teléfono de la referencia debe ser un valor númerico']}
                            url={'/post/general'} key1={'PacienteContactoAdd'} duplicados={duplicate(true, [agregar.doc], ['doc'], ['Error: Número de documento ya existe'])}
                            agregar={agregar} datos1={idPaciente} caso={'ordenesAdd'} mensajeLoadingPromesa={'Creando...'} mensajePromesa={'Creado'}
                            functionEnviar={enviarFuncion ? acutalizar : undefined} principal={open}
                        />
                    }
                    tablaEncabezado={['Nombre y Apellidos', 'Documento', 'Telefóno', 'Parentesco', 'Acciones']} tablaNombre={'t1pr'}
                    tablaFilas={data.map((v, i) => {
                        datos[i] = ({ doc: v.doc, nombres: v.nombres, dir: v.dir, ciudad: v.ciudad, tel: v.tel, parentesco: v.parentesco, refPersonal: v.refPersonal, telRef: v.telRef })
                        return <tr key={i}>
                            {CreaarFila([v.nombres, v.doc, v.tel, v.parentesco, <>
                                <ModalEdit
                                    modalTitulo={'Editar'}
                                    inicializarDatos={() => inicializar(datos[i])}
                                    formulario={[
                                        [
                                            CrearInput({ type: 'text', name: 'nombres', value: editar.nombres, placeholder: 'Nombres y Apellidos', })
                                        ],
                                        [CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección', clase: 'col-5', }),
                                        CrearInput({
                                            type: 'select', name: 'ciudad', value: editar.ciudad, placeholder: 'Ciudad', clase: 'col-7', list: { value: "0", titulo: "Seleccione la Ciudad" }, options: ciudad.map((v, i) => {
                                                return { value: v.id, titulo: <>{v.otro} {v.ciudad} ({v.dep})</> }
                                            })
                                        }),
                                        ],
                                        [CrearInput({ type: 'text', name: 'parentesco', value: editar.parentesco, placeholder: 'Parentesco', clase: 'col-7', disabled: true }), CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: 'No de Teléfono', clase: 'col-5', })],
                                        [CrearInput({ type: 'text', name: 'refPersonal', value: editar.refPersonal, placeholder: 'Referencia Personal', clase: 'col-8', }), CrearInput({ type: 'text', name: 'telRef', value: editar.telRef, placeholder: 'Teléfono', clase: 'col-4' })]
                                    ]} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                                    comprobarTextoVacio={[editar.doc, editar.nombres, editar.dir, editar.ciudad === "0" ? "" : "ok", editar.parentesco, editar.tel, editar.refPersonal, editar.telRef]}
                                    mensajeTextoVacio={['Escriba el No. de Documento', 'Escriba los Nombress y Apellidos', 'Digite la Dirección', 'Seleccione una Ciudad', 'Escriba el Parentesco', 'Digite el número de telefono del responsable', 'Escribal el nombre de la referencia perosnal', 'Digite el número de teléfono de la Referencia Personal']}
                                    numeroComprobar={[editar.doc, editar.tel, editar.telRef]}
                                    mensajeNumeroComprobar={['El documento debe ser un valor númerico', 'El teléfono es un campo númerico', 'El teléfono de la referencia debe ser un valor númerico']}
                                    url={'/put'} editar={editar} principal={open} mensajeLoadingPromesa={'Actualizando'} mensajePromesa={'Actualizado'} tabla={'pacientes_ordenes_contacto'}
                                    id={`id = ${v.id}`} idLog={v.id} infoLog={`${JSON.stringify(datos[i])}->${JSON.stringify(editar)}`} caso={'ordenesAdd'}
                                />
                                &nbsp; &nbsp;
                                <Boton tipo={'icono'} Icono={BsFillPersonCheckFill} posicionTexto={'left'} titulo={'Seleccionar a ' + v.nombres} claseIcon={'fs-4 pointer text-emerald-700'} onClick={() => enviarDatos(v.id, v.nombres)} />
                            </>])}
                        </tr>
                    })}

                />}
                titulo={'Personas Responsables'}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />}
            />
        </>
    )
};
