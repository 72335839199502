import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import DateTime from "infortech_modules/Constantes/Fecha";
import { usuario } from "infortech_modules/Constantes/Usuarios";
import Post from "infortech_modules/Crud/Post";
import { useState } from "react";

export default function RegistrarCalibracion({ id, titulo, tipo, principal, asunto, estados, transferir, asignar, idEquipo, recargar }) {
    asunto = asunto === undefined ? [] : asunto;
    estados = estados === undefined ? [] : estados;
    transferir = transferir === undefined ? [] : transferir;
    asignar = asignar === undefined ? [] : asignar;

    //const [disable, setDisable] = useState(false);
    const [modal, setModal] = useState(false);
    const [detalle, setDetalle] = useState({ idCE: id, asunto: "1", actividad: "", usrActividad: usuario().id, fecha: DateTime(), privado: false, obs: "", est: 1 });
    const [persona, setPersona] = useState("");


    const guardar = () => {
        //setDisable(true);
        const promesa = new Promise((res, rej) => {
            Post({ url: '/post/general', data0: detalle, data1: idEquipo, key: "CalibracionDetallesAdd", caso: "registrarCalibracion", data2: id }).then((r) => {
                tiempo(500, res, 'Información Agregada');
                principal(true);
                if (detalle.asunto === "3" && detalle.actividad === "4") {
                    recargar !== undefined && recargar(true);
                }
            }).catch((f) => {
                tiempo(250, rej, f)
            })
        })
        AlertaPromesa('Agregando información al proceso de Calibración...', promesa, "a");

    }

    const open1 = () => {
        //setDisable(true);
        let act = detalle.asunto === "1" ? detalle.actividad : detalle.asunto > "1" && (detalle.actividad === "00" ? "" : detalle.actividad);
        let msje = detalle.asunto === "1" ? "La nueva nota no puede estar vacía" : detalle.asunto === "2" ? "Debe seleccionar la persona a que le asigna el matenimiento" :
            detalle.asunto === "3" ? "Seleccione el Estado" : detalle.asunto === "4" && "Seleccione el usuario o proveedor al cual transferira el mantenimiento";
        let obs = detalle.asunto === "1" ? "ok" : detalle.obs;
        let msje1 = detalle.asunto === "2" ? "Escriba la razón por la cual le asigna el mantenimiento" : detalle.asunto === "3" ? "Escriba la razón por la cual cambia el estado" :
            detalle.asunto === "4" && "Escriba la razón por la cual transfiere el mantenimiento";

        if (EsVacio([act, obs], [msje, msje1])) {
            setModal(true);
        } else {
            //tiempo(500, setDisable, false)
        }

    }



    const closed = () => {
        setModal(false);
    }
    const cambiar = (e) => {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;
        setDetalle({
            ...detalle,
            [n]: v
        })

        setDetalle((v) => {
            if (v.asunto !== "1") {
                v.privado = false;
                if (detalle.asunto !== v.asunto) {
                    v.actividad = "0"
                } else {
                    if (v.asunto === "2") {
                        const f = asignar.filter(a => a.id === parseInt(v.actividad))
                        if (f.length > 0) {
                            setPersona(f[0].usr)
                        }

                    } else if (v.asunto === "4") {
                        const f = transferir.filter(a => a.id === parseInt(v.actividad))
                        if (f.length > 0) {
                            setPersona(f[0].transf)
                        }
                    } else {
                        setPersona("");
                    }
                }

            }
            return v;
        })
    }

    const lugares = transferir.map((v, i) => { return { value: v.id, titulo: v.transf + " " + v.tipo } });
    lugares.unshift({ value: "0", titulo: "Bodega Principal" })


    return (
        <>
            {

                <>
                    <div className="mb-2 mt-2">
                        <div className="row"><div className="m-titulo mb-3">Agregar Detalle </div></div>
                        <div className="row">
                            <div>
                                <Formularios
                                    datos={[[
                                        CrearInput({ type: 'checkbox', value: detalle.privado, name: 'privado', clase: 'col-xl-2 col-lg-2 col-sm-4 col-4', placeholder: "Privado", disabled: detalle.asunto !== "1" ? true : false, }),
                                        CrearInput({
                                            type: 'select', name: 'asunto', value: detalle.asunto, placeholder: 'Asunto', options: asunto.map((v, i) => {
                                                return ({ value: v.id, titulo: v.asunto })
                                            }), clase: 'col-xl-3 col-lg-3 col-sm-4 col-4'
                                        }),
                                        detalle.asunto === "1" ?
                                            CrearInput({ type: 'text', name: 'actividad', value: detalle.actividad, placeholder: 'Actividad', clase: 'col-xl-7 col-lg-7 col-sm-4 col-4' }) : detalle.asunto === "2" ?
                                                CrearInput({ type: 'select', name: 'actividad', value: detalle.actividad, placeholder: 'Seleccione la persona', clase: 'col-xl-7 col-lg-7 col-sm-4 col-4', options: asignar.map((v, i) => { return ({ value: v.id, titulo: v.usr }) }), list: { value: "0", titulo: "Seleccione el Usuario" } }) : detalle.asunto === "3" ?
                                                    CrearInput({ type: 'select', name: 'actividad', value: detalle.actividad, placeholder: 'Estado', clase: 'col-xl-7 col-lg-7 col-sm-4 col-4', options: estados.map((v, i) => { return ({ value: v.id, titulo: v.estado }) }), list: { value: "0", titulo: "Seleccione el Estado" } }) : detalle.asunto === "4" &&
                                                    CrearInput({ type: 'select', name: 'actividad', value: detalle.actividad, placeholder: 'Transferir a', clase: 'col-xl-7 col-lg-7 col-sm-4 col-4', options: lugares, list: { value: "00", titulo: "Seleccione el lugar donde transferira el mantenimiento" } })
                                    ],
                                    detalle.asunto !== "1" ? [CrearInput({ type: 'text', name: 'obs', value: detalle.obs, placeholder: detalle.asunto === "2" ? "¿Por qué lo asigna?" : detalle.asunto === "3" ? "¿Por que cambia lo cambia de estado?" : detalle.asunto === "4" && "¿Por qué lo transfiere?" })] : []
                                    ]}
                                    onChange={cambiar}
                                />


                            </div>
                            <div className="row">
                                <div className="col-10"></div>
                                <div className="col-2">
                                    <Boton tipo={'sinfondo'} titulo={'Guardar'} onClick={() => open1()} />
                                </div>

                            </div>
                        </div>
                    </div>

                </>
            }
            <Modales
                titulo={tipo === 'actividad' ? 'Guardar Información de una Actividad Especifica' : detalle.asunto === "1" ? "Agregar Nota" : detalle.asunto === "2" ? "Asignar un Mantenimiento" :
                    detalle.asunto === "3" ? "Cambiar de Estado el Mantenimiento" : detalle.asunto === "4" && "Transferir el Mantenimiento"}
                size={'lg'}
                show={modal}
                onHide={closed}
                body={<div className="text-center p-3">
                    {
                        tipo === "actividad" ? " Una vez guardado no podrá editar ni eliminar la actividad realizada. ¿Desea guardar el regisrtro?" :
                            detalle.asunto === "1" ? "¿Desea agregar la nota al proceso de calibración?" : detalle.asunto === "2" ? <>¿Está seguro de asignar este proceso de calibración al Sr (a) <strong>{persona}</strong>?</> :
                                detalle.asunto === "3" ? detalle.est === "4" ? "¿Esta seguro de finalizar el proceso de calibración? Una vez finalizado no podrá interacturar con este proceso de calibración y la información solo estara a modo de consulta?" : "¿Está seguro de cambiar de estado el presente proceso de calibración?" :
                                    detalle.asunto === "4" && <>¿Está seguro de transferir el equipo a <strong>{persona}</strong>?</>
                    }
                </div>}
                footer={<>
                    <Boton titulo={'Cancelar'} tipo='secondary' onClick={() => closed()} />
                    <Boton titulo={tipo === 'actividad' ? "Si, Guardar" : detalle.asunto === "1" ? "Si, Guardar Nota" : detalle.asunto === "2" ? "Si, Asignar" :
                        detalle.asunto === "3" ? "Cambiar Estado" : detalle.asunto === "4" && "Si, Transferir"
                    } tipo={'principal'} onClick={() => guardar()} />

                </>}
            />
        </>
    )
};
