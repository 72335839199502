import { MdWarning } from 'react-icons/md';
export default function Error({ mensaje, altura, fondo, Icono, colorIcon, sizeIcon, classNameText }) {
    mensaje = mensaje !== undefined ? mensaje : ""
    fondo = fondo !== undefined ? fondo : "bg-transparent"
    altura = altura !== undefined ? altura : "0"
    colorIcon = colorIcon !== undefined ? colorIcon : 'red';
    Icono = Icono !== undefined? Icono:MdWarning;
    sizeIcon = sizeIcon !== undefined ? sizeIcon:'84';
    classNameText = classNameText !== undefined ? classNameText+' p-4 text-center':'text-3xl text-danger font-bold text-center';
    

    return (
        <>
            <div>
                <div className='container-fluid p-3'>
                    <div className={` ${fondo} rounded`}>
                        <div className='App-header' style={{ minHeight: altura }}>
                            <div className='mb-3'>
                                <Icono
                                    fontSize={sizeIcon}
                                    color={colorIcon}
                                />
                            </div>
                            <div className={classNameText}>{mensaje}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
