import { useState } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import { datosConsultarHistorial} from 'infortech_modules/Constantes/Constantes';
import CrearInput from 'infortech_modules/Constantes/CrearInput';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';
import Permisos from 'infortech_modules/Constantes/Usuarios';
import ModalEdit from 'infortech_modules/Modales/ModalEdit';
import ModalDelete from 'infortech_modules/Modales/ModalDelete';
import { extraerCliente, buscarCilindro, cambiarFormulario } from 'infortech_modules/Constantes/Constantes';
import Formularios from 'infortech_modules/Componentes/Formularios';




export default function PedidosTemporalClientes({ select, datosTemporales, cargar, mensaje, error, dos, paciente, actualizar }) {
    datosTemporales = datosTemporales === undefined ? [] : datosTemporales;
    const datos = [];
    const pro = [];
    const [temporal, setTemporal] = useState({ vid: "", cant: "", otro: "", des: "", tipo: "" });
    const [temp, setTemp] = useState({ tipo: "", prodcuto: "" })
    const [ingDos, setIngDos] = useState(false);


    const inicializarDatos = (d, p) => {
        setTemporal(d);
        setTemp(p)
        setTemporal((v) => {
            console.log(v);
            if (v.tipo === "Producto") {
                const s = buscarCilindro(dos, v.vid.toString());
                if (s) {
                    setIngDos(false);
                    v.des = "";
                    v.otro = "";
                } else {
                    setIngDos(true);

                }
            } else {
                setIngDos(false);
                v.des = "";
                v.otro = "";
            }
            return v;
        })
    }

    const cambiarTemporal = (e) => {
        cambiarFormulario(e, temporal, setTemporal);
        setTemporal((v) => {
            v.tabla = extraerCliente(v.vid, 1);
            if (extraerCliente(v.vid, 1) === "Producto") {
                const s = buscarCilindro(dos, extraerCliente(v.vid));
                if (s) {
                    setIngDos(false);
                    v.des = "";
                    v.otro = "";
                } else {
                    setIngDos(true);

                }
            } else {
                setIngDos(false);
                v.des = "";
                v.otro = "";
            }
            v.cliente = paciente.idPac;

            return v;
        })
    }


    return (

        <>
            <div className='fw-bold fs-5 mb-3 text-center fcom'>Detalles del Pedido</div>
            <Contenedor ruta={[]} autorizado={Permisos().pedidosAdd} altura={'15vh'}
                cargar={cargar} mensajeCarga={'Cargando Información del Pedido'} error={error} mensajeError={mensaje}
                tablaEncabezado={['Tipo', 'Producto, Equipo o Descartable', 'Cant', 'Acciones']}
                tablaNombre={'ttemp'}
                tablaFilas={datosTemporales.map((v, i) => {
                    datos[i] = { vid: v.vid, cant: v.cant, des: v.des}
                    
                    return <tr key={i}>
                        {CreaarFila([v.tabla, v.descripcion, v.cant, <>
                            <ModalEdit modalTitulo={'Editar ' + temp.tipo} inicializarDatos={() => inicializarDatos(datos[i], pro[i])}
                                contenidoSuperiorModal={
                                    <>
                                        <div className='row'>
                                            <div className={ingDos ? "col-xl-6 col-lg-6 col-12" : "col-6"}>
                                                <Formularios datos={[
                                                    [CrearInput({ type: 'text', value: temp.prodcuto, placeholder: temp.tipo, disabled: true })],
                                                ]} />

                                            </div>
                                            <div className={ingDos ? "col-xl-6 col-lg-6 col-12" : "col-xl-6 col-lg-6 col-6"}>
                                                <div className='row'>
                                                    <div className={ingDos ? "col-xl-4 col-lg-4 col-4" : "col-12"}><Formularios datos={[
                                                        [CrearInput({ type: 'text', name: 'cant', value: temporal.cant, placeholder: 'Cantidad' })]]}
                                                        onChange={cambiarTemporal} />
                                                    </div>
                                                    <div className='col-4'>
                                                        {
                                                            ingDos &&
                                                            <Formularios datos={[
                                                                [CrearInput({ type: 'text', name: 'otro', value: temporal.otro, placeholder: 'Horas', })]
                                                            ]} onChange={cambiarTemporal} />
                                                        }
                                                    </div>
                                                    <div className='col-4'>
                                                        {
                                                            ingDos &&
                                                            <Formularios datos={[
                                                                [CrearInput({ type: 'text', name: 'des', value: temporal.des, placeholder: 'LPM', })]
                                                            ]} onChange={cambiarTemporal} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <ModalDelete modalTitulo={'Eliminar ' + v.tipo}
                                mensajeEliminar={<>Va ha eliminar el producto <strong>{v.descripcion}</strong> del pedido ¿Desea continuar? </>}
                                tituloBotonEnviar={'Si, Elimianr'}
                                tabla={'temporal'} url={'/del'} condicionEliminar={`id = ${v.id}`} consultarHistorial={datosConsultarHistorial(true)}
                                caso={'pedidosAdd'}
                                mensajeLoadingPromesa={'Eliminando'} mensajePromesa={'Eliminado'}
                                principal={actualizar}
                            />
                        </>])}
                    </tr>
                })}

            />

        </>
    )
};
