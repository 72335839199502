import Contenedor from "infortech_modules/Componentes/Contenedor";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useEffect, useState } from "react"

export default function Familias({ iniciar }) {
    const [carga, setCarga] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({familia:"", nomenclatura:""})
    const [editar, setEditar] = useState({familia:"", nomenclatura:""})

    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCarga(true)
        Get({ url: '/get', key: '8' }).then((r) => {
            setData(r);
            tiempo(500, setCarga, false);
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setError, true, setCarga, false)
        })
    }


    return (
        <>
            <Contenedor autorizado={Permisos().familas}
                cargar={carga} mensajeCarga={'Cargando Datos...'} classNameMensaje={'text-dark text-center p-3 fs-5'} altura={'85vh'}
                error={error} mensajeError={mensaje} claseNameTextError={'text-danger text-center p3 fs-5'}
                rutas={['Inicio', 'Familias', 'Familias']} titulo={'Familias'}
                botonAdd={<>
                    {
                        Autorizado([Permisos().familiasAdd]) &&
                        <ModalAdd
                            tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nueva Familia'}
                            formulario={[
                                [CrearInput({type:'text', name:'familia', value:agregar.familia, placeholder:'Nombre Descriptivo'})],
                                [CrearInput({type:'text', name:'nomenclatura', value:agregar.nomenclatura, placeholder:'Codigo de Identificación'})],
                            ]}
                            onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                            modalTitulo={'Agregar Familia'} tipoBotonEnviar={'principal'}
                            comprobarTextoVacio={[agregar.familia, agregar.nomenclatura]} 
                            mensajeTextoVacio={['Escriba un nombre que describa la familia', 'Escriba un codigo para identificar la familia']}
                            mensajeLoadingPromesa={'Agregando Familia...'} mensajePromesa={'Familia Agregada'} principal={principal}
                            url={'/post'} caso={'familiasAdd'} agregar={agregar} infoLog={JSON.stringify(agregar)} tabla={'familias'}
                            duplicados={duplicate(true, [agregar.familia, agregar.nomenclatura],['familia', 'nomenclatura'],['Ya existe una familia registrada con este nombre', ['Ya existe una nomenclatura registrada con este código']])}
                            
                        />
                    }
                </>}
                tablaEncabezado={['Código', 'Familia', 'Acciones']}
                tablaNombre={'tf'}
                tablaFilas={data.map((v, i) => {
                    return (
                        <tr key={i}>
                            {CreaarFila([v.nomenclatura, v.familia],"scope", [""], "text-left")}
                            <td className="text-left">
                                {
                                    Autorizado([Permisos().familiasEdit]) && 
                                    <ModalEdit
                                    modalTitulo={'Editar Familia'}
                                    formulario={[
                                        [CrearInput({type:'text', name:'familia', value:editar.familia, placeholder:'Nombre Descriptivo'})],
                                        [CrearInput({type:'text', name:'nomenclatura', value:editar.nomenclatura, placeholder:'Codigo de Identificación'})],
                                    ]}
                                    onChange={(e) => cambiarFormulario(e, editar, setEditar)} 
                                    inicializarDatos={() => setEditar({familia:v.familia, nomenclatura:v.nomenclatura})}
                                    duplicados={duplicate(true, [editar.familia, editar.nomenclatura],['familia', 'nomenclatura'], ['Este nombre de familia ya existe', 'Esta nomenclatura ya existe'], [` and id != ${v.id}`, ` and id != ${v.id}`])}
                                    id={`id = ${v.id}`} idLog={v.id} infoLog={JSON.stringify(`${editar} -> {familia:${v.familia}, nomenclatura:${v.nomenclatura}}`)}
                                    tabla={'familias'} caso={'familiasEdit'} mensajeLoadingPromesa={'Actualizando ...'} mensajePromesa={'Actualizado'}
                                    url={'/put'} principal={principal} editar={editar}

                                    />
                                }
                                &nbsp; &nbsp;
                                {
                                    Autorizado([Permisos().familiasDel]) && 
                                    <ModalDelete
                                    modalTitulo={'Eliminar Familia'}
                                    mensajeEliminar={<>¿Desea eliminar la familia <strong>{v.familia}</strong> - <strong>{v.nomenclatura}</strong>?</>}
                                    mensajeLoadingPromesa={'Eliminando'} mensajePromesa={'Eliminado'} principal={principal}
                                    tabla={'familias'} caso={'familiasDel'} url={'/del'} infoLog={JSON.stringify({id:v.id, familia:v.familia, nomenclatura:v.nomenclatura})}
                                    valorEliminar={v.id} condicionEliminar={`id = ${v.id}`} 
                                    consultarHistorial={datosConsultarHistorial(false, ['subfamilias'],['familia'],['Esta familia tiene subfamilias asignadas'])}
                                    />
                                }

                            </td>
                        </tr>
                    )
                })}
            />
        </>
    )
};
