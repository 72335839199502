import img from "infortech_modules/Recursos/Imagenes/sicma_ox.png";

export const api = 'https://sicma.groupbinarios.co';
//export const api = 'http://172.20.10.12:3011'
//export const api = 'http://localhost:3011';
//export const api = 'http://localhost:3011';

export const duplicate = (estado, datos, row, msje, ad) => {
    ad = ad === undefined ? ["", "", "", "", ""] : ad;


    if (estado) {
        return {
            verificar: true,
            datos: datos,
            row: row,
            ad: ad,
            msje: msje,
        }
    } else {
        return { verificar: false }
    }
}

export const datosConsultarHistorial = (estado, tabla, fila, msje, adicional) => {
    adicional = adicional === undefined ? ["", "", "", "", "", "", "", ""] : adicional;
    if (estado) {
        return {
            verificar: estado,
        }
    } else {
        return {
            verificar: false,
            tabla: tabla,
            row: fila,
            msje: msje,
            ad: adicional
        }
    }
}

export const header = () => {
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.sicma}`
    }
}

export const upload = "http://192.168.0.101/sicma";
export const imagenes = {
    productos: `${upload}/Imagenes/Productos/`, servicios: `${upload}/Imagenes/Servicios/`,
    logos: `${upload}/Imagenes/Logos/`, portada: `${upload}/Imagenes/Portada/`, perfil: `${upload}/Imagenes/Perfil/`,
    pportada: `${upload}/Imagenes/Perfiles/Portada/`
}

export const cambiarFormulario = (e, dato, setDato, setImg) => {
    if (e.target.files && e.target.files.length > 0) {
        const archivo = e.target.files[0];
        if (archivo.type.includes("image")) {
            setImg(archivo)
        }
    } else {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        setDato({
            ...dato,
            [n]: v
        })
    }
}

export const urlMaps = (coordendas, zoom) => {
    return `https://maps.google.com/?ll=${coordendas.lat},${coordendas.lng}&z=${zoom}`;
}

export const Logo = img;
export const tiempo = (time, f1, v1, f2, v2, f3, v3, f4, v4, f5, v5) => {

    setTimeout(() => {
        if (typeof f1 === 'function') {
            f1(v1)
        }
        if (typeof f2 === 'function') {
            f2(v2)
        }
        if (typeof f3 === 'function') {
            f3(v3)
        }
        if (typeof f4 === 'function') {
            f4(v4)
        }
        if (typeof f5 === 'function') {
            f5(v5)
        }

    }, time);
}

export const buscarTipoCliente = (objeto, valor) => {
    if (Array.isArray(objeto)) {
        if (objeto.some(v => v.ipa === valor)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

export const buscarValor = (array, valor) => {
    const v = valor.split("-");
    return array.some(a => a.id === parseInt(v[0]));

}

export const buscarCilindro = (array, valor) => {
    const v = valor.split("-");
    return array.some(a => a.idProducto === parseInt(v[0]));
}

export const determinarEstadoCilindros = (estado, subestado) => {
    let retorno;
    switch (parseInt(estado)) {
        case 1:
            retorno = "Vacío";
            break;
        case 2:
            retorno = "En Ruta";
            break;
        case 3:
            retorno = "En Cliente";
            break;
        case 4:
            retorno = "En Mantenimiento";
            break;
        case 5:
            retorno = "En PH";
            break;
        case 6:
            retorno = "Lleno";
            break;

        default:
            retorno = "Desconocido";
            break;
    }
    return retorno;
}

export const determinarEstadoEps = (estado) => {
    let retorno;
    switch (estado) {
        case 1:
            retorno = "Activo";
            break;
        case 2:
            retorno = "Suspendida";
            break;
        case 3:
            retorno = "Finalizada";
            break;

        default:
            break;
    }

    return retorno;
}

export const extraerCliente = (cliente, pos) => {
    pos = pos === undefined ? 0 : pos;
    let v = cliente.split("-");
    if (v[pos] === undefined) {
        return ""
    } else {
        return v[pos].trim();
    }

}

export const calcularEdad = (date) => {

    if (date !== "0000-00-00") {
        const actual = new Date();
        const fecha = new Date(date);
        const tiempo = actual - fecha;
        let calculo = (tiempo / (1000 * 60));
        calculo = (calculo / (365 * 24 * 60)).toFixed(2);
        if (isNaN(calculo)) {
            return 0
        } else {
            return calculo;
        }
    } else {
        return 0;
    }

}

export const calcularCantidad = (array, valor, diferencia) => {
    if (diferencia !== "") {
        valor = parseFloat(valor);
        diferencia = parseFloat(diferencia);
        const datos = array.filter(a => a.id === valor)[0];
        const minuendo = datos.cant;
        const sustraendo = datos.cantE;
        const total = minuendo - sustraendo;
        console.log(minuendo, sustraendo, total);


        if (diferencia <= total) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const diferenciaCantidad = (array, valor, diferencia, tipo) => {
    if (valor !== "" || valor !== undefined) {
        const datos = array.filter(a => a.id === valor)[0];
        if (tipo !== undefined) {
            return datos.cantE;
        } else {
            valor = parseFloat(valor);
            return datos.cant;
        }

    } else {
        return 0;
    }
}

export const determinarEstadoPedidos = (key) => {
    let estado;
    switch (parseInt(key)) {
        case 1:
            estado = "Recibimos tu Pedido";
            break;
        case 2:
            estado = "Estamos alistando tu pedido";
            break;
        case 3:
            estado = "Vamos en camino a tu ciudad";
            break;
        case 4:
            estado = "Vamos a tu dirección";
            break;
        case 5:
            estado = "Hemos entregado tu pedido";
            break;
        case 6:
            estado = "Tu pedido ha sido devuelto";
            break;
        case 7:
            estado = "Tu pedido ha sido cancelado";
            break;
        case 8:
            estado = "Regresando el Pedido a Plataforma";
            break;
        case 9:
            estado = "Pedido Cancelado";
            break;
        case 100:
            estado = "Tu pedido esta pendiente de autorización";
            break;
        default:
            break;
    }

    return estado;
}


export default api;

