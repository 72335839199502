import { Logo } from "infortech_modules/Constantes/Constantes"
import { imagenes } from "infortech_modules/Constantes/Constantes"
import { usuario } from "infortech_modules/Constantes/Usuarios"
export default function Header({ broken }) {
    return (
        <>
            <div className="sbh-header">
                <div className="sbh-style">
                    <img className="col-10" src={Logo} alt={'Logo Principal INFORTECH SAS'} />
                </div>
            </div>
            {
                broken && (
                    <div className="sbh-header">
                        <div className="sbh-style">
                            <img src={imagenes.perfil + usuario().img} alt='Foto de Perfil del Usuario' className="ly-i-img" />
                            <div className="ml-3 text-white">{usuario().nom}</div>
                        </div>
                    </div>
                )
            }
        </>
    )
};
