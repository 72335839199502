import { MdDelete } from "react-icons/md";


export const Dias = (dias) => {
    const array = dias.split(",");

    const r = array.map((v, i) => {
        let dia = "";
        switch (v) {
            case "1":
                dia = "Lunes"
                break;
            case "2":
                dia = "Martes";
                break;
            case "3":
                dia = "Miercoles";
                break;
            case "4":
                dia = "Jueves";
                break;
            case "5":
                dia = "Viernes";
                break;
            case "6":
                dia = "Sabado";
                break;
            case "7":
                dia = "Domingo";
                break;
            default:
                break;
        }
        return (
            <div key={i}>{dia}</div>
        )
    })
    return <div>{r}</div>;
}

export const escribirDias = (dias, ad) => {
    const array = dias.split(",");

    const r = array.map((v, i) => {
        let dia = "";
        switch (v) {
            case "1":
                dia = "Lunes"
                break;
            case "2":
                dia = "Martes";
                break;
            case "3":
                dia = "Miercoles";
                break;
            case "4":
                dia = "Jueves";
                break;
            case "5":
                dia = "Viernes";
                break;
            case "6":
                dia = "Sabado";
                break;
            case "7":
                dia = "Domingo";
                break;
            default:
                break;
        }

        ad = ad === undefined ? parseInt(array[i + 1]) === i + 2 ? " " : "" : "";
        return parseInt(array[i]) === i + 1 ? dia : parseInt(array[i - 1]) === i ? ad : ""
    })
    return (
        r
    )

}


export const ciudadesRuta = (ciudades, ciudad) => {
    const array = ciudades.split(",");
    const d = array.map((v, i) => {
        const data = ciudad.filter(e => e.id === parseInt(v));
        const valor = data[0];

        return data.length === 1 && <div key={i}>{valor.otro === "" ? valor.municipio + ' (' + valor.departamento + ') ' : valor.otro + ' - ' + valor.municipio + ' (' + valor.departamento + ') '}</div>

    })

    return <div>{d}</div>;
}

export const ciudadeSelect = (ciudades, ciudad, eliminar) => {
    const array = ciudades.split(",");
    const d = array.map((v, i) => {
        const data = ciudad.filter(e => e.id === parseInt(v));
        const valor = data[0];

        return data.length === 1 &&
            <div key={i} className="row mb-2">
                <div className="col-9" key={i}>{valor.otro === "" ? valor.municipio + ' (' + valor.departamento + ') ' : valor.otro + ' - ' + valor.municipio + ' (' + valor.departamento + ') '}</div>
                <div className="col-3">
                    <div className="infoText">
                        <MdDelete className="text-danger fs-5 pointer" onClick={() => eliminar(i)} />
                        <span className="textText-Top">Eliminar Ciudad</span>
                    </div>
                </div>
            </div>

    })

    return <div>{d}</div>;
}


export const diasSemana = (onChange, name, value) => {
    const array = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
    const d = array.map((v, i) => {
        return (
            <div key={i} className="mb-1">
                <input type={'checkbox'} name={name[i]} checked={value[i]} onChange={onChange} />
                <label>&nbsp;{v}</label>
            </div>
        )
    })

    return d;
}

export const convertirDias = (obj) => {
    let l, m, mm, j, v, s, d;
    l = obj.l ? '1' : '';
    m = obj.m ? '2' : '';
    mm = obj.mm ? '3' : '';
    j = obj.j ? '4' : '';
    v = obj.v ? '5' : '';
    s = obj.s ? '6' : '';
    d = obj.d ? '7' : '';
    const a = ([l, m, mm, j, v, s, d]);

    return (a.toString())

}

export const escribirCiudades = (ciudades, ciudad) => {
    const array = ciudades.split(",");

    const d = array.map((v, i) => {
        let ad = "";
        const data = ciudad.filter(e => e.id === parseInt(v));
        const valor = data[0];
        ad = array.length === i + 1 ? " " : ", "
        return data.length === 1 &&

            valor.otro === "" ? valor.municipio + ' (' + valor.departamento + ')' + ad : valor.otro + ' - ' + valor.municipio + ' (' + valor.departamento + ')' + ad;


    })

    return d
}

export const escribirCiudadesDir = (ciudades, ciudad) => {
    const array = ciudades.split(",");
    let array2 = [];

    array.map((v, i) => {
       
        const data = ciudad.filter(e => e.id === parseInt(v));
        const valor = data[0];

        data.length === 1 &&

            array2.push(" "+(valor.otro === "" ? ' ' + valor.municipio + ' (' + valor.departamento + ')' : valor.otro + ' - ' + valor.municipio + ' (' + valor.departamento + ') '));
        return array2;

    })

    return array2.toString();
}

export const diasDir = (dias) => {

    const array = dias.split(",");

    let array2 = [];
    array.map((v, i) => {

        let dia = "";
        switch (v) {
            case "1":
                dia = "Lunes"
                break;
            case "2":
                dia = "Martes";
                break;
            case "3":
                dia = "Miercoles";
                break;
            case "4":
                dia = "Jueves";
                break;
            case "5":
                dia = "Viernes";
                break;
            case "6":
                dia = "Sabado";
                break;
            case "7":
                dia = "Domingo";
                break;
            default:
                dia = "h";
                break;
        }

        dia !== 'h' && array2.push(" " + dia);

        return array2;

    })
    return (array2.toString());

}

export default Dias;