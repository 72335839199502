
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import Tabla from "infortech_modules/Componentes/Tabla";
import { cambiarFormulario, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import DateTime from "infortech_modules/Constantes/Fecha";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Post from "infortech_modules/Crud/Post";
import { useState } from "react";
import ActividadesMantenimientoCilindros from "./ActividadesMantenimnientoCilindros";
import {GoTools} from "react-icons/go";
export default function MantenimientoCilindrosEnviar({ id, serial, propietario, producto, estado, ven }) {
    let texto;
    switch (estado) {
        case 1:
            estado = false;
            texto = "Enviar a Mantenimiento: Cilindro  - S/N " + serial;
            break;
        case 2:
            estado = true;
            texto = "No se puede enviar a mantenimiento, El equipo se encuentra en Ruta";
            break;
        case 3:
            estado = true;
            texto = "No se puede enviar a mantenimeinto, el equipo esta en un cliente";
            break;
        case 4:
            estado = true;
            texto = "El equipo esta en mantenimiento";
            break;
        case 5:
            estado = true;
            texto = "No se puede enviar a mantenimiento: El equipo esta en prueba de presión hidrostática (PH)"
            break;    
        default:
            break;
    }
    const [modal, setModal] = useState(false);
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [agregar, setAgregar] = useState({ idC: id, fecha: DateTime(), res: usuario().id, obs: "" });
    const [temporal, setTemporal] = useState([]);
    


    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'MantenimientoEnviar', data0: 'ADDCIL', id: id }).then((r) => {
            setTemporal(r[1]);          
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setError(true);
            setMensaje(f);
            tiempo(500, setCargar, false);
        })
    }

    const open = () => {
        setModal(true);
        principal();
    }

    const cerrar = () => {
        setModal(false);
        tiempo(500, setCargar, true, setError, false, setMensaje, "");
    }

    const guardar = () => {
        const promesa = new Promise((res, rej) => {
            Post({ url: '/post/general', data0: agregar, infoLog: JSON.stringify(agregar), caso: "mantenimientoCilindrosAdd", key: 'MantenimientoCilindrosAdd' }).then((r) => {
                window.location.href = '/cilindros/mantenimiento/' + r;
                tiempo(250, res, "Mantenimiento Creado")
            }).catch((f) => {
               tiempo(250, rej, f)
            })
        })

        AlertaPromesa('Creando Mantenimiento...', promesa, "a");
    }

    return (
        <>
            {
                !estado ?
                    <Boton tipo={'icono'} titulo={texto} posicionTexto={'left'} Icono={GoTools} onClick={() => open()} claseIcon={ven?"text-white pointer":undefined} /> :
                    <Boton tipo={'icono'} titulo={texto} posicionTexto={'left'} Icono={GoTools} claseIcon={'text-dark'}  />
            }
            &nbsp;
            {
               
            }

           

            <Modales
                titulo={'Enviar Cilindro con Serial '+ serial +' a Mantenimiento'}
                show={modal}
                onHide={cerrar}
                body={
                    <Contenedor autorizado={Permisos().mantenimientoEquiposAdd} altura={'55vh'}
                        cargar={cargar} mensajeCarga={'Cargando Información...'}
                        error={error} mensajeError={mensaje}
                        header={<>
                            <Tabla
                                encabezado={['Serial', 'Propietario', 'Producto']}
                                filas={<tr>{CreaarFila([serial, propietario, producto])}</tr>}
                            />
                            <Formularios datos={[[CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: 'Observaciones Generales' })]]}
                                onChange={(e) => { cambiarFormulario(e, agregar, setAgregar) }}
                            />
                            <ActividadesMantenimientoCilindros temporal={temporal} id={id} principal={principal} />
                        </>}
                    />
                }
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => cerrar()} />
                    <Boton tipo={'principal'} titulo={'Guardar'} onClick={() => guardar()} />
                </>}

            />
        </>
    )
};
