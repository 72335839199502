import { useState, useEffect } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import { determinarEstadoCilindros, tiempo } from 'infortech_modules/Constantes/Constantes';
import CrearInput from 'infortech_modules/Constantes/CrearInput';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';
import Permisos, { Autorizado, usuario } from 'infortech_modules/Constantes/Usuarios';
import Get from 'infortech_modules/Crud/Get';
import ModalAdd from 'infortech_modules/Modales/ModalAdd';
import DateTime, { FormatearFecha } from 'infortech_modules/Constantes/Fecha';
import OperacionCilindro from './OperacionCilindro';
import Formularios from 'infortech_modules/Componentes/Formularios';
import Modales from 'infortech_modules/Componentes/Modales';
import Tabla from 'infortech_modules/Componentes/Tabla';
import Boton from 'infortech_modules/Componentes/Boton';


export default function LlenadoCilindro({ iniciar }) {
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({ fecha: DateTime(), lote: "", tipoGas: "Medicinal", vencimiento: "", obs: "", usr: usuario().id })
    const [serialesCilindros, setSerialesCilindros] = useState([]);
    const [cil, setCil] = useState([]);
    const [mostrar, setMostrar] = useState(true);
    const [rta, setRta] = useState([]);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        principal()
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'CilindrosaLlenar' }).then((r) => {
            setData(r[0]);
            setCil(r[1]);
            tiempo(250, setCargar, false);
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }

    const grabarCilindros = (cil) => {
        console.log(cil.length);
        setSerialesCilindros(cil);
        if (cil.length > 0) {
            setMostrar(false);
        } else {
            setMostrar(true);
        }
    }

    const cambiar = (e) => {
        setAgregar({
            ...agregar,
            [e.target.name]: e.target.value
        });
        setAgregar((v) => {
            if (v.tipoGas === "Industrial") {
                v.lote = "";
                v.vencimiento = "0000-00-00";
            }
            return v;
        })
    }

    const actualizarCilindros = (valor, pos) => {
        setSerialesCilindros((v) => {
            v[pos] = valor;
            return v;
        })
    }

    const open = (valor) => {
        setRta(valor);
        setModal(true);
    }

    const closed = () => {
        setModal(false);
        principal(true);
    }

    return (
        <>
            <Contenedor titulo={'Operaciones de Llenado de Cilindro'} rutas={['Inicio', 'Cilindro', 'Llenado']} autorizado={Permisos().llenado} altura={'85vh'}
                cargar={cargar} mensajeCarga={''} error={error} mensajeError={mensaje}
                botonAdd={Autorizado([Permisos().llenadosAdd]) &&
                    <ModalAdd modalTitulo={'Llenar Cilindros'} tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Llenar Cilindros'} modalFullScrenn={true}
                        tituloBotonEnviar={'Llenar Cilindros'}
                        contenidoSuperiorModal={<>
                            <Formularios datos={[
                                [CrearInput({ type: 'select', name: 'tipoGas', value: agregar.tipoGas, placeholder: 'Tipo de Gas', options: [{ value: 'Medicinal', titulo: 'Medicinal' }, { value: 'Industrial', titulo: 'Industrial' }] })]
                            ]} onChange={cambiar} />
                            {
                                agregar.tipoGas === "Medicinal" &&
                                <Formularios datos={[
                                    [CrearInput({ type: 'text', name: 'lote', value: agregar.lote, placeholder: 'Codigo Lote' }),
                                    CrearInput({ type: 'date', name: 'vencimiento', value: agregar.vencimiento, placeholder: 'Vencimiento Lote' })
                                    ],
                                    [CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: 'Observaciones' })]
                                ]} onChange={cambiar} />
                            }

                            <hr />
                        </>}
                        contenidoInferiorModal={<OperacionCilindro tipo={'Llenar'} seriales={grabarCilindros} mostrar={mostrar}
                            imprimirSeriales={serialesCilindros} datosCilindros={cil} actulizarCilindros={actualizarCilindros}
                        />}
                        comprobarTextoVacio={[serialesCilindros.length > 0 ? "ok" : "", agregar.tipoGas === "Medicinal" ? agregar.lote : "ok", agregar.tipoGas === "Medicinal" ? agregar.vencimiento : "ok"]}
                        mensajeTextoVacio={['Valide al Menos un serial de cilindro', 'Digite el codigo del lote', 'Seleccione una fecha valida']}
                        mensajeLoadingPromesa={'Realizando Proceso de Llenado'} mensajePromesa={'Proceso Completado'}
                        url={'/post/general'} caso={'llenadoAdd'} key1={'CilindrosaLlenarAdd'} agregar={agregar}
                        datos1={serialesCilindros} respuestaExterna={open}


                    />
                }
                tablaEncabezado={['Movimiento No.', 'Fecha', 'Lote', 'Observaciones', 'Ver']}
                tablaNombre={'tcl'}
                tablaFilas={data.map((v, i) => {
                    return <tr key={i}>
                        {
                            CreaarFila([v.id, FormatearFecha(new Date(v.fecha), 3), v.lote, v.obs, <></>])
                        }
                    </tr>
                })}

            />
            <Modales
                show={modal}
                titulo={'Resultado del Proceso de Llenado'}
                body={
                    <>
                        <Tabla nombre={'tdrp'} encabezado={['Serial', 'Estado', 'Mensaje']}
                            filas={rta.map((v, i) => {
                                return <tr key={i}>
                                    {CreaarFila([v.serial, v.estado, v.error!==undefined?v.error:"Solo se pueden llenar cilindros en estado vacío y se encuentren en la Bodega. El estado actual de este cilindro es: "+determinarEstadoCilindros(v.rta) ], "",[""], v.estado === "Error"?"bg-danger text-white":"bg-success text-white")}
                                </tr>
                            })}
                        />
                    </>

                }
                footer={<Boton tipo={'secondary'} titulo={'Aceptar'} onClick={() => closed()} />}

            />

        </>
    )
};
