import { useState, useEffect } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import {  determinarEstadoPedidos,  tiempo } from 'infortech_modules/Constantes/Constantes';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';
import Permisos from 'infortech_modules/Constantes/Usuarios';
import Get from 'infortech_modules/Crud/Get';
import  { FormatearFecha,  TransfomarFecha, compararFechasMayor} from 'infortech_modules/Constantes/Fecha';
import  { NumerosCeros } from 'infortech_modules/Constantes/EsNumero';
import Alistamiento from './Alistamiento';


export default function Alistar({ iniciar }) {
   
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    //const [agregar, setAgregar] = useState({})
    //const [tiempoAlerta, setTiempoAlerta] = useState([]);
    //const [dirCliente, setDirCliente] = useState([]);      



    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get', key: '19' }).then((r) => {
            setData(r);
            
            tiempo(250, setCargar, false)
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }


    return (
        <>
           
            <Contenedor titulo={'Pedidos Pendientes por Alistar'} ruta={['Inicio','Operaciones', 'Alistar']} autorizado={Permisos().pedidosM} altura={'85vh'}
                cargar={cargar} mensajeCarga={''} error={error} mensajeError={mensaje}
               

                tablaEncabezado={['No. de Pedido','Fecha de Pedido', 'Fecha de Despacho', 'Cliente', 'Dirección', 'Estado', 'Acciones']}
                tablaNombre={'tpm'} tablaOrdenar={true} tablaOrdenNo={2} tablaTipoOrden={'desc'}
                tablaFilas={data.map((v, i) => {
                    let clase = compararFechasMayor(v.fdes) ? "bg-danger text-white fw-bold":"";
                    let color = compararFechasMayor(v.fdes) ? "text-white fw-bold fs-4 pointer":"fs-4 pointer text-purple-500"
                    return <tr key={i}>
                        {CreaarFila([v.area === "1"? "P"+ NumerosCeros(v.id,7):"G"+ NumerosCeros(v.id,7), FormatearFecha(new Date(v.fped),3), TransfomarFecha(v.fdes), v.cliente, <>{v.dir}  <div>{v.otro +" "+v.municipio + " ("+v.departamento+") "}</div></>, determinarEstadoPedidos(v.est), <>
                        {<Alistamiento
                        idPedido={v.id} 
                        color={color}
                        tipo={v.area}                   
                        />}
                        </>], "",[""], clase)}
                    </tr>
                })}

            />
        </>
    )
};
