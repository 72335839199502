import Contenedor from "infortech_modules/Componentes/Contenedor";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import { useEffect, useState } from "react";

export default function Residuales() {
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({nom:"", stock:0})
    useEffect(() => {
        principal();
    },[])

    const principal = (est) => {
        est && setCargar(true);
        Get({url:'/get', key:'12'}).then((r) => {
            setData(r);
            tiempo(250, setCargar, false)
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            tiempo(500, setCargar, false)
        })
    } 


    return (
        <>
        <Contenedor
        titulo={'Descartables'} rutas={['Incio', 'IPS', 'Descartables']} autorizado={Permisos().planes}
        cargar={cargar} mensajeCarga={'Obteniendo Información...'} altura={'85vh'}
        error={error} mensajeError={mensaje}
        botonAdd={
            Autorizado([Permisos().residuales]) && 
            <ModalAdd
            modalTitulo={'Agregar Descartable'} modalSize={'lg'}
            tituloBotonPrincipal={'Agregar'} tipoBotonPrincipal={'principal'}
            formulario={[
                [CrearInput({type:'text', name:'nom', value:agregar.nom, placeholder:'Nombre del Descartable'})]
            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
            url={'/post'} caso={'residualesAdd'} tabla={'residuales'} agregar={agregar} infoLog={JSON.stringify(agregar)}
            comprobarTextoVacio={[agregar.nom]} mensajeTextoVacio={['Escriba el nombre del descartable']}
            duplicados={duplicate(true,[agregar.nom],['nom'],['Error: Ya exister un descartable con este nombre'])}
            mensajeLoadingPromesa={'Agregando...'} mensajePromesa={'Agregado'} principal={principal}
            />
        }
        tablaEncabezado={['No.', 'Descartable','Acciones']} tablaNombre={'tpd'}
        tablaFilas={data.map((v,i) => {
            return (
                <tr key={i}>
                    {CreaarFila([i+1, v.nom, 
                    Autorizado([Permisos().residualesDel]) && 
                    <ModalDelete
                    modalTitulo={'Eliminar Descartable'} modalSize={'lg'} tituloBotonEnviar={'Si, Eliminar'}
                    mensajeEliminar={<>¿Está seguro de eliminar el descartable <strong>{v.nom}?</strong></>}
                    mensajeLoadingPromesa={'Eliminado Descartable...'} mensajePromesa={'Descartable Eliminado'} principal={principal}
                    url={'/del'} tabla={'residuales'} caso={'residualesDel'} condicionEliminar={`id = ${v.id}`}
                    consultarHistorial={datosConsultarHistorial(false,['planes_detalle_residuales'],['idResidual'],['Error: Este descartable tiene movimientos registrados'])}
                    
                    />
                    ])}
                </tr>
            )
        })}
        />
        </>
    )
};
