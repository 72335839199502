import { useState } from "react";
import Boton from "infortech_modules/Componentes/Boton";
import Modales from "infortech_modules/Componentes/Modales";
import { BiStreetView } from "react-icons/bi";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import Get from "infortech_modules/Crud/Get";
import { cambiarFormulario, tiempo } from "infortech_modules/Constantes/Constantes";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import CrearInput from "infortech_modules/Constantes/CrearInput";
//import ModalEdit from "infortech_modules/Modales/ModalEdit";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import { diasDir, escribirCiudadesDir } from "infortech_modules/Constantes/Dias";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { MdOutlineLocationDisabled } from "react-icons/md";
import { BsCheckAll } from "react-icons/bs";

export default function Direcciones({ id, iniciar, cliente, ruta, select, disabled, sizeIcon }) {
    disabled = disabled === undefined ? false : disabled
    sizeIcon = sizeIcon === undefined ? 23 : sizeIcon
    const edit = [];
    const dir = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [ciudad, setCiudad] = useState([]);
    const [agregar, setAgregar] = useState({ idCliente: id, dir: '', ciu: '0', otro: '', ruta: "0", obs: '' });
    //const [editar, setEditar] = useState({idCliente:'', dir: '', ciu: '', otro: '',ruta:"", obs: ''})
    //const [rutas, setRutas] = useState([]);

    



    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'clientesDir', id: id, data1: 1, data2: select === undefined ? undefined : "est" }).then((r) => {
            setData(r[0]);
            setCiudad(r[1]);
            setAgregar({...agregar, idCliente:id})
            //setRutas(ruta);

            tiempo(500, setCargar, false)
        }).catch((f) => {
            setMensaje(f);
            tiempo(500, setError, true, setCargar, false)
        })
    }

    const open = () => {
        setModal(true);
        principal();
    }

    const closed = () => {
        setModal(false);
        setError(false);
        tiempo(500, setCargar, true)
    }

    /* const inicializarDatos = (d) => {
         setEditar(d);
     }*/

    const crearObjeto = (array, city) => {

        const array2 = [];
        array.forEach(e => {

            e.ciudades.split(",").forEach(v => {
                const { id } = e;
                const dt = city.length > 0 ? escribirCiudadesDir(e.ciudades, city) : "0";
                const nom = e.ruta;
                const di = city.length > 0 ? diasDir(e.dias) : "0";

                array2.push({ id, dt, v, nom, di })
            })
        })
        return array2
    }


    // console.log(crearObjeto(rutas).filter(r => r.v === agregar.ciu));

    //console.log(agregar.ruta);
    const defRutas = crearObjeto(ruta, ciudad).filter(r => r.v.toString() === agregar.ciu.toString()).map((v, i) => {
        return { value: v.id, titulo: v.nom + " -> " + v.dt + "\n Días -> " + v.di }
    });

    const seleccionarDireccion = (id, dir, obs) => {
        dir = obs !== "" ?dir+" *** "+obs+" *** ":dir
        select(id, dir);
        setModal(false);

    }



    return (
        <>
            <Boton tipo={'icono'} Icono={BiStreetView} claseIcon={disabled ? 'text-secondary' : 'pointer text-success'} sizeIcon={sizeIcon}
                titulo={'Direcciones'} onClick={() => open()} disabled={disabled}

            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={cliente + ' - Direcciones'}
                body={<Contenedor
                    titulo={''}
                    cargar={cargar} mensajeCarga={'Estamos cargando las direcciones del cliente...'} classNameMensaje={'text-dark fw-bold fcom fs-6'}
                    autorizado={Permisos().clientesComercialDir} altura={'70vh'}
                    error={error} mensajeError={mensaje} claseNameTextError={'text-danger fw-bold fcom fs-4'}
                    botonAdd={
                        Autorizado([Permisos().clienteComercialDirAdd]) &&
                        <ModalAdd
                            modalTitulo={'Agregar Dirección'}
                            tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Agregar Dirección'}
                            formulario={[
                                [CrearInput({ type: 'text', name: 'dir', value: agregar.dir, placeholder: 'Dirección' })],
                                [CrearInput({ type: 'text', name: 'otro', value: agregar.otro, placeholder: 'Piso, Torre, Apto etc' })],
                                [CrearInput({
                                    type: 'select', name: 'ciu', value: agregar.ciu, placeholder: 'Ciudad', list: { value: '0', titulo: 'Seleccione una ciudad' },
                                    options: ciudad.map((v, i) => { return ({ value: v.id, titulo: v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ')' }) })
                                })],
                                [CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: 'Observaciones Adicionales' })],
                                [CrearInput({ type: 'select', name: 'ruta', value: agregar.ruta, placeholder: 'Rutas Disponibles', options: defRutas, list: { value: "0", titulo: "Seleccione Ruta disponibles" } })]
                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                            comprobarTextoVacio={[agregar.dir, agregar.ciu === "0" ? "" : agregar.ciu, agregar.ruta === '0' ? "" : agregar.ruta]} mensajeTextoVacio={["Escriba una dirección", "Seleccione la Ciudad", "Seleccione la Ruta"]}
                            mensajeLoadingPromesa={'Registrando Dirección'} mensajePromesa={'Dirección Registrada'}
                            principal={principal} tabla={'direccion_cliente'} caso={'clientesDirAdd'} url={'/post'} agregar={agregar} infoLog={JSON.stringify(agregar)}
                        />
                    }
                    tablaEncabezado={['No.', 'Dirección', 'Piso, Torre, Apto', 'Ciudad', 'Ruta', 'estado', 'Observaciones', 'Acciones']} claseTablaEncabezado={'text-center fcom'}
                    tablaNombre={'td'} tablaFilas={data.map((v, i) => {
                        edit[i] = { idCliente: v.idCliente, dir: v.dir, ciu: v.ciu, otro: v.otrod, obs: v.obs }
                        dir[i] = v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ')';

                        return (
                            <tr key={i}>
                                {CreaarFila([i + 1, v.dir, v.otrod, v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ')', v.ruta, v.est === 1 ? "Activa" : "Deshabilitada", v.obs], "scope", [""], "text-center fcom")}
                                <td className="text-center">
                                    {
                                        select === undefined &&
                                        Autorizado([Permisos().dirinh]) &&
                                        <ModalEdit
                                            IconoBotonPrincipal={MdOutlineLocationDisabled}
                                            claseIconBotonPrincipal={v.est === 1 ? 'text-primary fs-5 pointer' : 'text-secondary fs-5 pointer'}
                                            tituloBotonPrincipal={v.est === 1 ? 'Inhabilitar Dirección' : 'Habilitar Dirección'}
                                            inicializarDatos={() => { }}
                                            modalTitulo={v.est === 1 ? 'Inhabilitar Dirección' : 'Habilitar Dirección'}
                                            contenidoSuperiorModal={<>
                                                <div className="p-3 text-justify">
                                                    <div className="mb-3 text-center">{
                                                        v.est === 1 ? <>

                                                            ¿Está seguro de inhabiltiar la dirección <strong>{v.dir}</strong> de la ciudad <strong>{v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ') '} </strong>
                                                            del cliente <strong>{cliente}</strong>?
                                                        </>
                                                            : <>
                                                                Habilirar dirección <strong>{v.dir}</strong> de la ciudad <strong>{v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ') '} </strong>
                                                                del cliente <strong>{cliente}</strong>
                                                            </>
                                                    }
                                                    </div>
                                                    <div className="text-center italic">{v.est === 1 ? "Cuando inhabilite esta dirección, no la podrá visualizar al momento de realizar un pedido o despacho" : "Una vez habilitada, la dirección estara visible en el momento de realizar un pedido o despacho"}</div>
                                                </div>
                                            </>}
                                            tipoBotonEnviar={v.est === 1 ? 'danger' : 'principal'} tituloBotonEnviar={v.est === 1 ? 'Inhabilitar' : 'Habilitar'}
                                            editar={v.est === 1 ? { est: 0 } : { est: 1 }} url={'/put'} tabla={'direccion_cliente'} caso={v.est === 1 ? 'inhdr' : 'hdr'}
                                            id={`id = ${v.id}`} idLog={v.id} infoLog={v.est === 1 ? "Inhabilito" : "Habilito"}
                                            mensajeLoadingPromesa={v.est === 1 ? "Inhabilitando..." : "Habilitando..."} mensajePromesa={v.est === 1 ? "Inhabilitada" : "Habilitada"}
                                            principal={principal}
                                        />
                                    }


                                    {/*Autorizado([Permisos().clientesComercialDirEdit]) &&
                                        <ModalEdit
                                        modalTitulo={'Editar Dirección'}
                                            formulario={[
                                                [CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección' })],
                                                [CrearInput({ type: 'text', name: 'otro', value: editar.otro, placeholder: 'Piso, Torre, Apto etc' })],
                                                [CrearInput({
                                                    type: 'select', name: 'ciu', value: editar.ciu, placeholder: 'Ciudad', list: { value: '0', titulo: 'Seleccione una ciudad' },
                                                    options: ciudad.map((v, i) => { return ({ value: v.id, titulo: v.otro !== "" ? v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ')' }) })
                                                })],
                                                [CrearInput({ type: 'text', name: 'obs', value:editar.obs, placeholder: 'Observaciones Adicionales' })]
                                            ]}
                                            onChange={(e) => cambiarFormulario(e, editar, setEditar)} inicializarDatos={() => inicializarDatos(edit[i])}
                                            comprobarTextoVacio={[editar.dir, editar.ciu === "0" ? "" : editar.ciu]} mensajeTextoVacio={["Escriba una dirección", "Seleccione la Ciudad"]}
                                            mensajeLoadingPromesa={'Actualizando...'} mensajePromesa={'Actualizado'} editar={editar} id={`id = ${v.id}`} idLog={v.id} 
                                            infoLog={`${JSON.stringify(edit[i])} -> ${JSON.stringify(editar)}`}
                                            url={'/put'} tabla={'direccion_cliente'} caso={'clientesComercialDirEdit'} 
                                            principal={principal}
                                        />
                                        */}
                                    &nbsp; &nbsp;
                                    {
                                        select === undefined &&
                                        Autorizado([Permisos().clientesComercialDirDel]) &&
                                        <ModalDelete
                                            modalTitulo={'Eliminar Dirección'}
                                            condicionEliminar={`id = ${v.id}`}
                                            mensajeEliminar={<div className="p-3">¿Está seguro de eliminar la dirección <strong>{v.dir} {v.otrod}
                                                {v.otro !== "" ? ' ' + v.otro + ' - ' + v.municipio + ' (' + v.departamento + ')' : v.municipio + ' (' + v.departamento + ') '}</strong>
                                                del cliente <strong>{cliente}</strong>
                                            </div>}
                                            tituloBotonEnviar={'Si, Eliminar'}
                                            mensajeLoadingPromesa='Eliminando...' mensajePromesa={'Eliminado'} infoLog={JSON.stringify(edit[i])}
                                            tabla={'direccion_cliente'} caso='clientesComercialDel' principal={principal} valorEliminar={v.id}
                                            url={'/del'}
                                        />
                                    }
                                    &nbsp; &nbsp;
                                    {
                                        select !== undefined &&
                                        <Boton tipo={'icono'} Icono={BsCheckAll} posicionTexto={'left'} titulo={'Seleccionar Esta Dirección'}
                                        sizeIcon={30} onClick={() => seleccionarDireccion(v.id, v.dir + ' '+v.otrod + ' '+dir[i],v.obs ) }
                                         
                                        />
                                    }

                                </td>
                            </tr>
                        )
                    })}


                />}
            />

        </>
    )
};
