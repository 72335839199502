import Cerrar from "1Loguin/Cerrar";
import ClientesComercial from "2Access/0Clientes/ClientesComercial";
import ClientesMedicinal from "2Access/0Clientes/ClientesMedicinal";
import ListadoMantenimiento from "2Access/4Equipos/ListadoMantenimiento";
import RutasD from "2Access/3Rutas/RutasD";
import Calibracion from "2Access/4Equipos/Calibracion";
import Equipos from "2Access/4Equipos/Equipos";
import Mantenimientos from "2Access/4Equipos/Mantenimientos";
import Actividades from "2Access/7Configuraciones/Actividades";
import Ciudades from "2Access/7Configuraciones/Ciudades";
import Zonas from "2Access/7Configuraciones/Zonas";
import Familias from "2Access/8Familias/Familias";
import Productos from "2Access/8Familias/Productos";

import { Route, Routes} from "react-router-dom";
import ListadoCalibracion from "2Access/4Equipos/ListadoCalibracion";
import Cilindros from "2Access/1Cilindros/Cilindros/Cilindros";
import MantenimientoCilindros from "2Access/1Cilindros/Cilindros/MantenimientoCilindros";
import Eps from "2Access/6Ips/Eps";
import Pacientes from "2Access/6Ips/Pacientes";
import Planes from "2Access/6Ips/Planes";
import Residuales from "2Access/6Ips/Residuales";
import Pedidos from "2Access/6Ips/Pedidos/Pedidos";
import PedidosComercial from "2Access/5Pedidos/PedidosComercial";
import PedidosMedicinal from "2Access/5Pedidos/PedidosMedicinal";
import LlenadoCilindro from "2Access/1Cilindros/Llenado/LlenadoCilindro";
import Alistar from "2Access/9Operaciones/Despachos/Alistar";

export default function Main({ iniciar }) {
    return (
        <>
            <div>
                <div className='container-fluid main-contenedor'>
                    <div className='shadow-lg p-3 mb-3 bg-body rounded'>
                        <div style={{ minHeight: '90vh' }}>
                            <Routes>
                                <Route path="/operaciones/alistar" element={<Alistar iniciar={iniciar} />}/>
                                <Route path="/clientes/comercial" element={<ClientesComercial iniciar={iniciar} />}/>
                                <Route path="/clientes/medicinal" element={<ClientesMedicinal iniciar={iniciar} />}/>
                                <Route path="/rutas" element={<RutasD iniciar={iniciar} />}/>
                                <Route path="/configuraciones/ciudades" element={<Ciudades iniciar={iniciar} />} />
                                <Route path="/configuraciones/actividades" element={<Actividades iniciar={iniciar} />} />
                                <Route path="/configuraciones/zonas" element={<Zonas iniciar={iniciar} />} />
                                <Route path="/familias/familias" element={<Familias iniciar={iniciar} />} />
                                <Route path="/familias/productos" element={<Productos iniciar={iniciar} />} />
                                <Route path="/equipos/equipos" element={<Equipos iniciar={iniciar} />} />
                                <Route path="/cilindros/cilindros" element={<Cilindros iniciar={iniciar} />} />
                                <Route path="/cilindros/llenado" element={<LlenadoCilindro iniciar={iniciar} />} />
                                <Route path="/cilindros/mantenimiento/:id" element={<MantenimientoCilindros iniciar={iniciar} />} />
                                <Route path="/equipos/mantenimiento/:id" element={<Mantenimientos iniciar={iniciar} />} />
                                <Route path="/equipos/listado/mantenimiento" element={<ListadoMantenimiento iniciar={iniciar} />} />
                                <Route path="/equipos/listado/calibracion" element={<ListadoCalibracion iniciar={iniciar} />} />
                                <Route path="/equipos/calibracion/:id" element={<Calibracion iniciar={iniciar} />} />
                                <Route path="/pedidos/comercial" element={<PedidosComercial iniciar={iniciar}/>} /> 
                                <Route path="/pedidos/medicinal" element={<PedidosMedicinal iniciar={iniciar}/>} /> 
                                <Route path="/ips/eps" element={<Eps />} />
                                <Route path="/ips/pacientes" element={<Pacientes />} />
                                <Route path="/ips/planes" element={<Planes />} />
                                <Route path="/ips/residuales" element={<Residuales />} />
                                <Route path="/ips/pedidos" element={<Pedidos />} />
                                <Route path="/salir" element={<Cerrar />} />
                            </Routes>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
