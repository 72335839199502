import Contenedor from "infortech_modules/Componentes/Contenedor";
import Boton from "infortech_modules/Componentes/Boton";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { cambiarFormulario, tiempo } from "infortech_modules/Constantes/Constantes";
import { useState } from "react";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Formularios from "infortech_modules/Componentes/Formularios";
import Tabla from "infortech_modules/Componentes/Tabla";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Modales from "infortech_modules/Componentes/Modales";
import { TbRulerMeasure } from "react-icons/tb";
import DateTime from "infortech_modules/Constantes/Fecha";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { buscarValor } from "infortech_modules/Constantes/Constantes";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Post from "infortech_modules/Crud/Post";

export default function PhEnviar({ id, serial, familia, subfamilia, producto, capacidad, unm, propietario, estado, proveedores, principal, ven }) {

    const [agregar, setAgregar] = useState({idCil:id, res:usuario().id, fenv:DateTime(), proveedor:"", certificado:"", obs:"", est:1, usrfin:0})
    const cargar = false;
    const error = false;
    const mensaje = '';
    const [modal, setModal] = useState(false);

    let texto;
    switch (estado) {
        case 1:
            estado = false;
            texto = "Enviar Cilindro a prueba de presión hidrostática (PH) - S/N " + serial;
            break;
        case 2:
            estado = true;
            texto = "No se puede enviar a prueba de presión hidrostática, El equipo se encuentra en Ruta";
            break;
        case 3:
            estado = true;
            texto = "No se puede enviar a prueba de presión hidrostática, el equipo esta en un cliente";
            break;
        case 4:
            estado = true;
            texto = "El cilindro esta en mantenimiento";
            break;
        case 5:
            estado = true;
            texto = "Este cilindro se encuentra en pruebas de presión hidrostática"
            break;    
        default:
            break;
    }


    const guardar = () => {       
        if(EsVacio([buscarValor(proveedores, agregar.proveedor)?"ok":""],['Debe seleccionar un dato de la lista'])){
            const promesa = new Promise((res, rej) => {
                Post({url:'/post/general', data0:agregar,  data1:id, caso:'cilindrosPhAdd', key:'PhEnviar'})
                .then((r) => {
                    setTimeout(() => {
                        res('Registrado');
                        principal !== undefined && principal(true);

                    }, 500);
                })
                .catch((f) =>{
                    tiempo(250, rej, f)
                })           
            })
            AlertaPromesa('Registrando...', promesa, 'a');
        }
    }

    const open = () => {
        setModal(true);
    }

    const cerrar = () => {
        setModal(false);
    }
    return (
        <>
        <datalist id='proveedores'>
           {
            proveedores.map((v,i) => {
                return <option key={i} value={v.id + ' - '+ v.doc + ' - '+v.nom + ' '+v.ape}/>
            })
           }
        </datalist>
            {
                !estado ?
                    <Boton tipo={'icono'} titulo={texto} posicionTexto={'left'} Icono={TbRulerMeasure} onClick={() => open()} claseIcon={ven?'text-white fs-5 pointer':'text-lime-800 fs-5 pointer'} /> :
                    <Boton tipo={'icono'} titulo={texto} posicionTexto={'left'} Icono={TbRulerMeasure} claseIcon={'text-dark'} />
            }
            &nbsp;
            {

            }



            <Modales
                titulo={'Enviar Cilindro a Prueba de presión hidrostática'}
                show={modal}
                onHide={cerrar}
                body={
                    <Contenedor autorizado={Permisos().mantenimientoEquiposAdd} altura={'55vh'}
                        cargar={cargar} mensajeCarga={'Cargando Información...'}
                        error={error} mensajeError={mensaje}
                        header={<>
                            <Tabla
                                encabezado={['Serial', 'Propiedad', 'Familia', 'Subfamilia', 'Producto', 'Capacidad']}
                                filas={<tr>{CreaarFila([serial, propietario, familia, subfamilia, producto,`${capacidad} ${unm}`])}</tr>}
                            />
                            <Formularios datos={[
                                [CrearInput({type: 'text', name:'proveedor', value:agregar.proveedor, list:'proveedores', placeholder:'Lugar Donde se realizara la prueba'})],
                                [CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: 'Observaciones Generales' })]
                            ]}
                                onChange={(e) => { cambiarFormulario(e, agregar, setAgregar) }}
                            />
                        </>}
                    />
                }
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => cerrar()} />
                    <Boton tipo={'principal'} titulo={'Guardar'} onClick={() => guardar()} />
                </>}

            />
        </>
    )
};
