import Contenedor from "infortech_modules/Componentes/Contenedor";
import { datosConsultarHistorial, determinarEstadoEps, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useEffect, useState } from "react";

export default function Eps({ iniciar }) {
    const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [data, setData] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [agregar, setAgregar] = useState({ eps: "", ciudad: "1085", departamento: "85", tel: "", dir: "", est: 1 })
    const [editar, setEditar] = useState({ eps: "", ciudad: "", departamento: "", tel: "", dir: "", est: "" });

    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'Eps' }).then((r) => {
            setData(r[0]);
            setDepartamentos(r[1]);
            setMunicipios(r[2]);
            tiempo(250, setCargar, false)
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }

    const cambiar = (e, dato, setDato) => {
        setDato({
            ...dato,
            [e.target.name]: e.target.value
        });
        setDato((v) => {
            if (dato.departamento !== v.departamento) {
                v.ciudad = municipios.filter(m => m.departamento_id === parseInt(v.departamento))[0].id_municipio;
            }
            return v;
        })
    }
    const inicializar = (d) => {
        setEditar(d);
    }



    return (
        <>
            <Contenedor titulo={'EPS'} rutas={['Incio', 'IPS', 'EPS']} autorizado={Permisos().eps} altura={'85vh'}
                cargar={cargar} mensajeCarga={'Obteniendo Datos de las EPS...'} error={error} mensajeError={mensaje}
                botonAdd={
                    Autorizado([Permisos().epsAdd]) &&
                    <ModalAdd
                        modalTitulo={'Registrar EPS en el Sistema'}
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Agregar EPS'}
                        formulario={[
                            [CrearInput({ type: 'text', name: 'eps', value: agregar.eps, placeholder: 'EPS' })],
                            [CrearInput({ type: 'select', name: 'departamento', value: agregar.departamento, placeholder: 'Departamento', options: departamentos.map((v, i) => { return ({ value: v.id_departamento, titulo: v.departamento }) }) })],
                            [CrearInput({ type: 'select', name: 'ciudad', value: agregar.ciudad, placeholder: 'Ciudad', options: municipios.filter(m => m.departamento_id === parseInt(agregar.departamento)).map((v, i) => { return ({ value: v.id_municipio, titulo: v.municipio }) }) })],
                            [CrearInput({ type: 'text', name: 'dir', value: agregar.dir, placeholder: 'Dirección' }), CrearInput({ type: 'text', name: 'tel', value: agregar.tel, placeholder: 'Teléfono' })]
                        ]} onChange={(e) => cambiar(e, agregar, setAgregar)}
                        comprobarTextoVacio={[agregar.eps, agregar.dir, agregar.tel]}
                        duplicados={duplicate(true, [agregar.eps], ['eps'], ['Error: Esta EPS ya ha sido registrada'])}
                        mensajeTextoVacio={['Escriba el nombre de la EPS', 'Escriba la Dirección', 'Escriba el número de teléfono']}
                        numeroComprobar={[agregar.tel]} mensajeNumeroComprobar={['El campo teléfono debe ser númerico']}
                        url={'/post'} caso={'epsAdd'} agregar={agregar} infoLog={JSON.stringify(agregar)} principal={principal}
                        mensajeLoadingPromesa={'Registrando EPS...'} mensajePromesa={'EPS Registrada'} tabla={'eps'}
                    />
                }
                tablaEncabezado={['EPS', 'Ubicación', 'Teléfono', 'Estado', 'Acciones']}
                tablaNombre={'teps'} tablaFilas={data.map((v, i) => {
                    datos[i] = { eps: v.eps, ciudad: v.ciudad, departamento: v.departamento, tel: v.tel, dir: v.dir, est: v.est }

                    return (
                        <tr key={i}>
                            {CreaarFila([v.eps, v.muni + ' ' + v.dep, v.tel, determinarEstadoEps(v.est), <>
                                {
                                    Autorizado([Permisos().epsEdit]) &&
                                    <ModalEdit
                                        modalTitulo={'Editar'} inicializarDatos={() => inicializar(datos[i])}
                                        formulario={[
                                            [CrearInput({ type: 'text', name: 'eps', value: editar.eps, placeholder: 'EPS' })],
                                            [CrearInput({ type: 'select', name: 'departamento', value: editar.departamento, placeholder: 'Departamento', options: departamentos.map((v, i) => { return ({ value: v.id_departamento, titulo: v.departamento }) }) })],
                                            [CrearInput({ type: 'select', name: 'ciudad', value: editar.ciudad, placeholder: 'Ciudad', options: municipios.filter(m => m.departamento_id === parseInt(editar.departamento)).map((v, i) => { return ({ value: v.id_municipio, titulo: v.municipio }) }) })],
                                            [CrearInput({ type: 'text', name: 'dir', value: editar.dir, placeholder: 'Dirección' }), CrearInput({ type: 'text', name: 'tel', value: editar.tel, placeholder: 'Teléfono' })]
                                        ]} onChange={(e) => cambiar(e, editar, setEditar) }
                                        comprobarTextoVacio={[editar.eps, editar.dir, editar.tel]}
                                        duplicados={duplicate(true, [editar.eps], ['eps'],['Error: EPS Ya esta registrada'], [`and id != '${v.id}'`])}
                                        mensajeTextoVacio={['Escriba el nombre de la EPS', 'Escriba la Dirección', 'Escriba el número de teléfono']}
                                        numeroComprobar={[editar.tel.toString()]} mensajeNumeroComprobar={['El campo teléfono debe ser númerico']}
                                        mensajeLoadingPromesa={'Actualizando Información....'} mensajePromesa={'Información Actualizada'}
                                        infoLog={`${JSON.stringify(datos[i])}->${JSON.stringify(editar)}`} idLog={v.id} id={`id = ${v.id}`} 
                                        url={'/put'} caso={'epsEdit'} principal={principal} tabla={'eps'} editar={editar}
                                    />

                                }
                                {
                                    Autorizado([Permisos().epsDel]) && <>
                                        &nbsp; &nbsp;
                                        <ModalDelete
                                        modalTitulo={'Eliminar EPS'} modalSize={'lg'}              
                                        mensajeEliminar={<>¿Está seguro de eliminar la EPS <strong>{v.eps}</strong>?</>}
                                        tituloBotonEnviar={'Si, Eliminar'}
                                        mensajeLoadingPromesa={'Eliminado EPS...'} mensajePromesa={'EPS Elimianda'}
                                        url={'/del'} tabla={'eps'} caso={'epsDel'}
                                        condicionEliminar={`id = ${v.id}`}
                                        consultarHistorial={datosConsultarHistorial(false, ['eps_pacientes'],['idEps'],['Esta EPS tiene pacientes asociados'])}
                                        principal={principal} valorEliminar={v.id} infoLog={JSON.stringify(datos[i])}
                                        />
                                        
                                    </>
                                }
                            </>], "scope", [""], "text-left")}
                        </tr>
                    )
                })}

            />
        </>
    )
};
