import Contenedor from "infortech_modules/Componentes/Contenedor";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import { useState, useEffect } from "react"
import VerEquipos from "./VerEquipos";

export default function Equipos({ iniciar }) {
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({ nom: '', mar: '', modl: '' })
    const [editar, setEditar] = useState({ nom: '', mar: '', modl: '' })

    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'Equipos' }).then((r) => {
            setData(r[0]);
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(500, setCargar, false)
        })
    }


    return (
        <>
            <Contenedor rutas={['Inicio', 'Equipos', 'Equipos']} autorizado={Permisos().equipos} altura={'85vh'}
            titulo={'Equipos'}

                cargar={cargar} mensajeCarga={'Cargando datos...'}
                error={error} mensajeError={mensaje}
                botonAdd={<>
                    {
                        Autorizado([Permisos().equiposAdd]) &&
                        <ModalAdd
                            tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nuevo Equipo'} modalTitulo={'Crear Nuevo Equipo'}
                            formulario={[
                                [CrearInput({ type: 'text', name: 'mar', value: agregar.mar, placeholder: 'Marca' })],
                                [CrearInput({ type: 'text', name: 'modl', value: agregar.modl, placeholder: 'Modelo' })],
                                [CrearInput({ type: 'text', name: 'nom', value: agregar.nom, placeholder: 'Descripción' })],
                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                            comprobarTextoVacio={[agregar.mar, agregar.modl, agregar.nom]}
                            mensajeTextoVacio={['Digite la marca del equipo', 'Escriba un modelo para el equipo', 'Debe escribir la descripción del equipo']}
                            mensajeLoadingPromesa={'Agregando...'} mensajePromesa={'Agregado'} agregar={agregar}
                            duplicados={duplicate(true, [agregar.nom], ['nom'], ['Ya existe una equipo con estas caracteristicas'], [` and mar = '${agregar.mar}' and modl = '${agregar.modl}'`])}
                            tabla={'equipos'} caso={'equiposAdd'} url={'/post'} principal={principal} infoLog={JSON.stringify(agregar)}
                        />
                    }
                </>}
                tablaEncabezado={['Marca', 'Modelo', 'Descripción', 'Cant', 'Disp', 'Acciones']} tablaNombre={'te'}
                tablaFilas={data.map((v, i) => {
                    return (
                        <tr key={i}>
                            {
                                CreaarFila([v.mar, v.modl, v.nom, <VerEquipos id={v.id} marca={v.mar} modelo={v.modl} descripcion={v.nom} cant={v.cant} />, <VerEquipos id={v.id} marca={v.mar} modelo={v.modl} descripcion={v.nom} cant={v.disp} disp={true} />], "scope", [""], "")
                            }

                            <td className="text-center">
                                {
                                    Autorizado([Permisos().equiposEdit]) &&
                                    <ModalEdit
                                        modalTitulo={'Editar Equipo'}
                                        formulario={[
                                            [CrearInput({ type: 'text', name: 'mar', value: editar.mar, placeholder: 'Marca' })],
                                            [CrearInput({ type: 'text', name: 'modl', value: editar.modl, placeholder: 'Modelo' })],
                                            [CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: 'Descripción' })],
                                        ]} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                                        inicializarDatos={() => setEditar({ mar: v.mar, modl: v.modl, nom: v.nom })}
                                        mensajeLoadingPromesa={'Editando...'} mensajePromesa={'Editado'}
                                        comprobarTextoVacio={[editar.mar, editar.modl, editar.nom]}
                                        mensajeTextoVacio={['Digite la marca del equipo', 'Escriba un modelo para el equipo', 'Debe escribir la descripción del equipo']}
                                        duplicados={duplicate(true, [editar.nom], ['nom'], ['Ya existe una equipo con estas caracteristicas'], [` and mar = '${agregar.mar}' and modl = '${agregar.modl}' and id != '${v.id}'`])}
                                        caso={'equiposEdit'} tabla={'equipos'} principal={principal} url={'/put'} idLog={v.id} id={`id = ${v.id}`} infoLog={JSON.stringify({ mar: v.mar, modl: v.modl, nom: v.nom })+'>'+JSON.stringify({editar}) }
                                        editar={editar} 
                                    />
                                }
                                &nbsp;
                                {
                                    Autorizado([Permisos().equiposDel]) &&
                                    <ModalDelete
                                    modalTitulo={'Eliminar Equipo'}
                                    mensajeEliminar={<>¿Está seguro de eliminar el equipo de marca <strong>{v.mar}</strong>, modelo <strong>{v.modl}</strong> - <strong>{v.nom}</strong>?
                                    </>}
                                    condicionEliminar={`id = ${v.id}`} valorEliminar={v.id} infoLog={JSON.stringify({ mar: v.mar, modl: v.modl, nom: v.nom })}
                                    consultarHistorial={datosConsultarHistorial(false,['equipos_serial'],['idE'],['Error: "Este equipo tiene seriales registrados"'])}
                                    caso={'equiposDel'} url={'/del'} tabla={'equipos'} principal={principal}
                                    mensajeLoadingPromesa={'Eliminando...'} mensajePromesa={'Eliminado'} 

                                    />
                                }
                            </td>
                        </tr>
                    )
                })}

            />

        </>
    )
};
