import { useState, useEffect } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import { buscarCilindro, buscarValor, calcularCantidad, cambiarFormulario,  determinarEstadoPedidos, duplicate, extraerCliente, tiempo } from 'infortech_modules/Constantes/Constantes';
import CrearInput from 'infortech_modules/Constantes/CrearInput';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';
import Permisos, { Autorizado, usuario } from 'infortech_modules/Constantes/Usuarios';
import Get from 'infortech_modules/Crud/Get';
import ModalAdd from 'infortech_modules/Modales/ModalAdd';
import DateTime, { FormatearFecha } from 'infortech_modules/Constantes/Fecha';
import Boton from 'infortech_modules/Componentes/Boton';
import EsVacio from 'infortech_modules/Constantes/EsVacio';
import { AlertaPromesa } from 'infortech_modules/Componentes/Alertas';
import { NumerosCeros } from 'infortech_modules/Constantes/EsNumero';
import Formularios from 'infortech_modules/Componentes/Formularios';
import Post from 'infortech_modules/Crud/Post';
import Tabla from 'infortech_modules/Componentes/Tabla';
import PedidosTemporal from './PedidosTemporal';
import Direcciones from '2Access/0Clientes/Direcciones';

export default function Pedidos({ id }) {
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({ idOrden: "", idPac: "", idDir:"", fped: DateTime(), fdes: "", obs: "", est: 1, codAutorizacion: 0, usrAutorizacion: 0 });
    const [disabledInput, setDisabledInput] = useState(true);
    const [cambio, setCambio] = useState(true);
    //const [btnSnp, setBtnSnp] = useState(false);
    const btnSnp = false;
    const [btnDis, setBtnDis] = useState(false);
    const [paciente, setPaciente] = useState({ idPac: "", doc: "", nom: "", ape: "", idOrden: "", idPlan: "", plan: "", vence: "", OEps: "" });
    const [productos, setProductos] = useState([]);
    //const [dirCliente, setDirCliente] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [btnModal, setBotonModal] = useState(false);
    const [temporal, setTemporal] = useState({ vid: "", des: "", cant: "", otro: "", usr: usuario().id, tipo: 'PedidosTemp', cliente: "", tabla: "" })
    //const [dosificacion, setDosificacion] = useState({ idOrden: "", idPedido: "", idProducto: "", lpm: "", horas: "" })
    const [dos, setDos] = useState([]);
    const [ingDos, setIngDos] = useState(false);
    const [btnTDis, setBTDis] = useState(true);
    const [datosTemporales, setDatosTemporales] = useState([]);
    const [cargarT, setCargarT] = useState(true);
    const [insumosCliente, setInsumosCliente] = useState([]);
    const [recogida, setRecogida] = useState({ idCliente: '', fecha: "", usr: usuario().id, dirCliente: "", obs: "", est: 1 })
    //const [dirRecogida, setDirRecogida] = useState("");
    const [rutas, setRutas] = useState([]);
    const [direccionEntrega, setDireccionEntrega] = useState("");



    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        Get({ url: '/get/general', key: 'Pedidos', data0: 'ips' }).then((r) => {
            setData(r[0]);
            setPacientes(r[1]);
            setRutas(r[2]);
            tiempo(250, setCargar, false)
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }

    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar);

    }

    const actualizarCliente = (est) => {
        est && setCargarT(true);
        Get({ url: '/get/general', key: 'BuscarPaciente', data0: agregar.idPac })
            .then((r) => {
                setDatosTemporales(r[4]);

                tiempo(250, setCargarT, false, setIngDos, false)
            })
    }

    const buscarCliente = () => {
        if (EsVacio([buscarValor(pacientes, agregar.idPac) ? "ok" : ""], ['Seleccione un Cliente de la Lista'])) {
            const promesa = new Promise((res, rej) => {
                Get({ url: '/get/general', key: 'BuscarPaciente', data0: agregar.idPac }).then((r) => {
                    const v = r[0];
                    setAgregar({
                        ...agregar,
                        idOrden: v.idOrden,
                    })
                    setPaciente(v);
                    //setDirCliente(r[1]);
                    setProductos(r[2]);
                    setDos(r[3]);
                    setDatosTemporales(r[4]);
                    setBTDis(false);
                    setIngDos(false);
                    setCargarT(false);
                    setTemporal({
                        ...temporal,
                        cliente: v.idPac
                    })
                    setInsumosCliente(r[5]);
                    setRecogida({
                        ...recogida,
                        idCliente: extraerCliente(agregar.idPac, 0)
                    })

                    tiempo(250, setCargar, false);
                    setCambio(false);
                    setDisabledInput(false); setBotonModal(true)
                    tiempo(250, res, 'Información del Paciente Cargada')
                })
                    .catch((f) => {
                        tiempo(250, rej, f)
                    })
            });
            AlertaPromesa('Buscando Información del Paciente', promesa, 'a')
        }
    }

    const cambiarDoc = () => {
        setCambio(true);
        setDisabledInput(true);
        setBtnDis(false);
        setBotonModal(false);
        setBTDis(true);
        setIngDos(false);
        setTemporal({
            ...temporal,
            vid: "0"
        })
    }

    const cambiarTemporal = (e) => {
        cambiarFormulario(e, temporal, setTemporal);
        setTemporal((v) => {
            v.tabla = extraerCliente(v.vid, 1);
            if (extraerCliente(v.vid, 1) === "Producto") {
                const s = buscarCilindro(dos, extraerCliente(v.vid));
                if (s) {
                    setIngDos(false);
                    v.des = "";
                    v.otro = "";
                } else {
                    setIngDos(true);

                }
            } else {
                setIngDos(false);
                v.des = "";
                v.otro = "";
            }
            v.cliente = paciente.idPac;

            return v;
        })
    }

    const agregarTemporal = () => {
        setBTDis(true);
        if (EsVacio([temporal.vid === "0" ? "" : "ok", temporal.cant, ingDos ? temporal.des : "ok", ingDos ? temporal.otro : "ok", calcularCantidad(productos, extraerCliente(temporal.vid), temporal.cant) ? "ok" : ""],
            ["Seleccione un Insumo para agregar al Pedido", "Seleccione la Cantidad", "Digite LPM", 'Digite las Horas', "Esta cantidad supera la cantidad maxima del insumo autorizado"])) {
            setCargarT(true);
            const data0 = { vid: extraerCliente(temporal.vid), des: temporal.des, cant: temporal.cant, otro: temporal.otro, usr: usuario().id, tipo: 'PedidosTemp', tabla: temporal.tabla, cliente: temporal.cliente }

            const promesa = new Promise((res, rej) => {
                Post({
                    url: '/post', table: 'temporal', caso: 'pedidosAdd', data0: data0, duplicate: duplicate(true, [data0.vid], ['vid'], ["Ya existe este producto en las líneas de pedido"],
                        [` and usr = '${usuario().id}' and tabla = '${data0.tabla}' and tipo = 'PedidosTemp'`]
                    )
                }).then((r) => {
                    actualizarCliente();
                    setTemporal({ vid: "", des: "", cant: "", otro: "", usr: usuario().id, tipo: 'PedidosTemp', tabla: "" });
                    tiempo(250, res, "Línea de Pedido Agregada", setBTDis, false, setCargarT, false)
                }).catch((f) => {
                    tiempo(250, rej, f, setBTDis, false, setCargarT, false)
                })
            })
            AlertaPromesa('Agregando Línea de Pedido', promesa, "a");

        } else {
            tiempo(250, setBTDis, false);
        }
    }



    const seleccionarDireccionEntrega = (id, dir) => {
        setAgregar({
            ...agregar,
            idDir:id
        })
        setDireccionEntrega(dir);
    }


    return (
        <>
            <datalist id='clientes'>
                {
                    pacientes.map((v, i) => {
                        return <option key={i} value={v.id + ' - ' + v.paciente + ' - ' + v.doc} />
                    })
                }
            </datalist>
            <Contenedor titulo={'Pedidos IPS'} ruta={[]} autorizado={Permisos().pedidosIps} altura={'85vh'}
                cargar={cargar} mensajeCarga={'Cargando Listado de Pedidos'} error={error} mensajeError={mensaje}
                botonAdd={Autorizado([Permisos().pedidosIPSAdd]) &&
                    <ModalAdd modalFullScrenn={true}
                        modalTitulo={'Crear Pedido'} tipoBotonPrincipal={'primary'} tituloBotonPrincipal={'Crear Pedido'}
                        disabledBotonEnviar={btnModal}
                        contenidoSuperiorModal={<>
                            <div className="row mb-3">
                                <div className="col-xl-6 col-lg-6 col-sm-8 col-9 mb-3">
                                    <div className="form-floating">
                                        <input type={'text'} name='idPac' value={agregar.idPac} placeholder={'Seleccionar Cliente'} list={'clientes'} className='form-control' onChange={cambiar} disabled={!disabledInput} />
                                        <label>Seleccionar Cliente</label>
                                    </div>

                                </div>
                                <div className="col-xl-2 col-lg-2 col-sm-4 col-3 mb-3">
                                    {
                                        cambio ?
                                            <Boton tipo={'primary'} titulo={'Elegir'} disabled={btnDis} SpinnerBoton={btnSnp} onClick={() => buscarCliente()} /> :
                                            <Boton tipo={'primary'} titulo={'Cambiar'} SpinnerBoton={btnSnp} onClick={() => cambiarDoc()} />
                                    }

                                </div>
                                <div className='col-xl-3 col-lg-3 col-sm-12 col-12'>
                                    <Formularios datos={[
                                        [CrearInput({ type: 'date', value: agregar.fdes, name: 'fdes', placeholder: 'Fecha Para Despachar' })]
                                    ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)} />
                                </div>

                                {
                                    !disabledInput &&
                                    <div className="table-responsive table-container">
                                        <table className="table font-sans">
                                            <thead>
                                                <tr>
                                                    <th>Cod Interno</th><td>{NumerosCeros(paciente.idOrden, 6)}</td>
                                                    <th>Orden EPS</th><td>{paciente.OEps}</td>
                                                    <th>Plan</th><td>{paciente.plan}</td>
                                                    <th>Vencimiento</th><td>{FormatearFecha(new Date(paciente.vence), 2)}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                }
                            </div>
                            <div className='row mb-3'>
                                <div className='col-10'>
                                    <Formularios datos={[
                                        [CrearInput({type:'text', value:direccionEntrega, placeholder:'Dirección de Entrega', disabled:true})]
                                    ]} />
                                </div>
                                <div className='col-2'>
                                    <Direcciones ruta={rutas} id={extraerCliente(agregar.idPac,0)} sizeIcon={35} cliente={extraerCliente(agregar.idPac,2)} 
                                    disabled={disabledInput} select={seleccionarDireccionEntrega}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-lg-6 col-xl-6 mb-3'>
                                    <div className='form-floating'>
                                        <select className='form-control' name='vid' value={temporal.vid} placeholder='Seleccione Insumo' onChange={cambiarTemporal} disabled={disabledInput} >
                                            <option value={"0"}>Seleccione Insumo</option>
                                            {
                                                productos.map((v, i) => {
                                                    return <option key={i} value={`${v.id} - ${v.tipo}`}>{v.tipo + ' - ' + v.nom}</option>
                                                })
                                            }

                                        </select>
                                        <label>Seleccione Insumo</label>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-lg-6 col-xl-6 mb-3'>
                                    <div className='row'>
                                        <div className={ingDos ? "col-4" : "col-12"}>
                                            <Formularios datos={[[CrearInput({ type: 'text', name: 'cant', value: temporal.cant, placeholder: 'Cantidad', disabled: disabledInput })]]}
                                                onChange={cambiarTemporal} />
                                        </div>
                                        {
                                            ingDos &&
                                            <div className='col-8'>
                                                <Formularios datos={[
                                                    [CrearInput({ type: 'text', name: 'des', value: temporal.des, placeholder: 'LPM', disabled: disabledInput, clase: 'col-6' }),
                                                    CrearInput({ type: 'text', name: 'otro', value: temporal.otro, placeholder: 'Horas', disabled: disabledInput, clase: 'col-6' })
                                                    ]
                                                ]} onChange={cambiarTemporal} />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className='text-end mb-3'>
                                <Boton tipo={'primary'} titulo={'Agregar'} disabled={btnTDis} onClick={() => agregarTemporal()} />
                            </div>
                            <div className=''>
                                <Formularios datos={[
                                    [CrearInput({type:'text', name:'obs', value:agregar.obs, placeholder:'Observaciones'})]
                                ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)} />
                            </div>
                            <div>
                                <hr />
                                <div>
                                    {
                                        !disabledInput &&
                                        <PedidosTemporal cargar={cargarT} datosTemporales={datosTemporales} dos={dos} actualizar={actualizarCliente} />
                                    }
                                </div>

                                <div className=''>
                                    {
                                        !disabledInput &&
                                        insumosCliente.length > 0 &&
                                        <>
                                            <hr></hr>
                                            <div className='fcom fw-bold h4 text-center'>Cilindros o Equipos en Cliente</div>
                                            <Tabla encabezado={['Cilindro o Equipo', 'Serial', 'Esta en Cliente desde', 'Pedido']}
                                                nombre={'tdec'}
                                                filas={insumosCliente.filter(i => i.nomP !== extraerCliente(agregar.idPac, 0)).map((v, i) => {
                                                    return <tr key={i}>
                                                        {CreaarFila([v.equipo, v.ser, FormatearFecha(new Date(v.fecha), 2), NumerosCeros(v.idPedido, 6)])}
                                                    </tr>
                                                })}
                                            />
                                          
                                        </>
                                    }
                                </div>
                            </div>
                        </>}
                        comprobarTextoVacio={[agregar.fdes, agregar.idDir]} mensajeTextoVacio={['Seleccione una fecha valida', 'Seleccione la dirección de despacho']}
                        url={'/post/general'} key1={'PedidosIpsAdd'} caso={'pedidosIpsAdd'} datos1={'PedidosTemp'} agregar={agregar}
                        mensajeLoadingPromesa={'Creando Pedido...'} mensajePromesa={""}
                    />
                }
                tablaEncabezado={['No. de Pedido','Fecha de Pedido', 'Cliente', 'Dirección', 'Estado', 'Acciones']}
                tablaNombre={'tpm'}
                tablaFilas={data.map((v, i) => {
                    return <tr key={i}>
                        {CreaarFila([NumerosCeros(v.id,7), FormatearFecha(new Date(v.fped),3),  v.paciente, v.dir + ' - ' + v.ciudad, determinarEstadoPedidos(v.est), <></>])}
                    </tr>
                })}
            />
            
        </>
    )
};
