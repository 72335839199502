import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import { cambiarFormulario, datosConsultarHistorial, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Post from "infortech_modules/Crud/Post";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import { useEffect, useState } from "react";
import PEquipos from "./Planes/PEquipos";
import PProductos from "./Planes/PProductos";
import PResiduales from "./Planes/PResiudales";
import { AiFillEye } from "react-icons/ai";

export default function Planes() {

    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [agregar, setAgregar] = useState({ plan: "", est: 1 })
    const [productos, setProductos] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [residuales, setResiduales] = useState([]);
    const [temPro, setTemPro] = useState([]);
    const [temEqu, setTemEqu] = useState([]);
    const [temRes, setTemRes] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [cargar1, setCargar1] = useState(true);
    const [cargar2, setCargar2] = useState(true);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [mensaje1, setMensaje1] = useState('');
    const [mensaje2, setMensaje2] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [temporal, setTemporal] = useState({ titulo: "" })
    const [detalles, setDetalles] = useState([]);
    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'Planes', data1: 'planes' }).then((r) => {
            setData(r[0]);
            setProductos(r[1]);
            setEquipos(r[2]);
            setResiduales(r[3]);
            tiempo(250, setCargar, false)
        }).catch((f) => {
            setError(true);
            setMensaje(f)
            tiempo(500, setCargar, false)
        })
    }

    const open = (est) => {
        setModal(true);
        est && setCargar1(true);
        Get({ url: '/get/general', key: 'Planes', data1: 'productos' }).then((r) => {
            setTemPro(r[0])
            setTemEqu(r[1]);
            setTemRes(r[2]);
            tiempo(250, setCargar1, false)
        }).catch((f) => {
            setError1(true);
            setMensaje1(f)
            tiempo(500, setCargar1, false)
        })

    }

    const open2 = (d, id, est) => {
        est = est === undefined ? false : est;
        setTemporal(d);
        setModal2(true);
        est && setCargar2(true);
        Get({ url: '/get/general', key: 'Planes', data1: 'DetallePlan', data2: id }).then((r) => {
            setDetalles(r);
            tiempo(250, setCargar2, false)
        }).catch((f) => {
            setError2(true);
            setMensaje2(f)
            tiempo(500, setCargar2, false)
        })
    }

    const closed = () => {
        setModal(false);
        tiempo(500, setCargar1, true, setError1, false, setMensaje1, "");
    }

    const closed2 = () => {
        setModal2(false);
        tiempo(300, setCargar2, true, setError2, false, setMensaje2, "");
    }

    const guardar = () => {
        setDisabled(true);
        if (EsVacio([agregar.plan], ['Escriba un nombre para el Plan'])) {
            const promesa = new Promise((res, rej) => {
                Post({ url: '/post/general', key: 'PlanesAdd', caso: 'planesAdd', data0: agregar, })
                    .then((r) => {
                        setAgregar({ plan: "", est: 1 });
                        tiempo(250, res, 'Plan Creado', setDisabled, false);
                        principal(true);
                        setModal(false);
                    }).catch((f) => {
                        tiempo(250, rej, f, setDisabled, false)
                    })
            })
            AlertaPromesa('Creando Plan...', promesa, 'e')
        } else {
            setDisabled(false);
        }
    }



    return (
        <>
            <Contenedor
                titulo={'Planes'} rutas={['Incio', 'IPS', 'Planes']} autorizado={Permisos().planes}
                cargar={cargar} mensajeCarga={'Obteniendo Información...'} altura={'85vh'}
                error={error} mensajeError={mensaje}
                tablaEncabezado={['Ítem', 'Nombre del Plan', 'Acciones']} tablaNombre={'tpp'}
                botonAdd={Autorizado([Permisos().planesAdd]) &&
                    <Boton tipo={'principal'} titulo={'Crear Plan'} onClick={() => open()} />
                }
                tablaFilas={data.map((v, i) => {
                    return <tr key={i}>
                        {CreaarFila([i + 1, v.plan, <div className="d-flex">
                            <Boton tipo={'icono'} Icono={AiFillEye} titulo={'Ver Detalles Plan ' + v.plan} posicionTexto={'left'} onClick={() => open2({ titulo: v.plan }, v.id)} />
                            &nbsp; &nbsp;
                            {Autorizado([Permisos().planesDel]) &&

                                <ModalDelete
                                    modalTitulo={'Eliminar Plan ' + v.plan}
                                    tituloBotonPrincipal={'Eliminar Plan ' + v.plan} posicionTextoBotonPrincipal={'left'}
                                    size={'lg'}
                                    mensajeEliminar={<>Va ha proceder a eliminar el Plan <strong>{v.plan}</strong>, ¿Está seguro de continuar?</>}
                                    tituloBotonEnviar={'Si, Eliminar'}
                                    tabla={'planes'} caso={'planesDel'} infoLog={JSON.stringify({ id: v.id, plan: v.plan })} url={'/del/general'} key1={'planesDel'}
                                    condicionEliminar={`id = ${v.id}`} consultarHistorial={datosConsultarHistorial(false, ['pacientes_ordenes'], ['idPlan'], ['Error: Este plan ya ha sido asignado a un Paciente'])}
                                    mensajeLoadingPromesa={'Eliminando Plan...'} mensajePromesa={'Plan Eliminado'} principal={principal} valorEliminar={v.id}
                                />

                            }

                        </div>])}
                    </tr>
                })}
            />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Crear Plan'}
                size={'xl'}
                fullscreen={true}
                body={<>
                    <Contenedor autorizado={Permisos().planesAdd} cargar={cargar1} error={error1} mensajeCarga={'Cargando...'} mensajeError={mensaje1} altura={'55vh'}
                        header={
                            <>
                                <Formularios datos={[[CrearInput({ type: 'text', name: 'plan', value: agregar.plan, placeholder: 'Nombre del Plan' })]]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)} />
                                <PProductos productos={productos} temporal={temPro} open={open} />
                                <PEquipos productos={equipos} temporal={temEqu} open={open} />
                                <PResiduales productos={residuales} temporal={temRes} open={open} />
                            </>}
                    />
                </>}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />
                    <Boton tipo={'primary'} titulo={'Crear'} onClick={() => guardar()} disabled={disabled} />
                </>}
            />
            <Modales
                show={modal2}
                onHide={closed2}
                size={'xl'}
                titulo={'Detalles del Plan ' + temporal.titulo}
                body={<Contenedor autorizado={Permisos().planes} altura={'55vh'}
                    cargar={cargar2} mensajeCarga={'Cargando Detalles...'}
                    mensajeError={mensaje2} error={error2}
                    tablaEncabezado={['Tipo', 'Producto, Equipo o Descartable', 'Cantidad']}
                    tablaNombre={'tdplan'}
                    tablaFilas={detalles.map((v, i) => {
                        return <tr key={i}>
                            {CreaarFila([v.tipo, v.nom, v.cant])}
                        </tr>
                    })}
                />}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed2()} />}
            />
        </>
    )
};
