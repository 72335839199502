import { useEffect, useState } from "react";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Get from "infortech_modules/Crud/Get";
import { cambiarFormulario, datosConsultarHistorial, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import DateTime from "infortech_modules/Constantes/Fecha";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { NumerosCeros } from "infortech_modules/Constantes/EsNumero";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import ModalDelete from "infortech_modules/Modales/ModalDelete";

export default function Productos({ iniciar }) {
    const dat = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [fam, setFam] = useState([]);   
    const [agregar, setAgregar] = useState({ fam: "0",  nom: "", unm: "0", cap: "" });
    const [editar, setEditar] = useState({ fam: "",  nom: "", unm: "", cap: "" });
    const [norma, setNorma] = useState({ idP: "", norma: "", fin: DateTime(), est: 1 });
    const [normaE, setNormaE] = useState({ idP: "", norma: "", fin: DateTime(), est: 1 });
    const [siNor, setSiNor] = useState({ s: false });
    const [siNorE, setSiNorE] = useState({ s: false });
    const [unm, setUnm] = useState([]);

    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'Productos' }).then((r) => {
            setData(r[0]);
            setFam(r[1]);           
            setUnm(r[3]);
            tiempo(500, setCargar, false);
        }).catch((f) => {
            setMensaje(f);
            setError(true);
            tiempo(500, setCargar, false)
        })
    }

    const cambiar = (e) => {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        setSiNor({
            ...siNor,
            [n]: v
        })
    }
    const cambiarE = (e) => {
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        setSiNorE({
            ...siNorE,
            [n]: v
        })
    }

    const inicializarDatos = (d, i) => {
        setSiNorE({
            ...siNorE,
            s:false
        });
        setEditar(d);
        i = i === "N.A."?"":i
        setNormaE({
            ...normaE,
            norma: i
        })
    }
    const definir = (d) => {
       if(normaE.norma === ""){
        return false;
       }else  if(normaE.norma === d){
        return false;
       }else{
        if(normaE.norma !== d){
            return (true)
        }
       }
    }
 
    return (
        <>
            <Contenedor autorizado={Permisos().productos} rutas={['Inicio', 'Familias', 'Productos']}
                cargar={cargar} classNameMensaje={'text-center p-3 fcom fs-6 text-dark'} altura={'80vh'} mensajeCarga={'Cargando...'}
                error={error} claseNameTextError={'text-center p-3 fcom fs-5 text-danger'} mensajeError={mensaje}
                titulo={'Prodcutos'}
                botonAdd={Autorizado([Permisos().prodcutosAdd]) &&
                    <ModalAdd
                        modalTitulo={'Nuevo Producto'}
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Nuevo Producto'}
                        formulario={[
                            [CrearInput({
                                type: 'select', name: 'fam', value: agregar.fam, placeholder: 'Seleccione ', list: { value: "0", titulo: "Seleccione Familia del Producto" }, options: fam.map((v, i) => {
                                    return ({ value: v.id, titulo: v.familia })
                                })
                            })],
                          
                            [CrearInput({ type: 'text', name: 'nom', value: agregar.nom, placeholder: 'Descripción Producto' })],
                            [CrearInput({ type: 'text', name: 'cap', value: agregar.cap, placeholder: 'Capacidad' }),
                            CrearInput({ type: 'select', name: 'unm', value: agregar.unm, placeholder: 'Unidad de Medida', list:{value:"0", titulo:'Seleccione Unidad de Medida'}, options:unm.map((v,i) => {
                                return (
                                    {value:v.sigla, titulo:v.nom}
                                )
                            })})
                            ],
                        ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                        contenidoInferiorModal={<>
                            <small>
                                <div className="form-check mb-3">
                                    <input type={'checkbox'} checked={siNor.s} name={'s'} onChange={cambiar} className='form-check-input' />
                                    <label className="form-check-label">Incluye IUM</label>

                                </div>
                            </small>
                            {
                                siNor.s &&
                                <>
                                    <small>
                                        <div className="form-floating">
                                            <input type={'text'} name='norma' value={norma.norma} onChange={(e) => cambiarFormulario(e, norma, setNorma)} className={'form-control'} placeholder='Escriba el IUM' />
                                            <label>Escriba el IUM</label>
                                        </div>
                                    </small>
                                </>

                            }
                        </>}
                        comprobarTextoVacio={[agregar.fam === "0" ? "" : "ok",  agregar.nom, agregar.cap, agregar.unm === "0"?"":agregar.unm, siNor.s ? norma.norma : "ok"]}
                        mensajeTextoVacio={['Seleccione la familia del producto',  'Escriba un nombre para el producto', 'Escriba la Capacidad del Producto', 'Digite la unidad de medida para el producto', 'Debe digitar el IUM para el producto']}
                        mensajeLoadingPromesa={'Agreegando Producto...'} mensajePromesa={'Producto Agregado'} principal={principal} iniciar={iniciar}
                        url={'/post/general'} caso={'productosAdd'} agregar={agregar} infoLog={JSON.stringify(agregar)} key1={'ProductosAdd'}
                        datos1={siNor.s} datos2={norma}
                        duplicados={duplicate(true, [agregar.nom], ['nom'], ['Ya ha sido agregado un producto con estas caracteristicas'], [` and fam = '${agregar.fam}'  and cap = '${agregar.cap}' and unm = '${agregar.unm}'`])}
                    />
                }
                tablaEncabezado={['Código', 'Familia',  'Producto', 'Und. de Med.', ' Capacidad', 'IMU', 'Acciones']}
                tablaNombre={'tpn'}
                tablaFilas={data.map((v, i) => {
                    dat[i] = { fam: v.fam, nom: v.nom, unm: v.unm, cap: v.cap }
                    return (
                        <tr key={i}>
                            {
                                CreaarFila([v.nomF + '-' + NumerosCeros(v.id, 3), v.familia,  v.nom, v.unm, v.cap, v.imu])
                            }
                            <td className="text-center">
                                {
                                    Autorizado([Permisos().productosEdit]) &&
                                    <ModalEdit
                                        modalTitulo={'Editar Producto'}
                                        inicializarDatos={() => inicializarDatos(dat[i], v.imu)}
                                        formulario={[
                                            [CrearInput({
                                                type: 'select', name: 'fam', value: editar.fam, placeholder: 'Seleccione ', list: { value: "0", titulo: "Seleccione Familia del Producto" }, options: fam.map((v, i) => {
                                                    return ({ value: v.id, titulo: v.familia })
                                                })
                                            })],
                                            
                                            [CrearInput({ type: 'text', name: 'nom', value: editar.nom, placeholder: 'Descripción Producto' })],
                                            [CrearInput({ type: 'text', name: 'cap', value: editar.cap, placeholder: 'Capacidad' }),
                                            CrearInput({ type: 'text', name: 'unm', value: editar.unm, placeholder: 'Unidad de Medida' })
                                            ],
                                        ]} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                                        contenidoInferiorModal={<>
                                            <small>
                                                <div className="form-check mb-3">
                                                    <input type={'checkbox'} checked={siNorE.s} name={'s'} onChange={cambiarE} className='form-check-input' />
                                                    <label className="form-check-label">Modificar  IUM</label>

                                                </div>
                                            </small>
                                            <>
                                                <small>
                                                    <div className="form-floating">
                                                        <input type={'text'} name='norma' value={normaE.norma} onChange={(e) => cambiarFormulario(e, normaE, setNormaE)} className={'form-control'} placeholder='Escriba el IUM' disabled={!siNorE.s} />
                                                        <label>Escriba el IUM</label>
                                                    </div>
                                                </small>
                                            </>
                                        </>}
                                        mensajeLoadingPromesa={'Actualizando...'} mensajePromesa={'Actualizado'}
                                        comprobarTextoVacio={[editar.fam === "0" ? "" : "ok", editar.nom, editar.cap, editar.unm, siNorE.s ? normaE.norma : "ok"]}
                                        mensajeTextoVacio={['Seleccione la familia del producto', 'Escriba un nombre para el producto', 'Escriba la Capacidad del Producto', 'Digite la unidad de medida para el producto', 'Debe digitar el IUM para el producto']}
                                        tabla={'prodcutos'} caso={'productosEdit'} key1={'productosEdit'} url={'/put/general'} principal={principal}
                                        editar={editar} datos1={definir(v.imu)} datos2={normaE} id={`id = ${v.id}`} idLog={v.id} infoLog={JSON.stringify(dat[i]) +'->'+JSON.stringify(editar)}
                                        duplicados={duplicate(true, [agregar.nom], ['nom'], ['Ya ha sido agregado un producto con estas caracteristicas'], [` and fam = '${agregar.fam}' and cap = '${agregar.cap}' and unm = '${agregar.unm}' and id != ${v.id}`])}
                                        datos3={{est:0, ffi:DateTime()}}
                                    />
                                }
                                &nbsp;
                                {
                                    Autorizado([Permisos().productosDel]) && 
                                    <ModalDelete
                                    modalTitulo={'Eliminar Producto'}
                                    mensajeEliminar={<>
                                    ¿Está seguro de eliminar el producto <strong>{v.nom}</strong> de la familia <strong>{v.familia}</strong>?
                                    </>}
                                    mensajeLoadingPromesa={'Eliminando Producto...'} mensajePromesa={'Producto Eliminado'}
                                    condicionEliminar={`id = ${v.id}`} valorEliminar={v.id} 
                                    consultarHistorial={datosConsultarHistorial(false,['productos_norma'],['idP'],['El producto tiene un registro IUM asignado'])}
                                    url={'/del'} caso={'productosDel'} tabla={'productos'} infoLog={JSON.stringify(dat[i])} principal={principal}
                                    />
                                        
                                    
                                }
                            </td>
                        </tr>
                    )
                })}

            />
        </>
    )
};
