import axios from "axios";
import { upload } from "infortech_modules/Constantes/Constantes";
import { Lectura } from "infortech_modules/Constantes/Lectura";

const Upload = (name, file, ruta) => {
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.ingreso
    }
        

    const form = new FormData();
    form.append(name, file);
    form.append('name', Date.now().toString());


    return new Promise((res, rej) => {
        axios.post(upload + ruta,
            form,
            {
                headers: header,
                timeout: 25000
            }

        ).then((r) => {                
            if (r.data.res) {
                res(r.data.msje)
            } else {
                rej(Lectura(r.data.msje))
            }
        })
            .catch((f) => {                
                rej(Lectura(""+f))
            })

    });
}

export default Upload;