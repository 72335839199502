import Contenedor from "infortech_modules/Componentes/Contenedor";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import Permisos from "infortech_modules/Constantes/Usuarios";
import Put from "infortech_modules/Crud/Put";
import { useEffect, useState } from "react";

export default function Cerrar({iniciar}) {
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState("");
    useEffect(() => {
        principal();
    },[])
    const principal = () => {
        Put({url:'/salir', data0:'web'})
        .then((r) => {
            localStorage.clear();
            setTimeout(() => {
                window.location.href='/'
            }, 500);

        })
        .catch((f) => {
            setMensaje(f);
            setError(true)
            tiempo(250, setCargar, false)            
        })
    }
    return (
        <>
        <Contenedor autorizado={Permisos().salir}
        altura={'85vh'} mensajeCarga={'Cerrando Sesión'}
        cargar={cargar}
        error={error} mensajeError={mensaje}


        />
        </>
    )
};
