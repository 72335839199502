import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import Layout from "0Plantilla/Layout";



export default function Contenido({ iniciar }) {

    return (
        <>
            <BrowserRouter>
                <ProSidebarProvider>
                    <Layout iniciar={iniciar} />
                </ProSidebarProvider>              
            </BrowserRouter>

        </>
    );
};
