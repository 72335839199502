import jwtDecode from "jwt-decode"

export const usuario = () => {
    if (localStorage.sicma) {      
        return jwtDecode(localStorage.getItem('sicma'))
    } else {
        return []
    }

}

export default function Permisos() {
    return {
      dashboard:0,
      clientesComercial:0, 
      clientesComercialDir:0,   
      clientesMedicinalDir:0,  
      cilindros:0,
      ciudades:0,
      actividades:0,
      zonas:0,
      rutas:0,
      dirinh:0,
      familas:0,
      productos:0,
      equipos:0,
      mantenimientoEquipos:0,
      detMantenimientoEquipos:0,
      salir:0,
      eps:0,
      planes:0,
      residuales:0,
      ordenes:0,
      pedidosIps:0,
      pedidos:0,
      pedidosC:0,
      pedidosM:0,
      llenado:0,
      alistar:0,
      

      clientesAdd:0,
      clientesComercialAdd:0,
      clienteComercialDirAdd:0,
      clientesMedicinalDirAdd:0,
      ciudadesAdd:0,
      actividadesAdd:0,
      rutasAdd:0,
      familiasAdd:0,
      prodcutosAdd:0,
      equiposAdd:0,
      mantenimientoEquiposAdd:0,
      detMantenimientoEquiposAdd:0,
      cilindrosAdd:0,
      mantenimientoCilindrosAdd:0,
      phcilindrosAdd:0,
      epsAdd:0,
      planesAdd:0,
      residualesAdd:0,
      ordenesAd:0,
      pedidosIPSAdd:0,
      pedidosAdd:0,
      pedidosCAdd:0,
      pedidosMAdd:0,
      llenadosAdd:0,
      alistarAdd:0,

      clientesComercialEdit:0,
      clientesComercialDirEdit:0,
      clientesMedicinalDirEdit:0,      
      rutasEdit:0,
      familiasEdit:0,
      productosEdit:0,
      equiposEdit:0,
      mantenimientoEquiposEdit:0,
      detMantenimientoEquiposEdit:0,
      cilindrosEdit:0,
      epsEdit:0,
      planesEdit:0,
      residualesEdit:0,
      ordenesEdit:0,
      pedidosEdit:0,
      pedidosIpsEdit:0,
      pedidosCEdit:0,
      pedidosMEdit:0,
      alistarEdit:0,
      

      clientesComercialDel:0,
      clientesComercialDirDel:0,
      clientesMedicinalDirDel:0,
      rutasDel:0,
      familiasDel:0,
      productosDel:0,
      equiposDel:0,
      mantenimientoEquiposDel:0,
      detMantenimientoEquiposDel:0,
      cilindrosDel:0,
      epsDel:0,
      planesDel:0,
      residualesDel:0,
      ordenesDel:0,
      pedidosIpsDel:0,
      pedidosDel:0,
      pedidosCDel:0,
      pedidosMDel:0,
      alistarDel:0


    }
}

export function Autorizado(value) {
    if (localStorage.sicma) {
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                const val = value[i].toString();
                const usr = usuario().per;
                if (usr.some(e => e === val)) {
                    i = value.length;
                    return true;
                } else {
                    if (i === value.length - 1) {
                        return false;
                    }
                }

            }
        } else {
            return false
        }
    } else {
        return false
    }
}


/*
admin: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41
usuario: 1,2,3,4,5,6,7,8,9,11,12,13,14,16,17,27,28,29,35,

*/