import Carga from "infortech_modules/Elementos/Carga"
import Error from "infortech_modules/Elementos/Error"
import Rutas from "infortech_modules/Constantes/Rutas"
import Tabla from "infortech_modules/Componentes/Tabla";
import { Autorizado } from "infortech_modules/Constantes/Usuarios";

export default function Contenedor({
    titulo, cargar, error, mensajeCarga, mensajeError, classNameMensaje, altura, fondoCarga, fondoError, full, spiner, imagen, anchoImagen, rutas, botonAdd, header, footer,
    tablaNombre, tablaFilas, tablaEncabezado, claseTablaEncabezado, claseNameTextError, IconoError, colorIconError, sizeIconError, autorizado, tablaOrdenar, tablaOrdenNo, tablaTipoOrden
}) {
    cargar = cargar !== undefined ? cargar : true;
    error = error !== undefined ? error : false;
    altura = altura !== undefined ? altura : "95vh"
    mensajeCarga = mensajeCarga !== undefined ? mensajeCarga : ""
    mensajeError = mensajeError !== undefined ? mensajeError : ""
    autorizado = autorizado === undefined ? false : Autorizado([autorizado]);




    return (
        <>
            {
                autorizado ?
                    cargar ? <Carga altura={altura} mensaje={mensajeCarga} fondo={fondoCarga} full={full} spiner={spiner} imagen={imagen} anchoImagen={anchoImagen}
                        classNameMensaje={classNameMensaje}
                    /> : error ? <Error altura={altura} mensaje={mensajeError} fondo={fondoError} classNameText={claseNameTextError} Icono={IconoError}
                        colorIcon={colorIconError} sizeIcon={sizeIconError}

                    /> :
                        <>
                            {
                                rutas !== undefined && <div className='text-xs text-end'>{Rutas(rutas)}</div>
                            }
                            {/* Contendor */}

                            <div className="card">
                                <div className="overflow-y-scroll" style={{ maxHeight: '85vh' }}>
                                    <div className="card-body">
                                        {
                                            titulo !== undefined &&
                                            <div className="mb-3">
                                                <div className="mb-3 p-2">
                                                    <h2 className="fw-bold fs-4 ">{titulo}</h2>
                                                    <div className="text-end">
                                                        {
                                                            botonAdd !== undefined && botonAdd
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            header !== undefined && header
                                        }
                                        {
                                            <Tabla
                                                nombre={tablaNombre}
                                                encabezado={tablaEncabezado}
                                                claseEncabezado={claseTablaEncabezado}
                                                filas={tablaFilas}
                                                ordenar={tablaOrdenar} 
                                                ordenNo={tablaOrdenNo}
                                                tipoOrden={tablaTipoOrden}
                                            />
                                        }
                                        {
                                            footer !== undefined && footer

                                        }

                                    </div>
                                </div>
                            </div>

                        </>

                    : <Error altura={altura} mensaje={"(Error 401): No Esta Autorizado para ver esta página"} fondo={fondoError} classNameText={claseNameTextError} Icono={IconoError}
                        colorIcon={colorIconError} sizeIcon={sizeIconError}
                    />
            }

        </>
    )
};
