import './App.css';
import "infortech_modules/Recursos/Estilos/infortech.scss";
import Inicio from '1Loguin/Inicio';
import { useEffect, useState } from 'react';
import Carga from 'infortech_modules/Elementos/Carga';
import { Toaster } from "react-hot-toast";
import Contenido from '0Plantilla/Contenido';
//import Contenido from '0Plantilla/Contenido';


function App() {
  useEffect(() => {
    iniciar();
  }, [])
  const [cargar, setCargar] = useState(true);
  const [token, setToken] = useState(false);

  const iniciar = () => {
    setCargar(true);
    if (localStorage.sicma) {
      setToken(true);
      setTimeout(() => {
        setCargar(false);
      }, 500);
      return []
    } else {
      setToken(false);
      setTimeout(() => {
        setCargar(false);
      }, 500);
      return []

    }

  }

  return (
    <>
      {
        cargar ? <Carga mensaje={'Por favor espere...'} altura={'95vh'} fondo={'bg-purple-700'}
          classNameMensaje={'text-white'} full={true} anchoImagen={80}
        /> : token ? <Contenido iniciar={iniciar} /> :
          <Inicio iniciar={iniciar} />
      }
      <Toaster reverseOrder={false} position={'bottom-center'} />
    </>
  )
}

export default App;
