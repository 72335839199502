import { useState } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import { tiempo } from 'infortech_modules/Constantes/Constantes';
import Permisos, { Autorizado } from 'infortech_modules/Constantes/Usuarios';
import Get from 'infortech_modules/Crud/Get';
import Boton from 'infortech_modules/Componentes/Boton';
import { BsClipboardCheckFill } from 'react-icons/bs';
import Modales from 'infortech_modules/Componentes/Modales';
import { NumerosCeros } from 'infortech_modules/Constantes/EsNumero';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';



export default function Alistamiento({ iniciar, color, tipo, idPedido }) {

    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [productos, setProductos] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [descartables, setDescartables] = useState([]);
    const [cilindros, setCilindros] = useState([]);
    const [serialEqu, setSerialEqu] = useState([]);
    const [resisual, setResisual] = useState([]);




    const principal = (est) => {
        setModal(true)
        Get({ url: '/get/general', key: 'Alistar', data0: tipo, data1: idPedido }).then((r) => {
            setData(r[0]);
            tiempo(250, setCargar, false)
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }

    const closed = () => {
        tiempo(250, setCargar, true, setMensaje, "", setError, false)
        setModal(false);
    }


    return (
        <>
            <Boton tipo={'icono'} Icono={BsClipboardCheckFill} claseIcon={color} titulo={"Alistar"} onClick={() => principal()} />
            <Modales
                show={modal}
                onHide={closed}
                titulo={'Alistar Pedido #' + NumerosCeros(idPedido, 7)}
                body={<Contenedor
                    autorizado={Permisos().alistar} altura={'70vh'}
                    cargar={cargar} error={error} mensajeError={mensaje}
                    tablaEncabezado={["Item", "Tipo", "Descripción", "Cant"]} 
                    tablaNombre={"tabla"}
                    tablaFilas={data.map((v,i) => {                        
                        return <tr key={i}>
                            {
                                CreaarFila([i+1,v.tipo, v.nom, v.cant ])
                            }
                        </tr>
                    })}
                    
                />}

            />
        </>
    )
};
