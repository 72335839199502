import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import Tabla from "infortech_modules/Componentes/Tabla";
import { cambiarFormulario, datosConsultarHistorial } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
//import Modales from "infortech_modules/Componentes/Modales";
import Permisos, { usuario } from "infortech_modules/Constantes/Usuarios";
import Post from "infortech_modules/Crud/Post";
import { useState } from "react"
import { duplicate } from "infortech_modules/Constantes/Constantes";
import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import ModalEdit from "infortech_modules/Modales/ModalEdit";
import ModalDelete from "infortech_modules/Modales/ModalDelete";

export default function ActividadesMantenimientoCilindros({ temporal, id, principal }) {   
      
    const [agregar, setAgregar] = useState({ vid: id, des: "", usr: usuario().id, tipo: "ADDCIL" });
    const [editar, setEditar] = useState({des:""});


    const enviar = () => {
        if (EsVacio([agregar.des], ["Especifique primero una causa de revisión del equipo"])) {
            const promesa = new Promise((res, rej) => {
                Post({ url: '/post', caso: 'mantenimientoCilindrosAdd', data0: agregar, infoLog: JSON.stringify(agregar), duplicate: duplicate(false), table:'temporal' })
                .then((r) => {
                    principal(true);
                    tiempo(500, res, "Agregado")
                    
                })
                .catch((f) => {
                    tiempo(500, rej, f)
                })
            })
            AlertaPromesa('Agregando...', promesa, 'a');
        }
    }
    

    const row = temporal.map((v, i) => {
        return (
            <tr key={i}>
                {CreaarFila([i+1, v.des, <>
                <ModalEdit
                modalSize={'lg'}
                modalTitulo={'Editar'}
                inicializarDatos={() => setEditar({des:v.des})}
                formulario={[[CrearInput({type:'text', name:'des', value:editar.des, placeholder:"Detalle Revisión Especifica"})]]} onChange={(e) => cambiarFormulario(e, editar, setEditar)}
                tabla={'temporal'} caso={'mantenimientoEquiposAdd'} url={'/put'} principal={principal} duplicados={duplicate(false)} id={`id = ${v.id}`} editar={editar}
                mensajeLoadingPromesa={'Editando...'} mensajePromesa={'Editado'}
                />
                &nbsp; &nbsp;
                <ModalDelete
                modalTitulo={'Eliminar'}
                mensajeEliminar={<>¿Desea Eliminar el detalle de la revisión especifica, <strong>{v.des}</strong>?</>}
                mensajeLoadingPromesa={'Elimando...'} mensajePromesa={'Eliminado'} principal={principal} 
                tabla={'temporal'} caso={'mantenimientoEquiposDel'} consultarHistorial={datosConsultarHistorial(true)} condicionEliminar={`id = ${v.id}`}
                url={'/del'} i

                />
                </>], "scope", [""], "text-center")}
            </tr>
        )
    })

    return (
        <>
            <Contenedor autorizado={Permisos().mantenimientoEquiposAdd}
                altura={'40vh'} mensajeCarga={'Cargando Datos Temporales..'}
                cargar={false} error={false} mensajeError={""}
                header={<>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                            <div className="h5 mb-3">Agregar Revisión Especifica</div>
                            <Formularios datos={[
                                [CrearInput({ type: 'text', name: 'des', value: agregar.des, placeholder: 'Detalle Revisión Especifica' })]
                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)} />
                            <Boton tipo={'primary'} titulo={'Guardar'} onClick={() => enviar()} />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                            <div className="h5 mb-3">Revisiones Especificas</div>
                            <Tabla
                                encabezado={['No.', 'Falla', 'Acción']} claseEncabezado={"text-center"} filas={row} nombre={'taem'}
                            />
                        </div>
                    </div>
                </>}
            />
        </>
    )
};
