import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Modales from "infortech_modules/Componentes/Modales";
import { tiempo } from "infortech_modules/Constantes/Constantes";
import Permisos from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import { useState } from "react";
import { SiAuthy } from "react-icons/si";
import { NumerosCeros } from "infortech_modules/Constantes/EsNumero";
import { FormatearFecha } from "infortech_modules/Constantes/Fecha";
import { calcularEdad } from "infortech_modules/Constantes/Constantes";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";

export default function OrdenVignete({ id, titulo, estado, paciente }) {
    estado = estado === undefined ? false : estado;
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState({ id: "", idPlan: "", orden: "", pagare: "", ford: "", fini: "", fven: "", medico: "", diagnostico: "", obs: "", nombres: "", tel: "", paciente:"", doc:"", eps:"", fnac:"" });
    const [plan, setPlan] = useState([])
    const [modal, setModal] = useState(false);

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'OrdenVigente', data0: id }).then((r) => {
            setData(r[0]);
            setPlan(r[1]);
            tiempo(250, setCargar, false)
        }).catch((f) => {
            setError(true);
            setMensaje(f);
            tiempo(250, setCargar, false);
        })

    }

    const open = () => {
        setModal(true);
        principal();
    }

    const closed = () => {
        setModal(false)
        tiempo(200, setCargar, true, setError, false, setMensaje, "")
    }
    return (
        <>
            {
                estado ? <Boton tipo={'icono'} Icono={SiAuthy} titulo={'Ordenes Vigentes'} onClick={() => open()} posicionTexto={'left'} /> :
                    <Boton tipo={'icono'} Icono={SiAuthy} titulo={'No tiene ordenes Vigentes'} claseIcon={''} posicionTexto={'left'} />
            }
            <Modales
                titulo={'Orden Vigente'}
                show={modal}
                onHide={closed}
                body={<Contenedor autorizado={Permisos().ordenes} altura={'70vh'}
                    mensajeCarga={'Buscando Orden Vigente del Cliente...'} cargar={cargar} error={error} mensajeError={mensaje}
                    header={<div>
                        <div className="table-responsive table-container">
                            <table className="table font-sans">
                            <thead>
                                    <tr>
                                        <th>Paciente</th>
                                        <td>{data.paciente}</td>
                                        <th>No. de Documento</th>
                                        <td>{data.doc}</td>
                                        <th>Edad</th>
                                        <td>{calcularEdad(data.fnac)}</td>
                                        <th>EPS</th>
                                        <td>{data.eps}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Cod. Orden Interno</th>
                                        <td>{NumerosCeros(data.id, 6)}</td>
                                        <th>Fecha Orden</th>
                                        <td>{FormatearFecha(new Date(data.ford), 2)}</td>
                                        <th>Orden EPS</th>
                                        <td>{data.orden}</td>
                                        <th>Pagare</th>
                                        <td>{data.pagare}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Fecha de Inicio de Servicios</th>
                                        <td>{FormatearFecha(new Date(data.fini), 2)}</td>
                                        <th>Fecha de Vencimiento</th>
                                        <td>{FormatearFecha(new Date(data.fven), 2)}</td>
                                        <th>Medico Tratante</th>
                                        <td colSpan={3}>{data.medico}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Responsable</th>
                                        <td colSpan={2}>{data.nombres}</td>
                                        <th>Teléfono</th>
                                        <td colSpan={4}>{data.tel}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Diagnostico</th>
                                        <td colSpan={7}>{data.diagnostico}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Observaciones</th>
                                        <td colSpan={7}>{data.obs}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="text-center h4 fcom fw-bold">Detalles del Plan</div>
                        <hr />
                    </div>}
                    tablaEncabezado={['Tipo', 'Producto, Equipo y/o Descartables Incluidos', 'Cant', 'Cant Entregada', 'Cantidad Pendiente']}
                    tablaFilas={plan.map((v, i) => {
                        return <tr key={i}>
                            {CreaarFila([v.tipo, v.nom, v.cant, "", ""])}
                        </tr>
                    })}
                />}
                footer={<Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed ()} />}
            />

        </>
    )
};
