import Boton from "infortech_modules/Componentes/Boton";
import Select from "infortech_modules/Componentes/Select";

export default function Formularios({ datos, onChange }) {
    function DibujarFormulario() {
        if (Array.isArray(datos)) {
            const input = datos.map((v, i) => {
                let clase;
                switch (v.length) {
                    case 1:
                        clase = "col-12 mb-3 form-floating"
                        break;
                    case 2:
                        clase = "col-12 col-sm-6 col-lg-6 col-xl-6 mb-3"
                        break;
                    case 3:
                        clase = "col-12 col-sm-6 col-lg-4 col-xl-4 mb-3";
                        break;
                    case 4:
                        clase = "col-6 col-sm-6 col-lg-3 col-xl-3 mb-3"
                        break;
                    default:
                        break;
                }
                const contenido = v.map((h, j) => {
                    let disabled = h.disabled === undefined ? false : h.disabled;
                    let readonly = h.readonly === undefined ? false : h.readonly;
                    if (h.clase !== undefined) {
                        clase = h.clase;
                    }
                    return (
                        <div className={`mb-3 ${clase}`} key={j}>
                            <div className={h.type === "checkbox"? "form-check": h.type !== 'file' ? 'form-floating' : ''}>
                                {
                                    h.type === 'select' ? (                                        
                                        <Select
                                            name={h.name}
                                            value={h.value}
                                            onChange={onChange}
                                            options={h.options}
                                            titulo={h.placeholder}
                                            disabled={disabled}
                                            primerDato={h.list}
                                            busqueda={h.busqueda}
                                        />
                                    ) : h.type === 'checkbox' ? <>
                                    <label className="form-check-label">{h.placeholder}</label>
                                    <input
                                    type={h.type}
                                    className={'form-check-input'}
                                    onChange={onChange}
                                    disabled={disabled}
                                    readOnly={readonly}
                                    checked={h.value}
                                    name={h.name}

                                    />
                                    </> :
                                        h.type !== 'boton' ? (
                                            <>
                                                {h.type === 'file' && <label className="formFile">{h.placeholder}</label>}
                                                <input
                                                    type={h.type}
                                                    name={h.name}
                                                    value={h.value}
                                                    onChange={onChange}
                                                    placeholder={h.placeholder}
                                                    required={h.required === undefined ? false : h.required}
                                                    disabled={disabled}
                                                    list={h.list}
                                                    readOnly={readonly}
                                                    className={`form-control ${h.claseForm}`}
                                                />
                                                {h.type !== 'file' && <label>{h.placeholder}</label>}

                                            </>
                                        ) : (
                                            <div className={h.clase}>
                                                <Boton
                                                    tipo={h.value.tipo}
                                                    Icono={h.value.Icono}
                                                    SpinnerBoton={h.value.SpinnerBoton}
                                                    claseIcon={h.value.claseIcon}
                                                    disabled={h.value.disabled}
                                                    onClick={h.value.onClick}
                                                    titulo={h.value.titulo}
                                                    width={h.value.width}
                                                />
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    )
                })
                return <div key={i} className="row">{contenido}</div>

            })
            return input

        } else {
            return ""
        }

    }
    return DibujarFormulario();
};
