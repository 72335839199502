import { useState, useEffect } from 'react';
import Contenedor from 'infortech_modules/Componentes/Contenedor';
import { buscarValor, cambiarFormulario,  determinarEstadoPedidos,  duplicate, extraerCliente, tiempo } from 'infortech_modules/Constantes/Constantes';
import CrearInput from 'infortech_modules/Constantes/CrearInput';
import { CreaarFila } from 'infortech_modules/Constantes/CrearTabla';
import Permisos, { Autorizado, usuario } from 'infortech_modules/Constantes/Usuarios';
import Get from 'infortech_modules/Crud/Get';
import ModalAdd from 'infortech_modules/Modales/ModalAdd';
import DateTime, { FormatearFecha,  compararFechas } from 'infortech_modules/Constantes/Fecha';
import Formularios from 'infortech_modules/Componentes/Formularios';
import EsVacio from 'infortech_modules/Constantes/EsVacio';
import { AlertaPromesa } from 'infortech_modules/Componentes/Alertas';
import Direcciones from '2Access/0Clientes/Direcciones';
import PedidosTemporalClientes from './PedidosTemporalClientes';
import EsNumero, { NumerosCeros } from 'infortech_modules/Constantes/EsNumero';
import Post from 'infortech_modules/Crud/Post';
import Tabla from 'infortech_modules/Componentes/Tabla';
import { compararFechasMayor } from 'infortech_modules/Constantes/Fecha';
import VerPedidos from './VerPedidos';


export default function PedidosMedicinal({ iniciar }) {
    const area = 2;
    const tipo = "PedidosClientesMedicinal";
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [clientes, setClientes] = useState([])
    const [agregar, setAgregar] = useState({ area: area, fped: DateTime(), fdes: "", idCliente: "", dirCliente: "", obs: "",  idEnvio:0, fenvi:"0000-00-00 00:00:00", est: 1 })
    const [disableInput, setDisabledInput] = useState(true);
    //const [tiempoAlerta, setTiempoAlerta] = useState([]);
    const [disabledBotonSelect, setDisabledBotonSelect] = useState(false);
    //const [dirCliente, setDirCliente] = useState([]);
    const [rutas, setRutas] = useState([]);
    const [insumos, setInsumos] = useState([]);
    const [tabla, setTabla] = useState('Producto');
    const [dirSel, setDirSel] = useState('');
    const [temporal, setTemporal] = useState({ vid: "", des: "", cant: "", otro: "", usr: usuario().id, cliente: "", tabla: "", tipo: tipo })
    const [cargarT, setCargarT] = useState(true);
    const [datosTemporales, setDatosTemporales] = useState([]);
    const [btnTemporal, setBtnTemporal] = useState(false);
    const [insumosCliente, setInsumosCliente] = useState([]);
    const [recogida, setRecogida] = useState({ idCliente: '', fecha: "", usr: usuario().id, dirCliente: "", obs: "", est: 1 })
    const [dirRecogida, setDirRecogida] = useState("");



    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'PedidosComercial', data0: area }).then((r) => {
            setData(r[0]);
            setClientes(r[1]);
            setInsumos(r[2]);
            setRutas(r[3]);
            tiempo(250, setCargar, false)
        })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })
    }

    const cambiar = (e) => {
        cambiarFormulario(e, agregar, setAgregar)
    }

    const buscarCliente = () => {
        setDisabledBotonSelect(true);
        if (EsVacio([agregar.idCliente], ['Seleccione un Cliente de la Lista'])) {
            const promesa = new Promise((res, rej) => {
                Get({ url: '/get/general', key: 'BuscarClienteGeneral', data0: agregar.idCliente, data1: area, data2: tipo }).then((r) => {
                    setDatosTemporales(r[0]);
                    setDisabledInput(false);
                    setBtnTemporal(false);
                    setInsumosCliente(r[1]);
                    setRecogida({
                        ...recogida,
                        idCliente: extraerCliente(agregar.idCliente, 0)
                    })
                    tiempo(250, res, "Información del Cliente Cargada", setDisabledBotonSelect, false, setCargarT, false)
                }).catch((f) => {
                    tiempo(250, rej, f, setDisabledBotonSelect, false)
                })
            })
            AlertaPromesa('Buscando Cliente', promesa, 'e', 2000);
        } else {
            tiempo(1000, setDisabledBotonSelect, false)
        }
    }

    const actualizarCliente = (est) => {
        est && setCargarT(true);
        Get({ url: '/get/general', key: 'BuscarClienteGeneral', data0: agregar.idCliente, data1: area, data2: tipo }).then((r) => {
            setDatosTemporales(r[0]);
            setDisabledInput(false);
            setBtnTemporal(false);
            setInsumosCliente(r[1]);
            setRecogida({
                ...recogida,
                idCliente: extraerCliente(agregar.idCliente, 0)
            })
            tiempo(250, setCargarT, false)
        })
    }

    const seleccionarDireccion = (id, dir) => {
        setAgregar({
            ...agregar,
            dirCliente: id
        })
        setDirSel(dir);
    }

    const seleccionarDireccionRecogida = (id, dir) => {
        setRecogida({
            ...recogida,
            dirCliente: id
        })
        setDirRecogida(dir);
    }


    const cambiarTabla = (e) => {
        setTabla(e.target.value);
        setTemporal({
            ...temporal,
            vid: ""
        })
    }

  
    const guardatTemporal = () => {
        setBtnTemporal(true);
        if (EsVacio([buscarValor(insumos.filter(i => i.tabla === tabla), temporal.vid) ? "ok" : "", temporal.cant, temporal.cant > 0 ? "ok" : ""], ['Seleccione un ' + tabla + ' de la lista', 'Digite la cantidad', 'La Cantida debe ser mayor a 0'])) {
            if (EsNumero([temporal.cant], ['La Cantidad debe ser un valor númerico'])) {
                const promesa = new Promise((res, rej) => {
                    Post({
                        url: '/post', table: 'temporal', caso: 'pedidosComercial', duplicate: duplicate(true, [extraerCliente(temporal.vid, 0)], ['vid'], ['Este ' + tabla + ' ya ha sido agregado'], [` and usr = '${usuario().id}' and cliente = '${extraerCliente(agregar.idCliente, 0)}' and tabla = '${tabla}' and tipo = '${tipo}' `]),
                        data0: { vid: extraerCliente(temporal.vid, 0), des: temporal.des, cant: temporal.cant, otro: temporal.otro, usr: temporal.usr, cliente: temporal.cliente, tabla: temporal.tabla, tipo: temporal.tipo }
                    }).then((r) => {
                        actualizarCliente(true);
                        tiempo(250, res, tabla + ' agregado al pedido correctamente', setBtnTemporal, false);
                    }).catch((f) => {
                        tiempo(250, rej, f, setBtnTemporal, false)
                    })
                })
                AlertaPromesa('Agregando linea de pedido', promesa, 'e');
            } else {
                tiempo(500, setBtnTemporal, false)
            }
        } else {
            tiempo(500, setBtnTemporal, false)
        }

    }
    const cambiarSelect = () => {
        setAgregar({
            ...agregar,
            idCliente: ""
        })
        setCargarT(true);
        setDisabledInput(true);
    }
    const cambiarTemporal = (e) => {
        cambiarFormulario(e, temporal, setTemporal);
        setTemporal((v) => {
            v.cliente = extraerCliente(agregar.idCliente, 0);
            v.tabla = tabla;
            return v;
        })
    }

    return (
        <>
            <datalist id='clientesMedicinal'>
                {
                    clientes.map((v, i) => {
                        return <option key={i} value={v.id + ' - ' + v.doc + ' - ' + v.cliente} />
                    })
                }
            </datalist>
            <datalist id='insumos'>
                {
                    insumos.filter(i => i.tabla === tabla).map((v, i) => {
                        return <option key={i} value={v.id + ' - ' + v.descripcion} />
                    })
                }
            </datalist>
            <Contenedor titulo={'Pedidos Area Medicinal'} ruta={[]} autorizado={Permisos().pedidosM} altura={'85vh'}
                cargar={cargar} mensajeCarga={''} error={error} mensajeError={mensaje}
                botonAdd={Autorizado([Permisos().pedidosMAdd]) &&
                    <ModalAdd
                        tituloBotonPrincipal={'Nuevo Pedido'} tipoBotonPrincipal={'primary'} modalTitulo={'Crear Pedido'}
                        disabledBotonEnviar={!disableInput}
                        tituloBotonEnviar={'Crear Pedido'}
                        modalFullScrenn={true}
                        contenidoSuperiorModal={<>
                            <div>
                                <Formularios datos={[
                                    [CrearInput({ type: 'text', name: 'idCliente', value: agregar.idCliente, placeholder: 'Seleccione Cliente', list: 'clientesMedicinal', disabled: !disableInput, clase: 'col-xl-7 col-lg-7 col-sm-8 col-12' }),
                                    CrearInput({ type: 'boton', value: { tipo: 'principal', titulo: disableInput ? "Seleccionar" : "Cambiar", onClick: disableInput ? () => buscarCliente() : () => cambiarSelect(), disabled: disabledBotonSelect }, clase: 'col-xl-2 col-lg-2 col-sm-4 col-6' }),
                                    CrearInput({ type: 'date', name: 'fdes', value: agregar.fdes, placeholder: "Fecha Preparación Pedido", clase: 'col-xl-3 col-lg-3 col-sm-12 col-6' })
                                    ]

                                ]} onChange={cambiar} />
                                <hr />
                            </div>
                            <div className='row'>
                                <div className='col-10'>
                                    <div className='form-floating mb-3'>
                                        <input className='form-control' type='text' value={dirSel} disabled placeholder='Dirección de Despacho' />
                                        <label>Dirección de Despacho</label>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <Direcciones id={extraerCliente(agregar.idCliente, 0)} ruta={rutas} disabled={disableInput} sizeIcon={35}
                                        select={seleccionarDireccion} cliente={extraerCliente(agregar.idCliente, 2)} />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-3 col-sm-4 col-4 mb-3'>
                                    <div className='form-floating'>
                                        <select className='form-select' name='tabla' value={tabla} onChange={cambiarTabla} disabled={disableInput} >
                                            <option value={'Producto'}>Producto</option>
                                            <option value={'Equipo'}>Equipo</option>
                                            <option value={'Descartable'}>Descartable</option>
                                        </select>
                                        <label>Producto, Equipo o Descartable</label>
                                    </div>
                                </div>
                                <div className='col-xl-9 col-lg-9 col-sm-8 col-8'>
                                    <Formularios datos={[
                                        [CrearInput({ type: 'text', name: 'vid', value: temporal.vid, placeholder: tabla, list: 'insumos', clase: 'col-xl-8 col-lg-8 col-sm-7 col-7', disabled: disableInput }),
                                        CrearInput({ type: 'text', name: 'cant', value: temporal.cant, placeholder: 'Cantidad', disabled: disableInput, clase: 'col-xl-2 col-lg-2 col-sm-2 col-2' }),
                                        CrearInput({ type: 'boton', value: { tipo: 'principal', titulo: 'Agregar', onClick: () => guardatTemporal(), disabled: disableInput ? disableInput : btnTemporal }, clase: 'col-xl-1 col-lg-1 col-sm-3 col-3', })
                                        ]
                                    ]} onChange={cambiarTemporal}

                                    />
                                </div>
                                <div className='mb-3'>
                                    {
                                        !disableInput &&
                                        <PedidosTemporalClientes cargar={cargarT} datosTemporales={datosTemporales} actualizar={actualizarCliente} />
                                    }
                                </div>
                                <div className=''>
                                    {
                                        !disableInput &&
                                        insumosCliente.length > 0 &&
                                        <>
                                            <hr></hr>
                                            <div className='fcom fw-bold h4 text-center'>Cilindros o Equipos en Cliente</div>
                                            <Tabla encabezado={['Cilindro o Equipo', 'Serial', 'Esta en Cliente desde', 'Pedido']}
                                                nombre={'tdec'}
                                                filas={insumosCliente.filter(i => i.nomP !== extraerCliente(agregar.idCliente, 0)).map((v, i) => {
                                                    return <tr key={i}>
                                                        {CreaarFila([v.equipo, v.ser, FormatearFecha(new Date(v.fecha), 2), NumerosCeros(v.idPedido, 6)])}
                                                    </tr>
                                                })}
                                            />
                                            <div className='text-end'>
                                                <ModalAdd
                                                    modalTitulo={'Crear Orden  de Recogida'}
                                                    tipoBotonPrincipal={'sinfondo'} tituloBotonPrincipal={'Crear Orden de Recogida'} tituloBotonEnviar={'Crear'}
                                                    contenidoSuperiorModal={
                                                        <>
                                                            <div className='fcom mb-3'>Se creara una orden de recogida de equipos/cilindros de todos los equipos que estan actualmente en el cliente</div>
                                                            <div className='row'>
                                                                <div className='col-xl-2 col-lg-2'>
                                                                    <Formularios datos={[
                                                                        [CrearInput({ type: 'date', name: 'fecha', value: recogida.fecha, placeholder: 'Fecha de Recogida' })]
                                                                    ]} onChange={(e) => cambiarFormulario(e, recogida, setRecogida)} />
                                                                </div>
                                                                <div className='col-xl-10 col-lg-10'>
                                                                    <div className='row'>
                                                                        <div className='col-xl-11 col-lg-11'>
                                                                            <Formularios datos={[
                                                                                [CrearInput({ type: 'text', value: dirRecogida, placeholder: 'Dirección de Recogida', disabled: true })]
                                                                            ]} />
                                                                        </div>
                                                                        <div className='col-xl-1 col-lg-1 mb-3'>
                                                                            <Direcciones ruta={rutas} id={extraerCliente(agregar.idCliente, 0)} cliente={extraerCliente(agregar.idCliente, 2)} sizeIcon={35} select={seleccionarDireccionRecogida} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Formularios datos={[
                                                                    [CrearInput({ type: 'text', name: 'obs', value: recogida.obs, placeholder: 'Observaciones' })]
                                                                ]} onChange={(e) => cambiarFormulario(e, recogida, setRecogida)} />
                                                            </div>

                                                        </>
                                                    }
                                                    comprobarTextoVacio={[recogida.fecha, recogida.dirCliente, compararFechas(recogida.fecha) ? "" : "ok"]}
                                                    mensajeTextoVacio={['La fecga de recogida no es valida', 'Debe establecer la dirección del cliente', "La fecha de recogida debe ser superior a la fecha actual"]}
                                                    mensajeLoadingPromesa={'Creando Orden de Recogida'} mensajePromesa={'Orden de Recogida Creada'}
                                                    url={'/post/general'} key1={'OrdenRecogida'} caso={'ordenRecogida'} agregar={recogida} datos1={extraerCliente(agregar.idCliente, 0)}
                                                    principal={actualizarCliente}
                                                />

                                            </div>
                                        </>
                                    }
                                </div>
                            </div>                        
                        </>}
                        comprobarTextoVacio={[agregar.fdes,agregar.dirCliente, compararFechasMayor(agregar.fdes)?"":"ok"]}
                        mensajeTextoVacio={['Seleccione una fecha de pedido valida', 'Seleccione una  direacción de despacho', 'La fecah de despacho debe ser mayor a la fecha actual']}
                        mensajeLoadingPromesa={'Creando Pedido...'} mensajePromesa={'Pedido Creado'} principal={principal} caso={'pedidosMedicinalAdd'}
                        url={'/post/general'} key1={'PedidosComercialAdd'} agregar={agregar} datos1={tipo}

                    />
                }

                tablaEncabezado={['No. de Pedido','Fecha de Pedido', 'Cliente', 'Dirección', 'Estado', 'Acciones']}
                tablaNombre={'tpm'}
                tablaFilas={data.map((v, i) => {
                    return <tr key={i}>
                        {CreaarFila([NumerosCeros(v.id,7), FormatearFecha(new Date(v.fped),3),  v.cliente, v.dir + ' - ' + v.direccion, determinarEstadoPedidos(v.est), <>
                        {<VerPedidos
                        idPedido={v.id} 
                        area={"2"}
                        />}
                        </>])}
                    </tr>
                })}

            />
        </>
    )
};
