import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import { buscarValor, cambiarFormulario, datosConsultarHistorial, determinarEstadoCilindros, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import { CreaarFila } from "infortech_modules/Constantes/CrearTabla";
import { NumerosCeros } from "infortech_modules/Constantes/EsNumero";
import Permisos, { Autorizado } from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import ModalAdd from "infortech_modules/Modales/ModalAdd";
import ModalDelete from "infortech_modules/Modales/ModalDelete";
import { useEffect, useState } from "react";
import MantenimientoCilindrosEnviar from "./MantenimientoCilindrosEnviar";
import PhEnviar from "./PhEnviar";
import { compararFechasMayor } from "infortech_modules/Constantes/Fecha";
import PhFinalizar from "./PhFinalizar";

export default function Cilindros({ iniciar }) {
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [data, setData] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [unm, setUnm] = useState([]);
    const [productos, setProductos] = useState([]);
    const [agregar, setAgregar] = useState({ producto: "", ser: "", unm: "0", cap: "", prop: "1", nomP: "", ubic: "0", periodo: "1", est:1 })
    const [ph, setPh] = useState({ idCil: "", fecha: "", ven: "", faleta: "", est: 1 })   

   
    useEffect(() => {
        principal();
    }, [])

    const principal = (est) => {
        est && setCargar(true);
        Get({ url: '/get/general', key: 'cilindros' })
            .then((r) => {
                setData(r[0]);
                setPeriodos(r[1]);
                setClientes(r[2]);
                setProveedores(r[2].filter(c => c.tipoC === 2));
                setUnm(r[3]);
                setProductos(r[4]);
                tiempo(250, setCargar, false)
            })
            .catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false)
            })

    }

    const cambiarAgregar = (e) => {       
        if(agregar.prop !== "1"){
            setAgregar({
                ...agregar,
                [e.target.name]:e.target.value
            })
        }else{
            setAgregar({
                ...agregar,
                nomP:""
            })
        }
    }

    const vacioTerceros = () => {
        if (agregar.prop === "1") {
            return "ok"
        } else {
            if (agregar.prop === "2") {
                if (buscarValor(proveedores, agregar.nomP)) {
                    return "ok";
                } else {
                    return "";
                }
            } else {
                if (agregar.prop === "3") {
                    if (buscarValor(clientes, agregar.nomP)) {
                        return "ok"
                    } else {
                        return ""
                    }
                }
            }
        }
    }

    const vacioProducto = () => {
        if(buscarValor(productos, agregar.producto)){
            return "ok"
        }else{
            return ""
        }
    }

    const vacioPh = () => {
        if(agregar.periodo === "1"){
            return "ok"
        }else{
            if(ph.fecha !== ""){
                return "ok"
            }else{
                return ""
            }
        }
    }


    return (
        <>
            <datalist id="clientes">
                {
                    clientes.map((v, i) => {
                        return (
                            <option key={i} value={`${v.id} - ${v.doc} - ${v.nom} ${v.ape}`} />
                        )
                    })
                }


            </datalist>

            <datalist id="proveedor">
                {
                    proveedores.map((v, i) => {
                        return (
                            <option key={i} value={`${v.id} - ${v.doc} - ${v.nom} ${v.ape}`} />
                        )
                    })
                }
            </datalist>

            <datalist id="productos">
                {
                    productos.map((v,i) => {
                        return <option key={i} value={v.id +' - '+v.nomF +  '-' + NumerosCeros(v.id, 3) + ' ' + v.nom + ' (' + v.cap + ' ' + v.unm + ') IMU: ' + v.imu} />
                    })
                }
            </datalist>


            <Contenedor autorizado={Permisos().cilindros} rutas={['Inicio', 'Cilindros', 'Cilindros']}
                cargar={cargar} mensajeCarga={'Cargando Información de Cilindros'} altura={'85vh'}
                error={error} mensajeError={mensaje}
                titulo='Cilindros'
                botonAdd={
                    Autorizado([Permisos().cilindrosAdd]) &&
                    <ModalAdd
                        tipoBotonPrincipal={'principal'} tituloBotonPrincipal={'Crear Cilindro'}
                        modalTitulo={'Registrar Nuevo Cilindro'}
                        formulario={[
                            [CrearInput({ type: 'text', name: 'ser', value: agregar.ser, placeholder: 'Serial' })],
                            [CrearInput({ type: 'text', name: 'producto', value: agregar.producto, placeholder: 'Producto', list:'productos' })],
                            [CrearInput({ type: 'text', name: 'cap', value: agregar.cap, placeholder: 'Capacidad Cilindro' }), CrearInput({ type: 'select', name: 'unm', value: agregar.unm, placeholder: 'Unidad de Medida', list: { value: "0", titulo: "Seleccione Unidad de Medida" }, options: unm.map((v, i) => { return { value: v.sigla, titulo: v.nom } }) })],
                            [CrearInput({ type: 'select', name: 'prop', value: agregar.prop, placeholder: 'Propiedad', options: [{ value: "1", titulo: 'Propio' }, { value: "2", titulo: "Alquilado" }, { value: "3", titulo: "Tercero" }] })]

                        ]}
                        onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                        contenidoInferiorModal={<>
                            {
                                agregar.prop !== "1" &&
                                <Formularios datos={[
                                    [CrearInput({ type: 'text', name: 'nomP', value: agregar.nomP, list: agregar.prop === "3" ? "clientes" : "proveedor", placeholder: agregar.prop === "2" ? "Seleccione el Proveedor" : "Seleccione el Tercero" })]
                                ]} onChange={cambiarAgregar}
                                />
                            }
                            <Formularios datos={[
                                [CrearInput({ type:'select', name:'periodo', value:agregar.periodo, placeholder:'Período Prueba Hidrostática', options:periodos.map((v,i) => {return {value:v.id, titulo:v.id !== 1 ? v.periodo+' - '+v.tiempo + ' año(s)':v.periodo}})})]
                            ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}                                                        
                            />
                            {
                                agregar.periodo !== '1' && 
                                <Formularios
                                datos={[
                                    [CrearInput({type:'datetime-local', name:'fecha', value:ph.fecha, placeholder:'Fecha de Expedición'})]
                                ]} onChange={(e) => cambiarFormulario(e, ph, setPh)}           
                                />
                            }
                        </>}
                        comprobarTextoVacio={[agregar.ser, vacioTerceros(), vacioProducto(), agregar.cap, agregar.unm === "0"?"":agregar.unm, vacioPh()]}
                        mensajeTextoVacio={['Digite el Seríal del Cilindro', agregar.prop === "2" ? "Seleccione un Proveedor de la lista" : "Seleccione un Cliente de la Lista", 'Debe seleccionar un producto de la lista', 'Escriba la capacidad del Cilindro', 'Seleccione la unidad de medida', 'Seleccione la fecha de expedición de la prueba hidróstatica']}
                        duplicados={duplicate(true, [agregar.ser], ['ser'], ['Error: Serial ya registrado'])}
                        mensajeLoadingPromesa={'Creando Cilindro...'} mensajePromesa={'Cilindro Creado'} principal={principal}
                        caso='cilindrosAdd' key1={'CilindrosAdd'} url={'/post/general'} agregar={agregar} datos1={ph} infoLog={JSON.stringify(agregar)}
                    />
                }
                tablaEncabezado={['Serial', 'Propiedad', 'Und Medida', 'Capacidad', 'Ubicación', 'Familia',  'Producto', 'Estado', 'Acciones']} tablaNombre={'tc'}
                tablaFilas={data.map((v, i) => {
                    return (
                        <tr key={i}>
                            {
                                CreaarFila([v.ser, v.propietario, v.unm, v.cap, v.ubicacion,v.familia,  v.nom, compararFechasMayor(v.ph)?v.est===5?"En PH":"PH Vencida":determinarEstadoCilindros(v.est)], "scope", [''], compararFechasMayor(v.ph)?"text-left bg-danger text-white":"text-left")
                            }
                            <td className={compararFechasMayor(v.ph)?"text-center bg-danger text-white":"text-center"}>
                                {
                                    Autorizado([Permisos().cilindrosDel]) && 
                                    <ModalDelete tituloBotonPrincipal={'Eliminar Cilindro S/N '+v.ser} posicionTextoBotonPrincipal={'left'} modalTitulo={'Eliminar Cilindro'}
                                    consultarHistorial={datosConsultarHistorial(true,)} claseIconBotonPrincipal={compararFechasMayor(v.ph)?"pointer text-white":undefined}
                                    tabla={'cilindros'} valorEliminar={v.id} mensajeEliminar={<>¿Está seguro de eliminar el cilindro con serial No. <strong>{v.ser}</strong>, producto <strong>{v.nom}</strong> por <strong>{v.cap+' '+v.unm} de la familia <strong>{v.familia +' - ' +v.sub}</strong></strong>?</>}
                                    url={'/del/general'} caso={'cilindrosDel'} key1={'CilindrosDel'} condicionEliminar={`id = ${v.id}`}
                                    mensajeLoadingPromesa={'Eliminado Cilindro...'} mensajePromesa={'Cilindro Eliminado'} infoLog={JSON.stringify({ser:v.ser, propietario:v.propietario, familia:v.fammilia, subfamilia:v.sub, producto:v.nom})}
                                    principal={principal}
                                    
                                    />
                                }
                                {
                                    Autorizado([Permisos().mantenimientoCilindrosAdd]) && 
                                    <>
                                    &nbsp; &nbsp;
                                    <MantenimientoCilindrosEnviar estado={v.est} serial={v.ser} id={v.id} 
                                    producto={<>{v.familia} - {v.sub} - {v.nom} por {v.cap}{v.unm}</>}
                                    propietario={v.propietario} ven={compararFechasMayor(v.ph)}
                                    />
                                    </>
                                }
                                {
                                    Autorizado([Permisos().phcilindrosAdd]) && 
                                    <>
                                    &nbsp; &nbsp;
                                    <PhEnviar estado={v.est} serial={v.ser} id={v.id} 
                                    producto={v.nom} proveedores={proveedores}
                                    familia={v.familia} subfamilia={v.sub} capacidad={v.cap} unm={v.unm}
                                    propietario={v.propietario} principal={principal}
                                    ven={compararFechasMayor(v.ph)}
                                    />
                                    </>
                                }
                                {
                                    v.est === 5 && 
                                    <>
                                    &nbsp; &nbsp;
                                    <PhFinalizar
                                    id={v.phdetalle} 
                                    idCil={v.id}
                                    principal={principal}
                                    />
                                    </>
                                }
                            </td>
                        </tr>
                    )
                })}

            />
        </>
    )
};
