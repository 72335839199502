import Spinner from 'react-bootstrap/Spinner';
import { IoMdAddCircleOutline } from 'react-icons/io';
export default function Boton({ tipo, width, titulo, SpinnerBoton, onClick, disabled, Icono, claseIcon, sizeIcon, posicionTexto }) {
    width = width !== undefined ? width : "";
    sizeIcon = sizeIcon === undefined ? 20:sizeIcon;
    disabled = disabled !== undefined ? disabled : false
    switch (posicionTexto) {
        case "left":
            posicionTexto = "textText-Left"
            break;
        case "rigth":
            posicionTexto = ""
            break;   
        case "top-center":
            posicionTexto = "textText-TopCenter";
            break;
        default:
            posicionTexto = "textText-Top"
            break;
    }   

    function cargar() {
        if (SpinnerBoton) {
            return <><Spinner as={'span'} animation={'border'} size={'sm'} role={'status'} />&nbsp; </>
        } else {
            return ""
        }
    }


    function DibujarBoton() {        
        let boton;
        switch (tipo) {
            case "principal":
                boton = (
                    <button
                        className={`bg-purple-700 hover:bg-purple-500  text-white font-bold py-2 px-4  disabled:bg-purple-400 rounded ${width}`}
                        disabled={disabled}
                        onClick={onClick}
                       
                    >
                        {cargar()}
                        {titulo}
                    </button>
                )
                break;
            case "primary":
                boton = (
                    <button
                        className={`bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 disabled:bg-blue-400 rounded ${width}`}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {cargar()}
                        {titulo}
                    </button>
                )
                break;
            case "secondary":
                boton = (
                    <button
                        className={`bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded ${width}`}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {cargar()}
                        {titulo}
                    </button>
                )
                break;
            case "danger":
                boton = (
                    <button
                        className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${width}`}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {cargar()}
                        {titulo}
                    </button>
                )
                break;
            case "sinfondo":
                boton = (
                    <button
                        className={`border-2 border-purple-700 hover:text-white  hover:bg-purple-700 text-purple-700 py-2 px-4 rounded ${width}`}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {cargar()}
                        {titulo}
                    </button>
                )
                break;
            case "icono":
                Icono = Icono !== undefined ? Icono : IoMdAddCircleOutline;
                boton = 
                    <div className='infoText'>
                        {
                        disabled ?    
                        <Icono
                            className={claseIcon === undefined ? "fs-4 pointer text-purple-500":claseIcon}
                            size={sizeIcon}
                        />:
                        <Icono
                            className={claseIcon === undefined ? "fs-4 pointer text-purple-600":claseIcon}                           
                             onClick={() => onClick()} size={sizeIcon}

                        />                        
                        }
                        
                        <span className={posicionTexto}>{titulo}</span>
                    </div>
                break;

            default:
                boton = "Faltan datos para dibujar el boton"
                break;
        }
        return boton;
    }
    

    return DibujarBoton();

};
