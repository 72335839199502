import { AlertaPromesa } from "infortech_modules/Componentes/Alertas";
import Boton from "infortech_modules/Componentes/Boton";
import Contenedor from "infortech_modules/Componentes/Contenedor";
import Formularios from "infortech_modules/Componentes/Formularios";
import Modales from "infortech_modules/Componentes/Modales";
import { cambiarFormulario, duplicate, tiempo } from "infortech_modules/Constantes/Constantes";
import CrearInput from "infortech_modules/Constantes/CrearInput";
import EsVacio from "infortech_modules/Constantes/EsVacio";
import { compararDosFechasMayor, compararFechasMayor } from "infortech_modules/Constantes/Fecha";
import Permisos from "infortech_modules/Constantes/Usuarios";
import Get from "infortech_modules/Crud/Get";
import Post from "infortech_modules/Crud/Post";
import { useState } from "react";
import { MdAutorenew } from "react-icons/md";
import OrdenResponsables from "./OrdenResponsables";

export default function OrdenNueva({ id, paciente, principal }) {
    //const datos = [];
    const [cargar, setCargar] = useState(true);
    const [error, setError] = useState(false);
    const [mensaje, setMensaje] = useState('Error');
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [agregar, setAgregar] = useState({ idEP: id, idPlan: "0", orden: "", ford: "", fini: "", fven: "", pagare: "", medico: "", diagnostico: "", obs: "", est: 1, res: "" });
    const [res, setRes] = useState('')
    //const [disabled, setDisabled] = useState(false);
    const disabled = false;

    const open = (est) => {        
        setModal(true);        
        Get({ url: '/get', key: '14' })
            .then((r) => {             
                setData(r);
                tiempo(250, setCargar, false);
            }).catch((f) => {
                setMensaje(f);
                setError(true);
                tiempo(250, setCargar, false);
            })
    }

    const closed = () => {
        setModal(false);
        tiempo(250, setCargar, true, setError, false, setMensaje(''))
    }

    const seleccionar = (id, nombres) => {
        setAgregar({
            ...agregar,
            res:id
        })
        setRes(nombres)
    }
    const enviar = () => {

        if(EsVacio([agregar.orden, agregar.pagare, agregar.idPlan === "0"?"":"ok", agregar.ford, agregar.fini, agregar.fven, agregar.medico, agregar.diagnostico, agregar.res, compararFechasMayor(agregar.ford)?"ok":"", compararDosFechasMayor(agregar.fini, agregar.ford, true)?"ok":"", compararDosFechasMayor(agregar.fven,agregar.fini)?"ok":""],
            ['Digiete el número de la orden', 'Digite el número de pagare', 'Por favor seleccione el plan de la orden','Escriba una fecha de orden válida', 'La fecha de inicio de servicios es invalida', 'La fecha de vencimiento de la orden es invalida',
            'No ha digitado el nombre del medico tratante', 'No ha especificado el diagnostico del paciente', 'Seleccione la Persona Responsable', "La fecha de orden no puede ser mayor a la fecha actual",'La fecha de inicio de servicios debe ser igual o mayor a la fecha de la orden', "La fecha de vencimiento de la orden debe ser mayor a la fecha de inicio de servicios"
        ])){
            const promesa = new Promise((res,rej) => {
                Post({table:'pacientes_ordenes', url:'/post', duplicate:duplicate(true,[agregar.idEP],['idEP'],['El paciente tiene una orden vigente'],[` and est = '1'`]),
                caso:'ordenesAdd', data0:agregar            
            }).then((r) => {
                tiempo(250, res, 'Orden Agregada Correctamente', principal,true)
            }).catch((f) => {
                tiempo(250, rej,f)
            })
            })
            AlertaPromesa('Registrando Orden', promesa, 'a')
        }
    }

    return (
        <>
            <Boton tipo={'icono'} Icono={MdAutorenew} titulo={'Registrar Orden'} posicionTexto={'left'} claseIcon={'fs-4 fw-bold pointer text-blue-700'} onClick={() => open()} />

            <Modales
                titulo={'Registrar Orden al Paciente ' + paciente}
                show={modal}
                onHide={closed}
                body={<>
                    <Contenedor autorizado={Permisos().ordenesAd} cargar={cargar} mensajeCarga={'Cargando datos...'} error={error} mensajeError={mensaje} altura={'60vh'}
                        header={<>
                            <Formularios datos={[
                                [CrearInput({ type: 'text', name: 'orden', value: agregar.orden, placeholder: 'No. Orden' }), CrearInput({ type: 'text', name: 'pagare', value: agregar.pagare, placeholder: 'No. de Pagare' }),
                                CrearInput({
                                    type: 'select', name: 'idPlan', value: agregar.idPlan, placeholder: 'Seleccione Plan', list: { value: "0", titulo: "Seleccione Plan" }, options: data.map((v, i) => {
                                        return { value: v.id, titulo: v.plan }
                                    })
                                })],
                                [CrearInput({ type: 'date', name: 'ford', value: agregar.ford, placeholder: 'Fecha Orden' }), CrearInput({ type: 'date', name: 'fini', value: agregar.fini, placeholder: 'Fecha Inicio de Servicios' }),
                                CrearInput({ type: 'date', name: 'fven', value: agregar.fven, placeholder: 'Fecha Vencimiento Orden' })
                                ],
                                [CrearInput({ type: 'text', name: 'medico', value: agregar.medico, placeholder: 'Medico Tratante' })],
                                [CrearInput({ type: 'text', name: 'diagnostico', value: agregar.diagnostico, placeholder: 'Diagnostico' })],
                            ]} onChange={(e) => { cambiarFormulario(e, agregar, setAgregar) }} />
                            <div className="row">
                                <div className="col-10">
                                    <div className="form-floating mb-3">
                                        <input type={'text'} className='form-control' value={res} placeholder='Persona Responsable' disabled='true' />
                                        <label>Persona Responsable</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <OrdenResponsables idPaciente={id} seleccionar={seleccionar} />
                                </div>

                            </div>
                            <Formularios
                                datos={[
                                    [CrearInput({ type: 'text', name: 'obs', value: agregar.obs, placeholder: 'Observaciones Adicionales' })]
                                ]} onChange={(e) => cambiarFormulario(e, agregar, setAgregar)}
                            />
                        </>}

                    />
                </>}
                footer={<>
                    <Boton tipo={'secondary'} titulo={'Cerrar'} onClick={() => closed()} />
                    <Boton tipo={'primary'} titulo={'Registrar'} onClick={() => enviar()} disabled={disabled} />
                </>}
            />
        </>
    )
};
